import {IImage, Image} from 'app/blocks/model/image.model';

import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';

import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface ICustomStatementAddress extends IBaseEntity {
    id?: number;

    vatRegNo?: string;

    registrationNo?: string;

    headerLogo?: IImage;

    contactInfo?: IContactInfo;
}

export class CustomStatementAddress extends BaseEntity implements ICustomStatementAddress {
    public id?: number;

    public vatRegNo?: string;

    public registrationNo?: string;

    public headerLogo?: IImage;

    public contactInfo?: IContactInfo;

    constructor(customStatementAddress?) {
        super();
        if (customStatementAddress) {
            this.id = customStatementAddress.id;

            this.vatRegNo = customStatementAddress.vatRegNo;

            this.registrationNo = customStatementAddress.registrationNo;

            this.headerLogo = new Image(customStatementAddress.headerLogo);

            this.contactInfo = new ContactInfo(customStatementAddress.contactInfo);
        } else {
            this.headerLogo = new Image();

            this.contactInfo = new ContactInfo();
        }
    }

    get discriminator(): any {
        return this.registrationNo;
    }

    clone(): CustomStatementAddress {
        return new CustomStatementAddress(this);
    }
}
