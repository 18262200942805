import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IDurationProductPriceInfo extends IBaseEntity {
    id?: number;
    price?: number;
    cost?: number;
    fromDate?: any;
    toDate?: any;
    secondTierVolumeThreshold?: number;
    secondTierPrice?: number;
    thirdTierVolumeThreshold?: number;
    thirdTierPrice?: number;
}

export class DurationProductPriceInfo extends BaseEntity implements IDurationProductPriceInfo {
    public id?: number;
    public price?: number;
    public cost?: number;
    public fromDate?: any;
    public toDate?: any;
    public secondTierVolumeThreshold?: number;
    public secondTierPrice?: number;
    public thirdTierVolumeThreshold?: number;
    public thirdTierPrice?: number;

    constructor(durationProductPriceInfo?) {
        super();
        if (durationProductPriceInfo) {
            this.id = durationProductPriceInfo.id;
            this.price = durationProductPriceInfo.price;
            this.cost = durationProductPriceInfo.cost;
            this.fromDate = MposDate.newInstance(durationProductPriceInfo.fromDate);
            this.toDate = MposDate.newInstance(durationProductPriceInfo.toDate);
            this.secondTierVolumeThreshold = durationProductPriceInfo.secondTierVolumeThreshold;
            this.secondTierPrice = durationProductPriceInfo.secondTierPrice;
            this.thirdTierVolumeThreshold = durationProductPriceInfo.thirdTierVolumeThreshold;
            this.thirdTierPrice = durationProductPriceInfo.thirdTierPrice;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DurationProductPriceInfo {
        return new DurationProductPriceInfo(this);
    }
}
