import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {VehicleService} from 'app/blocks/service/api/vehicle.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IVehicle} from 'app/blocks/model/vehicle.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Vehicle} from 'app/blocks/model/vehicle.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class VehicleResource extends AbstractEntityResource<IVehicle> {
    constructor(vehicleService: VehicleService, router: Router) {
        super(
            Resource.VEHICLES,
            vehicleService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.VEHICLES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.VEHICLES),
            (entity?) => new Vehicle(entity),
            (entity) => entity.vehicleId,
            'local_shipping'
        );
    }
}
