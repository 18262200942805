import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {ISchedule} from 'app/blocks/model/schedule.model';
import {IRoute} from 'app/blocks/model/route.model';
import {FormSchedule} from 'app/main/private/setup/customer/model/form-schedule.model';
import {IServiceOrderItem} from 'app/blocks/model/service-order-item.model';
import {IProduct} from 'app/blocks/model/product.model';

export interface IEquipment extends IBaseEntity {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    equipmentPrice?: number;
    equipmentMonthlyRent?: number;
    maintenanceFee?: number;
    customer?: ICustomer;
    deliveryPoint?: IDeliveryPoint;
    schedule?: ISchedule;
    formSchedule?: FormSchedule;
    route?: IRoute;
    lastServiceDate?: any;
    lastServicedBy?: IEmployee;
    invoiceStartDate?: any;
    invoiceEndDate?: any;
    invoiceType?: any;
    serviceOrderItems?: IProduct[];
}

export class Equipment extends BaseEntity implements IEquipment {
    public id?: number;
    public code?: string;
    public name?: string;
    public description?: string;
    public equipmentPrice?: number;
    public equipmentMonthlyRent?: number;
    public maintenanceFee?: number;
    public customer?: ICustomer;
    public deliveryPoint?: IDeliveryPoint;
    public schedule?: ISchedule;
    public route?: IRoute;
    public lastServiceDate?: any;
    public lastServicedBy?: IEmployee;
    public invoiceStartDate?: any;
    public invoiceEndDate?: any;
    public invoiceType?: any;
    public serviceOrderItems?: IProduct[];

    constructor(equipment?) {
        super();
        if (equipment) {
            this.id = equipment.id;
            this.code = equipment.code;
            this.name = equipment.name;
            this.description = equipment.description;
            this.equipmentPrice = equipment.equipmentPrice;
            this.equipmentMonthlyRent = equipment.equipmentMonthlyRent;
            this.customer = equipment.customer;
            this.deliveryPoint = equipment.deliveryPoint;
            this.schedule = equipment.schedule;
            this.route = equipment.route;
            this.lastServiceDate = equipment.lastServiceDate;
            this.lastServicedBy = equipment.lastServicedBy;
            this.maintenanceFee = equipment.maintenanceFee;
            this.invoiceStartDate = equipment.invoiceStartDate;
            this.invoiceEndDate = equipment.invoiceEndDate;
            this.invoiceType = equipment.invoiceType;
            this.serviceOrderItems = equipment.serviceOrderItems;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): Equipment {
        return new Equipment(this);
    }
}
