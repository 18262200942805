import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ISupplierGroup extends IBaseEntity {
    editable: any;
    id?: number;
    name?: string;
    description?: string;
}
export class SupplierGroup extends BaseEntity implements ISupplierGroup {
    public id?: number;
    public name?: string;
    public description?: string;

    constructor(supplierGroup?) {
        super();
        if (supplierGroup) {
            this.id = supplierGroup.id;
            this.name = supplierGroup.name;
            this.description = supplierGroup.description;
        } else {
        }
    }
    get discriminator(): any {
        return this.name;
    }

    editable: any;
    clone(): SupplierGroup {
        return new SupplierGroup(this);
    }
}
