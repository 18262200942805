import {DynamicHeaderCode} from 'app/blocks/model/dynamic-header-code.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDynamicHeaderCode} from 'app/blocks/model/dynamic-header-code.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DynamicHeaderCodeService extends EntityCrudService<IDynamicHeaderCode> {
    constructor(http: HttpClient) {
        // super(http, Resource.DYNAMIC_HEADER_CODES, (obj?) => new DynamicHeaderCode(obj), true);
        super(http, Resource.DYNAMIC_HEADER_CODES, (obj?) => new DynamicHeaderCode(obj));
    }
}
