import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PickOrderService} from 'app/blocks/service/api/pick-order.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {PickOrder, IPickOrder} from 'app/blocks/model/pick-order.model';

@Injectable({
    providedIn: 'root'
})
export class PickOrderResource extends AbstractEntityResource<IPickOrder> {
    constructor(pickOrderService: PickOrderService, router: Router) {
        super(
            Resource.PICK_ORDERS,
            pickOrderService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PICK_ORDERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PICK_ORDERS),
            (entity?) => new PickOrder(entity),
            (entity) => entity.id,
            'grading'
        );
    }
}
