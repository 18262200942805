import {IWarehouse} from 'app/blocks/model/warehouse.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {IBinType} from 'app/blocks/model/bin-type.model';

export interface IBin extends IBaseEntity {
    id?: number;
    location?: string;
    warehouse?: IWarehouse;
    quantityCapacity?: number;
    customer?: ICustomer;
    binType?: IBinType;
    binCode?: string;
    percentageUtilisation?: number;
}

export class Bin extends BaseEntity implements IBin {
    id: number;
    location: string;
    warehouse: IWarehouse;
    quantityCapacity: number;
    customer: ICustomer;
    binType: IBinType;
    binCode: string;
    percentageUtilisation: number;

    constructor(bin?) {
        super();
        if (bin) {
            this.id = bin.id;
            this.location = bin.location;
            this.warehouse = bin.warehouse;
            this.quantityCapacity = bin.quantityCapacity;
            this.customer = bin.customer;
            this.binType = bin.binType;
            this.binCode = bin.binCode;
            this.percentageUtilisation = bin.percentageUtilisation;
        } else {
        }
    }

    clone(): Bin {
        return new Bin(this);
    }
}
