import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MajorAccountCodeService} from 'app/blocks/service/api/major-account-code.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IMajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {MajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class MajorAccountCodeResource extends AbstractEntityResource<IMajorAccountCode> {
    constructor(majorAccountCodeService: MajorAccountCodeService, router: Router) {
        super(
            Resource.MAJOR_ACCOUNT_CODES,
            majorAccountCodeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.MAJOR_ACCOUNT_CODES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.MAJOR_ACCOUNT_CODES),
            (entity?) => new MajorAccountCode(entity),
            (entity) => entity.code,
            'account_balance_wallet'
        );
    }
}
