import {Workshop} from 'app/blocks/model/workshop.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IWorkshop} from 'app/blocks/model/workshop.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class WorkshopService extends EntityCrudService<IWorkshop> {
    constructor(http: HttpClient) {
        super(http, Resource.WORKSHOP, (obj?) => new Workshop(obj));
    }
}
