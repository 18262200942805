import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ISupplierGroup, SupplierGroup} from 'app/blocks/model/supplier-group.model';
import {SupplierGroupService} from 'app/blocks/service/api/supplier-group-service';

@Injectable({
    providedIn: 'root'
})
export class SupplerGroupResource extends AbstractEntityResource<ISupplierGroup> {
    constructor(supplierGroupService: SupplierGroupService, router: Router) {
        super(
            Resource.SUPPLIER_GROUPS,
            supplierGroupService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SUPPLIER_GROUPS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SUPPLIER_GROUPS),
            (entity?) => new SupplierGroup(entity),
            (entity) => entity.name,
            'group_work'
        );
    }
}
