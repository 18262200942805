import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IProductQuantity} from 'app/blocks/model/product-quantity.model';
import {IDeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {DiTime} from 'app/blocks/util/di-time';
import {INote, Note} from 'app/blocks/model/note.model';

export interface IPackOrder extends IBaseEntity {
    id?: number;
    packDate?: any;
    packTime?: any;
    packOrderStatus?: string;
    pickedBy?: IEmployee;
    packedBy?: IEmployee;
    saleOrderNo?: string;
    purchaseOrderNo?: string;
    pickedItems?: IProductQuantity[];
    packedItems?: IProductQuantity[];
    packages?: IDeliveryPackage[];
    scheduledDate?: string;
    internalNotes?: INote;
}

export class PackOrder extends BaseEntity implements IPackOrder {
    public id?: number;
    public packDate?: any;
    public packTime?: any;
    public packOrderStatus?: string;
    public packedBy?: IEmployee;
    public saleOrderNo?: string;
    public purchaseOrderNo?: string;
    public pickedItems?: IProductQuantity[];
    public packedItems?: IProductQuantity[];
    public packages?: IDeliveryPackage[];
    public scheduledDate?: string;
    public internalNotes?: INote;

    constructor(packOrder?) {
        super();
        if (packOrder) {
            this.id = packOrder.id;
            this.packDate = packOrder.packDate;
            this.packTime = DiTime.newInstance(packOrder.packTime);
            this.packOrderStatus = packOrder.packOrderStatus;
            this.packedBy = packOrder.packedBy;
            this.saleOrderNo = packOrder.saleOrderNo;
            this.purchaseOrderNo = packOrder.purchaseOrderNo;
            this.pickedItems = packOrder.pickedItems || [];
            this.packedItems = packOrder.packedItems || [];
            this.packages = packOrder.packages || [];
            this.scheduledDate = packOrder.scheduledDate;
            this.internalNotes = packOrder.internalNotes || new Note();
        } else {
            this.internalNotes = new Note();
            this.pickedItems = [];
            this.packedItems = [];
            this.packages = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): PackOrder {
        return new PackOrder(this);
    }
}
