import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {Image} from 'app/blocks/model/image.model';

export interface ICatalogueEntity extends IBaseEntity {
    id?: number;
    name?: string;
    customFieldGroupIds?: number[];
    employeeGroupIds?: number[];
    presentationIds?: number[];
    resourceGroupIds?: number[];
    coverImage?: Image;
    categories?: ICatalogueCategory[];
}

export interface ICatalogueCategory {
    id: number;
    name: string;
    parentId: number;
    contentFilters?: ICatalogueContentFilter[];
    selectedClasses?: number[];
    selectedBrands?: number[];
}
export interface ICatalogueAttributeFilter {
    id?: number;
    categoryId?: number;
    productAttributeTypeId?: number;
    attributeFilters?: string[];
}

export interface ICatalogueContentFilter {
    id?: number;
    categoryId?: number;
    attributeFilter?: string;
    attributeValue?: string;
    comparisonOperator?: string;
}

export class CatalogueEntity extends BaseEntity implements ICatalogueEntity {
    public id?: number;
    public name?: string;
    public customFieldGroupIds?: number[];
    public employeeGroupIds?: number[];
    public presentationIds?: number[];
    public resourceGroupIds?: number[];
    public categories?: ICatalogueCategory[];
    public coverImage?: Image;

    constructor(catalogue?: any) {
        super();
        if (catalogue) {
            this.id = catalogue.id;
            this.name = catalogue.name;
            this.customFieldGroupIds = catalogue.customFieldGroupIds ?? [];
            this.employeeGroupIds = catalogue.employeeGroupIds ?? [];
            this.presentationIds = catalogue.presentationIds ?? [];
            this.resourceGroupIds = catalogue.resourceGroupIds ?? [];
            this.categories = catalogue.categories ?? [];
            this.coverImage = catalogue.coverImage;
        }
    }

    clone(): IBaseEntity {
        return new CatalogueEntity(this);
    }
}
