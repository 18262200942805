import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {CataloguePresentation, ICataloguePresentation} from 'app/blocks/model/presentation.model';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PresentationService extends EntityCrudService<ICataloguePresentation> {
    constructor(http: HttpClient) {
        super(http, Resource.PRESENTATIONS, (obj?) => new CataloguePresentation(obj));
    }

    uploadFile = (label: string, file: File, presentationId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', label);
        return this.http.post<any>(`${SERVER_API_URL}api/presentations/${presentationId}/slides`, fd).toPromise();
    };

    downloadFile = (presentationId: number, slideId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/presentations/${presentationId}/slides/${slideId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    deleteFile = (presentationId: number, slideId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/presentations/${presentationId}/slides/${slideId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    downloadImage(presentationId: number, slideId: number): Observable<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/presentations/${presentationId}/slides/${slideId}`;
        return this.http.get(REQUEST_URI, {responseType: 'blob'});
    }
}
