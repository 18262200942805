import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {SERVICE_ORDERS_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ServiceItemService} from 'app/blocks/service/api/service-item.service';
import {ServiceItem, IServiceItem} from 'app/blocks/model/service-item.model';

@Injectable({
    providedIn: 'root'
})
export class ServiceItemResource extends AbstractEntityResource<IServiceItem> {
    constructor(serviceItemService: ServiceItemService, router: Router) {
        super(
            Resource.SERVICE_ITEMS,
            serviceItemService,
            new EntityNavigator(SERVICE_ORDERS_BASE_URL, Resource.SERVICE_ITEMS, router),
            new EntityUrlProvider(SERVICE_ORDERS_BASE_URL, Resource.SERVICE_ITEMS),
            (entity?) => new ServiceItem(entity),
            (entity) => entity.code,
            'support_agent'
        );
    }
}
