import {LoadOrder} from 'app/blocks/model/load-order.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ILoadOrder} from 'app/blocks/model/load-order.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {saveAs} from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class LoadOrderService extends EntityCrudService<ILoadOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.LOAD_ORDERS, (obj?) => new LoadOrder(obj));
    }

    process(request: any): Promise<HttpResponse<any>> {
        return this.http
            .post<any>(SERVER_API_URL + 'api/load-orders/process', request, {
                observe: 'response'
            })
            .toPromise();
    }

    cancel(loadOrderId: number, cancelDate: string, cancelTime: string): Promise<HttpResponse<any>> {
        const requestParams = new HttpParams().set('cancelDate', cancelDate).set('cancelTime', cancelTime);

        return this.http
            .post<any>(
                SERVER_API_URL + `api/load-orders/${loadOrderId}/cancel`,
                {},
                {
                    observe: 'response',
                    params: requestParams
                }
            )
            .toPromise();
    }

    public downloadMergedLoadOrders = (invoiceIds: number[]): any => {
        const requestParams = new HttpParams().set('ids', invoiceIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/load-orders/download`;
        return this.http.get(REQUEST_URI, {
            responseType: 'arraybuffer',
            params: requestParams
        });
    };

    public bulkSummaryDownloadPDFsForLoadOrders = (loadOrderIds: number[]): any => {
        const requestParams = new HttpParams().set('ids', loadOrderIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/load-orders/download-summary`;
        return this.http.get(REQUEST_URI, {
            responseType: 'arraybuffer',
            params: requestParams
        });
    };

    public bulkCancelLoadOrders = (loadOrderIds: number[], cancelDate: string, cancelTime: string): any => {
        if (loadOrderIds == null) {
            return;
        }

        const REQUEST_URI = `${SERVER_API_URL}api/load-orders/bulk-cancel`;

        return this.http
            .post<any>(
                REQUEST_URI,
                {
                    ids: loadOrderIds,
                    cancelDate: cancelDate,
                    cancelTime: cancelTime
                },
                {observe: 'response'}
            )
            .toPromise();
    };

    public downloadPDF = (id: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/load-orders/${id}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public getExportSettings = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/export-settings/load-orders`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public saveExportSettings = (exportSettings: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/export-settings/load-orders`;
        return this.http.put<any>(REQUEST_URI, exportSettings, {observe: 'response'}).toPromise();
    };

    public getLoadOrderNotes = (id: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/load-orders/` + id + `/notes`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public saveLoadOrders = (updatedLoadOrders): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/load-orders/bulk/`;
        return this.http.put<any>(REQUEST_URI, updatedLoadOrders, {observe: 'response'}).toPromise();
    };
}
