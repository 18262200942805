import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DepositItemService} from 'app/blocks/service/api/deposit-item.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDepositItem} from 'app/blocks/model/deposit-item.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DepositItem} from 'app/blocks/model/deposit-item.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DepositItemResource extends AbstractEntityResource<IDepositItem> {
    constructor(depositItemService: DepositItemService, router: Router) {
        super(
            Resource.DEPOSIT_ITEMS,
            depositItemService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DEPOSIT_ITEMS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DEPOSIT_ITEMS),
            (entity?) => new DepositItem(entity),
            (entity) => entity.name,
            'add_shopping_cart'
        );
    }
}
