import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {CustomPdfTemplate, ICustomPdfTemplate} from 'app/blocks/model/custom-pdf-template.model';
import {SERVER_API_URL} from 'app/app.constants';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomPdfTemplateService extends EntityCrudService<ICustomPdfTemplate> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOM_PDF_TEMPLATES, (obj?) => new CustomPdfTemplate(obj));
    }

    generateInvoicePdf(invoiceId: number, templateId: number): Promise<any> {
        return lastValueFrom(this.http.post(`${SERVER_API_URL}api/custom-pdf-templates/${templateId}/invoice/${invoiceId}`, {observe: 'response'}));
    }

    async getFontsForPdf() {
        const fontList = ['Roboto-Regular', 'Roboto-Bold', 'Roboto-Thin', 'Roboto-Black'];
        const fontData = await Promise.all(
            fontList.map((font) => {
                return lastValueFrom(
                    this.http.get(`https://di-public-static-assets.s3.eu-west-1.amazonaws.com/fonts/${font}.ttf`, {responseType: 'arraybuffer'})
                );
            })
        );
        const fontMap: Record<string, {data: ArrayBuffer; fallback: boolean}> = {};
        fontList.forEach((font, idx) => {
            fontMap[font] = {
                data: fontData[idx],
                fallback: font === 'Roboto-Regular'
            };
        });
        return fontMap;
    }
}
