import {BillingFrequencyType} from 'app/blocks/model/billing-frequency-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IBillingFrequencyType} from 'app/blocks/model/billing-frequency-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class BillingFrequencyTypeService extends EntityCrudService<IBillingFrequencyType> {
    constructor(http: HttpClient) {
        super(http, Resource.BILLING_FREQUENCY_TYPES, (obj?) => new BillingFrequencyType(obj));
    }
}
