import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ISGSTCategory, SGSTCategory} from 'app/blocks/model/sgst-category.model';

@Injectable({
    providedIn: 'root'
})
export class SgstCategoryService extends EntityCrudService<ISGSTCategory> {
    constructor(http: HttpClient) {
        super(http, Resource.IN_SGST_RATES, (obj?) => new SGSTCategory(obj));
    }
}
