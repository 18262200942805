import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IExpenseType} from 'app/blocks/model/expense-type.model';
import {IExpensePaymentMethod} from 'app/blocks/model/expense-payment-method';
import {IEmployee} from 'app/blocks/model/employee.model';

export enum ExpenseStatus {
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export interface IEmployeeExpenses extends IBaseEntity {
    id?: number;
    employee?: IEmployee;
    value?: number;
    vat?: number;
    notes?: string;
    expenseDate?: any;
    numberOfImages?: number;
    expenseType?: IExpenseType;
    expensePaymentMethod?: IExpensePaymentMethod;
    expenseStatus: ExpenseStatus;
}

export class EmployeeExpenses extends BaseEntity implements IEmployeeExpenses {
    public id: number;
    employee?: IEmployee;
    public value?: number;
    public vat?: number;
    public notes?: string;
    public expenseDate?: any;
    public numberOfImages?: number;
    expenseType?: IExpenseType;
    expensePaymentMethod?: IExpensePaymentMethod;
    expenseStatus: ExpenseStatus;

    constructor(employeeExpense?: any) {
        super();
        if (employeeExpense) {
            this.id = employeeExpense.id;
            this.employee = employeeExpense.employee;
            this.value = employeeExpense.value;
            this.vat = employeeExpense.vat;
            this.notes = employeeExpense.notes;
            this.expenseDate = employeeExpense.expenseDate;
            this.numberOfImages = employeeExpense.numberOfImages;
            this.expenseType = employeeExpense.expenseType;
            this.expensePaymentMethod = employeeExpense.expensePaymentMethod;
            this.expenseStatus = employeeExpense.expenseStatus || ExpenseStatus.PENDING_APPROVAL;
        }
    }

    clone(): EmployeeExpenses {
        return new EmployeeExpenses(this);
    }
}
