import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IQuestionCategory, QuestionCategory} from 'app/blocks/model/check-list-category.model';
import {IVehicleType, VehicleType} from 'app/blocks/model/vehicle-type.model';

export interface ISafetyProfile extends IBaseEntity {
    id?: number;
    name?: string;
    categories?: IQuestionCategory[];
    vehicleTypes?: IVehicleType[];
}

export class SafetyProfile extends BaseFranchiseEntity implements ISafetyProfile {
    public id?: number;
    public name?: string;
    public categories?: QuestionCategory[];
    public vehicleTypes?: VehicleType[];

    constructor(safetyProfile?: any) {
        super();
        if (safetyProfile) {
            this.id = safetyProfile.id;
            this.name = safetyProfile.name;
            this.categories = safetyProfile.categories;
            this.vehicleTypes = safetyProfile.vehicleTypes || [];
            this.editable = safetyProfile.editable;
        } else {
            this.vehicleTypes = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): SafetyProfile {
        return new SafetyProfile(this);
    }
}
