import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IVehicleSkill, VehicleSkill} from 'app/blocks/model/vehicle-skill.model';
import {VehicleSkillService} from 'app/blocks/service/api/vehicle-skill.service';

@Injectable({
    providedIn: 'root'
})
export class VehicleSkillResource extends AbstractEntityResource<IVehicleSkill> {
    constructor(vehicleSkillService: VehicleSkillService, router: Router) {
        super(
            Resource.VEHICLE_SKILL,
            vehicleSkillService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.VEHICLE_SKILL, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.VEHICLE_SKILL),
            (entity?) => new VehicleSkill(entity),
            (entity) => entity.code,
            'share'
        );
    }
}
