import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IServiceItem, ServiceItem} from 'app/blocks/model/service-item.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ServiceItemService extends EntityCrudService<IServiceItem> {
    constructor(http: HttpClient) {
        super(http, Resource.SERVICE_ITEMS, (obj?) => new ServiceItem(obj));
    }
}
