import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IOrderItem} from 'app/blocks/model/order-item.model';
import {IProductQuantity} from 'app/blocks/model/product-quantity.model';
import {PickOrderStatus} from 'app/blocks/model/pick-order-status';
import {DiTime} from 'app/blocks/util/di-time';
import {INote, Note} from 'app/blocks/model/note.model';
import {pick} from 'lodash';

export interface IPickOrder extends IBaseEntity {
    id?: number;
    pickStartDate?: any;
    pickStartTime?: any;
    pickDate?: any;
    pickTime?: any;
    pickDurationMins?: number;
    pickOrderStatus?: PickOrderStatus;
    assignedTo?: IEmployee;
    pickedBy?: IEmployee;
    orderItems?: IOrderItem[];
    saleOrderNo?: string;
    purchaseOrderNo?: string;
    warehouseName?: string;
    pickedItems?: IProductQuantity[];
    scheduledDate?: string;
    skipPack?: boolean;
    batchInfoRequired?: boolean;
    internalNotes?: INote;
    notes?: INote;
    warehouseId?: number;
}

export class PickOrder extends BaseEntity implements IPickOrder {
    public id?: number;
    public pickStartDate?: any;
    public pickStartTime?: any;
    public pickDate?: any;
    public pickTime?: any;
    public pickDurationMins?: number;
    public pickOrderStatus?: PickOrderStatus;
    public assignedTo?: IEmployee;
    public pickedBy?: IEmployee;
    public orderItems?: IOrderItem[];
    public saleOrderNo?: string;
    public purchaseOrderNo?: string;
    public warehouseName?: string;
    public pickedItems?: IProductQuantity[];
    public skipPack?: boolean;
    public batchInfoRequired?: boolean;
    public scheduledDate?: string;
    public internalNotes?: INote;
    public notes?: INote;
    public warehouseId?: number;

    constructor(pickOrder?) {
        super();
        if (pickOrder) {
            this.id = pickOrder.id;
            this.pickStartDate = pickOrder.pickStartDate;
            this.pickStartTime = pickOrder.pickStartTime;
            this.pickDate = pickOrder.pickDate;
            this.pickTime = DiTime.newInstance(pickOrder.pickTime);
            this.pickDurationMins = pickOrder.pickDurationMins;
            this.pickOrderStatus = pickOrder.pickOrderStatus;
            this.assignedTo = pickOrder.assignedTo;
            this.pickedBy = pickOrder.pickedBy;
            this.orderItems = pickOrder.orderItems || [];
            this.saleOrderNo = pickOrder.saleOrderNo;
            this.purchaseOrderNo = pickOrder.purchaseOrderNo;
            this.warehouseName = pickOrder.warehouseName;
            this.pickedItems = pickOrder.pickedItems || [];
            this.skipPack = pickOrder.skipPack;
            this.batchInfoRequired = pickOrder.batchInfoRequired;
            this.scheduledDate = pickOrder.scheduledDate;
            this.internalNotes = pickOrder.internalNotes || new Note();
            this.notes = pickOrder.notes || new Note();
            this.warehouseId = pickOrder.warehouseId;
        } else {
            this.orderItems = [];
            this.pickedItems = [];
            this.internalNotes = new Note();
            this.notes = new Note();
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): PickOrder {
        return new PickOrder(this);
    }
}
