import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OrderPriorityService} from 'app/blocks/service/api/order-priority.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IOrderPriority} from 'app/blocks/model/order-priority.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {OrderPriority} from 'app/blocks/model/order-priority.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class OrderPriorityResource extends AbstractEntityResource<IOrderPriority> {
    constructor(orderPriorityService: OrderPriorityService, router: Router) {
        super(
            Resource.ORDER_PRIORITIES,
            orderPriorityService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ORDER_PRIORITIES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ORDER_PRIORITIES),
            (entity?) => new OrderPriority(entity),
            (entity) => entity.code
        );
    }
}
