import {ProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ProductTaxCategoryService extends EntityCrudService<IProductTaxCategory> {
    constructor(http: HttpClient) {
        super(http, Resource.UK_VAT_RATES, (obj?) => new ProductTaxCategory(obj));
    }
}
