import {CGSTCategory, ICGSTCategory} from 'app/blocks/model/cgst-category.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class CgstCategoryService extends EntityCrudService<ICGSTCategory> {
    constructor(http: HttpClient) {
        super(http, Resource.IN_CGST_RATES, (obj?) => new CGSTCategory(obj));
    }
}
