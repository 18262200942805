import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faMoneyCheckAlt} from '@fortawesome/free-solid-svg-icons';
import {ExpenseTypeService} from 'app/blocks/service/api/expense-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IExpenseType, ExpenseType} from 'app/blocks/model/expense-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ExpenseTypeResource extends AbstractEntityResource<IExpenseType> {
    constructor(expenseTypeService: ExpenseTypeService, router: Router) {
        super(
            Resource.EXPENSE_TYPES,
            expenseTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.EXPENSE_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.EXPENSE_TYPES),
            (entity?) => new ExpenseType(entity),
            (entity) => entity.name
        );
    }
}
