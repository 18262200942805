import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CompanyService} from 'app/blocks/service/api/company.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICompany} from 'app/blocks/model/company.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Company} from 'app/blocks/model/company.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CompanyResource extends AbstractEntityResource<ICompany> {
    constructor(companyService: CompanyService, router: Router) {
        super(
            Resource.COMPANIES,
            companyService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.COMPANIES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.COMPANIES),
            (entity?) => new Company(entity),
            (entity) => entity.name,
            'apartment'
        );
    }
}
