import {IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface ICustomFieldValue {
    id?: number;
    fieldValue?: string;
    entityId?: number;
    customFieldId?: number;
    entityName?: string;
}

export interface ICustomField extends IBaseEntity {
    id?: number;

    fieldName?: string;

    fieldType?: string;

    isRequired?: boolean;

    minValue?: number;

    maxValue?: number;

    customFieldValues?: ICustomFieldValue[];
}

export class CustomField extends BaseFranchiseEntity implements ICustomField {
    public id?: number;

    public fieldName?: string;

    public fieldType?: string = 'TEXT';

    public isRequired?: boolean = false;

    public minValue?: number;

    public maxValue?: number;

    public customFieldValues?: ICustomFieldValue[];

    constructor(customField?) {
        super();
        if (customField) {
            this.id = customField.id;

            this.fieldName = customField.fieldName;

            this.fieldType = customField.fieldType ?? 'TEXT';

            this.isRequired = customField.isRequired ?? false;

            this.minValue = customField.minValue;

            this.maxValue = customField.maxValue;

            this.customFieldValues = customField.customFieldValues;

            this.editable = customField.editable ?? true;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): CustomField {
        return new CustomField(this);
    }
}
