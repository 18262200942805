import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IBudgetItem} from 'app/blocks/model/budget-item.model';

export interface IBudget extends IBaseFranchiseEntity {
    id?: number;
    name?: string;
    code?: string;
    startDate?: any;
    endDate?: any;
    budgetType?: string;
    totalAmount?: number;
    budgetItems?: IBudgetItem[];
}

export class Budget extends BaseFranchiseEntity implements IBudget {
    public id: number;
    public name: string;
    public code: string;
    public startDate: any;
    public endDate: any;
    public budgetType: string;
    public totalAmount: number;
    public budgetItems: IBudgetItem[];

    constructor(budget?) {
        super();
        if (budget) {
            this.id = budget.id;
            this.name = budget.name;
            this.code = budget.code;
            this.startDate = MposDate.newInstance(budget.startDate);
            this.endDate = MposDate.newInstance(budget.endDate);
            this.budgetType = budget.budgetType;
            this.totalAmount = budget.totalAmount;
            this.budgetItems = budget.budgetItems;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Budget {
        return new Budget(this);
    }
}
