import {CgstCategoryService} from 'app/blocks/service/api/cgst-category.service';
import {CGSTCategory} from 'app/blocks/model/cgst-category.model';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ISGSTCategory, SGSTCategory} from 'app/blocks/model/sgst-category.model';
import {SgstCategoryService} from 'app/blocks/service/api/sgst-category.service';

@Injectable({
    providedIn: 'root'
})
export class SGSTCategoryResource extends AbstractEntityResource<ISGSTCategory> {
    constructor(sgstCategoryService: SgstCategoryService, router: Router) {
        super(
            Resource.IN_SGST_RATES,
            sgstCategoryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.IN_SGST_RATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.IN_SGST_RATES),
            (entity?) => new SGSTCategory(entity),
            (entity) => entity.label,
            'attach_money'
        );
    }
}
