import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IFuelSurchargeGroup extends IBaseEntity {
    id?: number;
    name?: string;
}

export class FuelSurchargeGroup extends BaseEntity implements IFuelSurchargeGroup {
    id: number;
    name: string;

    constructor(fuelSurchargeGroup?) {
        super();
        if (fuelSurchargeGroup) {
            this.id = fuelSurchargeGroup.id;
            this.name = fuelSurchargeGroup.name;
        }
    }

    clone(): FuelSurchargeGroup {
        return new FuelSurchargeGroup(this);
    }
}
