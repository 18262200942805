import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IVehicle} from 'app/blocks/model/vehicle.model';
import {IRoute} from 'app/blocks/model/route.model';
import {IIncomingInventory} from 'app/blocks/model/inventory-movement.model';
import {DiTime} from 'app/blocks/util/di-time';

export interface IVehicleUnload extends IBaseEntity {
    id?: number;
    vehicle?: IVehicle;
    driver?: IEmployee;
    route?: IRoute;
    incomingInventory: IIncomingInventory;
    unloadDate: any;
    unloadTime: any;
    sourceWarehouse: any;
    destWarehouse: any;
}

export class VehicleUnload extends BaseEntity implements IVehicleUnload {
    public id?: number;
    public vehicle?: IVehicle;
    public driver?: IEmployee;
    public route?: IRoute;
    public incomingInventory: IIncomingInventory;
    public unloadDate: any;
    public unloadTime: any;
    public sourceWarehouse: any;
    public destWarehouse: any;
    constructor(vehicleUnload?) {
        super();
        if (vehicleUnload) {
            this.id = vehicleUnload.id;
            this.incomingInventory = vehicleUnload.incomingInventory;
            this.vehicle = vehicleUnload.vehicle;
            this.driver = vehicleUnload.driver;
            this.route = vehicleUnload.route;
            this.unloadDate = MposDate.newInstance(vehicleUnload.date);
            this.unloadTime = DiTime.newInstance(vehicleUnload.time);
            this.sourceWarehouse = vehicleUnload.sourceWarehouse;
            this.destWarehouse = vehicleUnload.destWarehouse;
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): VehicleUnload {
        return new VehicleUnload(this);
    }
}
