import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IMposfile} from 'app/blocks/model/mpos-file.model';
import {Image} from 'app/blocks/model/image.model';

export interface IResourceContentFilter {
    id?: number;
    resourceId?: number;
    fieldName?: string;
    fieldValue?: string;
}

export interface ICatalogueResourceItem extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    groupId?: number;
    assets?: IMposfile[];
    thumbnail?: Image;
    linkImage?: Image;
    contentFilters?: IResourceContentFilter[];
    selectedClasses?: number[];
    selectedBrands?: number[];
}

export class CatalogueResourceItem extends BaseEntity implements ICatalogueResourceItem {
    public id?: number;
    public name?: string;
    description?: string;
    public groupId?: number;
    public assets: IMposfile[];
    public thumbnail?: Image;
    public linkImage?: Image;
    public contentFilters?: IResourceContentFilter[];
    public selectedClasses?: number[];
    public selectedBrands?: number[];

    constructor(resource?: any) {
        super();
        if (resource) {
            this.id = resource.id;
            this.name = resource.name;
            this.description = resource.description;
            this.assets = resource.assets ?? [];
            this.groupId = resource.groupId;
            this.thumbnail = resource.thumbnail;
            this.linkImage = resource.linkImage;
            this.contentFilters = resource.contentFilters ?? [];
            this.selectedClasses = resource.selectedClasses ?? [];
            this.selectedBrands = resource.selectedBrands ?? [];
        }
    }

    clone(): IBaseEntity {
        return new CatalogueResourceItem(this);
    }
}
