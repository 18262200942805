import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {VehicleTypeService} from 'app/blocks/service/api/vehicle-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IVehicleType} from 'app/blocks/model/vehicle-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {VehicleType} from 'app/blocks/model/vehicle-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class VehicleTypeResource extends AbstractEntityResource<IVehicleType> {
    constructor(vehicleTypeService: VehicleTypeService, router: Router) {
        super(
            Resource.VEHICLE_TYPES,
            vehicleTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.VEHICLE_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.VEHICLE_TYPES),
            (entity?) => new VehicleType(entity),
            (entity) => entity.type,
            'style'
        );
    }
}
