import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {createRequestOption} from 'app/blocks/util/request-util';
import {SERVER_API_URL} from 'app/app.constants';
import {ISupplierGroup, SupplierGroup} from 'app/blocks/model/supplier-group.model';

@Injectable({
    providedIn: 'root'
})
export class SupplierGroupService extends EntityCrudService<ISupplierGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.SUPPLIER_GROUPS, (obj?) => new SupplierGroup(obj));
    }

    searchSupplierGroupsByText = (pageNumber, size, text): Promise<HttpResponse<any>> => {
        const params = {query: text, page: pageNumber, size: size};
        const options = createRequestOption(params);
        return this.http
            .get(`${SERVER_API_URL}api/_search/supplier-groups`, {
                observe: 'response',
                params: options
            })
            .toPromise();
    };
}
