import {ISurveyReport, SurveyReport} from 'app/blocks/model/survey-report.model';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';
import {HISTORY_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {SurveyReportService} from 'app/blocks/service/api/survey-report.service';

@Injectable({
    providedIn: 'root'
})
export class SurveyReportResource extends AbstractEntityResource<ISurveyReport> {
    constructor(surveyReportService: SurveyReportService, router: Router) {
        super(
            Resource.SURVEY_REPORT,
            surveyReportService,
            new EntityNavigator(HISTORY_BASE_URL, Resource.SURVEY_REPORT, router),
            new EntityUrlProvider(HISTORY_BASE_URL, Resource.SURVEY_REPORT),
            (entity?) => new SurveyReport(entity),
            (entity) => entity.name,
            'inventory'
        );
    }
}
