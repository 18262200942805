import {StopType} from 'app/blocks/model/stop-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IStopType} from 'app/blocks/model/stop-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class StopTypeService extends EntityCrudService<IStopType> {
    constructor(http: HttpClient) {
        super(http, Resource.STOP_TYPES, (obj?) => new StopType(obj));
    }
}
