import {DeliveryItem} from 'app/blocks/model/delivery-item.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDeliveryItem} from 'app/blocks/model/delivery-item.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryItemService extends EntityCrudService<IDeliveryItem> {
    constructor(http: HttpClient) {
        super(http, Resource.DELIVERY_ITEMS, (obj?) => new DeliveryItem(obj));
    }
}
