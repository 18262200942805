import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {StopTypeService} from 'app/blocks/service/api/stop-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IStopType} from 'app/blocks/model/stop-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {StopType} from 'app/blocks/model/stop-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class StopTypeResource extends AbstractEntityResource<IStopType> {
    constructor(stopTypeService: StopTypeService, router: Router) {
        super(
            Resource.STOP_TYPES,
            stopTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.STOP_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.STOP_TYPES),
            (entity?) => new StopType(entity),
            (entity) => entity.code,
            'group_work'
        );
    }
}
