import {DailyActivitySummary, IDailyActivitySummary} from 'app/blocks/model/daily-activity-summary.model';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CompanyService} from 'app/blocks/service/api/company.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICompany} from 'app/blocks/model/company.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Company} from 'app/blocks/model/company.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {DailyActivitySummaryService} from 'app/blocks/service/api/daily-activity-summary.service';

@Injectable({
    providedIn: 'root'
})
export class DailyActivitySummaryResource extends AbstractEntityResource<IDailyActivitySummary> {
    constructor(dailyActivitySummaryService: DailyActivitySummaryService, router: Router) {
        super(
            Resource.DAILY_ACTIVITY_SUMMARIES,
            dailyActivitySummaryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DAILY_ACTIVITY_SUMMARIES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DAILY_ACTIVITY_SUMMARIES),
            (entity?) => new DailyActivitySummary(entity),
            (entity) => entity.name,
            'summarize'
        );
    }
}
