import {ICustomStatementAddress, CustomStatementAddress} from 'app/blocks/model/custom-statement-address.model';

import {IBillingFrequencyType, BillingFrequencyType} from 'app/blocks/model/billing-frequency-type.model';

import {IStatementType, StatementType} from 'app/blocks/model/statement-type.model';

import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IStatementCode extends IBaseEntity {
    id?: number;
    code?: string;
    description?: string;
    statementDisplayText?: string;
    dueInDaysFromStatementDate?: number;
    useCustomStatementAddress?: boolean;
    accountingMessage?: any;
    statementNotes?: any;
    customStatementAddress?: ICustomStatementAddress;
    billingFrequencyType?: IBillingFrequencyType;
    type?: IStatementType;
    invoiceConsolidationDay?: string;
    invoiceConsolidationDate?: number;
    invoiceConsolidationPeriod?: string;
}

export class StatementCode extends BaseEntity implements IStatementCode {
    public id?: number;
    public code?: string;
    public description?: string;
    public statementDisplayText?: string;
    public dueInDaysFromStatementDate?: number;
    public useCustomStatementAddress?: boolean;
    public accountingMessage?: any;
    public statementNotes?: any;
    public customStatementAddress?: ICustomStatementAddress;
    public billingFrequencyType?: IBillingFrequencyType;
    public type?: IStatementType;
    public invoiceConsolidationDay?: string;
    public invoiceConsolidationDate?: number;
    public invoiceConsolidationPeriod?: string;

    constructor(statementCode?) {
        super();
        if (statementCode) {
            this.id = statementCode.id;
            this.code = statementCode.code;
            this.description = statementCode.description;
            this.statementDisplayText = statementCode.statementDisplayText;
            this.dueInDaysFromStatementDate = statementCode.dueInDaysFromStatementDate;
            this.useCustomStatementAddress = statementCode.useCustomStatementAddress;
            this.accountingMessage = statementCode.accountingMessage;
            this.statementNotes = statementCode.statementNotes;
            this.customStatementAddress = new CustomStatementAddress(statementCode.customStatementAddress);
            this.billingFrequencyType = statementCode.billingFrequencyType;
            this.type = statementCode.type;
            this.invoiceConsolidationDate = statementCode.invoiceConsolidationDate;
            this.invoiceConsolidationDay = statementCode.invoiceConsolidationDay;
            this.invoiceConsolidationPeriod = statementCode.invoiceConsolidationPeriod;
        } else {
            this.customStatementAddress = new CustomStatementAddress();
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): StatementCode {
        return new StatementCode(this);
    }
}
