import {Vehicle} from 'app/blocks/model/vehicle.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IVehicle} from 'app/blocks/model/vehicle.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Schedule} from 'app/blocks/model/schedule.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class VehicleService extends EntityCrudService<IVehicle> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLES, (obj?) => new Vehicle(obj));
    }

    public addScheduleMaintenance = (requestParams: any): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/vehicles/schedule-maintenance`;
        return this.http.post(REQUEST_URI, requestParams).toPromise();
    };

    public getScheduleMaintenance = (vehicleId: any): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/vehicles/schedule-maintenance/${vehicleId}`;
        return this.http.get(REQUEST_URI).toPromise();
    };
}
