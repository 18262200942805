import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {DiscountTypes} from 'app/blocks/model/discount-types';

export interface IDiscountType extends IBaseEntity {
    name?: DiscountTypes;
}

export class DiscountType extends BaseEntity implements IDiscountType {
    public name?: DiscountTypes;

    constructor(name?) {
        super();
        if (name) {
            this.name = name;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): DiscountType {
        return new DiscountType(this);
    }
}
