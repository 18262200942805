import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface ISGSTCategory extends IBaseEntity {
    id?: number;
    label?: string;
    isDefaultInGroup?: boolean;
    taxPercentage: number;
}

export class SGSTCategory extends BaseEntity implements ISGSTCategory {
    public id?: number;
    public label?: string;
    public isDefaultInGroup?: boolean;
    public taxPercentage: number;

    constructor(sgstCategory?) {
        super();
        if (sgstCategory) {
            this.id = sgstCategory.id;
            this.label = sgstCategory.label;
            this.isDefaultInGroup = sgstCategory.isDefaultInGroup;
            this.taxPercentage = sgstCategory.taxPercentage;
        }
    }

    get discriminator(): any {
        return this.label;
    }

    clone(): SGSTCategory {
        return new SGSTCategory(this);
    }
}
