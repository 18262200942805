import {Warehouse} from 'app/blocks/model/warehouse.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IWarehouse} from 'app/blocks/model/warehouse.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService extends EntityCrudService<IWarehouse> {
    constructor(http: HttpClient) {
        super(http, Resource.WAREHOUSES, (obj?) => new Warehouse(obj));
    }

    getInventoryStock = (requestParams?: any): Promise<HttpResponse<any>> => {
        const options = createRequestOption(requestParams);
        return this.http
            .get<any>(SERVER_API_URL + 'api/inventory-stocks/warehouse', {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getInventoryStockForStockTake = (requestParams?: any): Promise<HttpResponse<any>> => {
        const options = createRequestOption(requestParams);
        return this.http
            .get<any>(SERVER_API_URL + 'api/stock-take-items/warehouse', {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getDeliveryPackages = (requestParams?: any): Promise<HttpResponse<any>> => {
        const options = createRequestOption(requestParams);
        return this.http
            .get<any>(SERVER_API_URL + 'api/inventory-stocks/warehouse-packages', {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getWarehouseBins = (warehouseId): Promise<HttpResponse<any>> => {
        const url = SERVER_API_URL + 'api/warehouses/bins/' + warehouseId;
        return this.http
            .get<any>(url, {
                observe: 'response'
            })
            .toPromise();
    };

    getInventoryStockForProduct = (id: number, requestParams?: any): Promise<HttpResponse<any>> => {
        const options = createRequestOption(requestParams);
        return this.http
            .get<any>(SERVER_API_URL + 'api/inventory-stocks/product/' + id, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getWarehouse = (warehouseId: any): Promise<HttpResponse<any>> => {
        return this.http
            .get<any>(SERVER_API_URL + 'api/warehouses/' + warehouseId, {
                observe: 'response'
            })
            .toPromise();
    };

    getRouteVanStock = (routeId, productIds, date): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('routeId', routeId);
        params = params.append('productIds', productIds);
        params = params.append('date', date);
        return this.http
            .get(SERVER_API_URL + 'api/inventory-stocks/van-stock', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    public getWarehouseStockCSV = (id: number, requestParams: any): any => {
        const options = createRequestOption(requestParams);
        const REQUEST_URI = `${SERVER_API_URL}api/inventory-stocks/export/${id}/`;
        return this.http.get(REQUEST_URI, {
            params: options,
            responseType: 'arraybuffer'
        });
    };

    public getAllWarehouseStockCSV = (requestParams: any): any => {
        const options = createRequestOption(requestParams);
        const REQUEST_URI = `${SERVER_API_URL}api/inventory-stocks/export/all`;
        return this.http.get(REQUEST_URI, {
            params: options,
            responseType: 'arraybuffer'
        });
    };

    public getAllInventoryStockTakeItemsCSV = (requestParams: any): any => {
        const options = createRequestOption(requestParams);
        const REQUEST_URI = `${SERVER_API_URL}api/stock-take-items/export/all`;
        return this.http.get(REQUEST_URI, {
            params: options,
            responseType: 'arraybuffer'
        });
    };

    public emailWarehouseStockCSV = (id: number, requestParams: any): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/inventory-stocks/email/${id}`;
        return this.http.post(REQUEST_URI, requestParams).toPromise();
    };

    addStockTake = (entity: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/add-stock-take', entity).toPromise();
    };
    public getAllInventoryStockTakeItemsPdf = (requestParams: any): any => {
        const options = createRequestOption(requestParams);
        const REQUEST_URI = `${SERVER_API_URL}api/stock-take-items/download-all`;
        return this.http.get(REQUEST_URI, {
            params: options,
            responseType: 'arraybuffer'
        });
    };
}
