import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IProductQuantity} from 'app/blocks/model/product-quantity.model';
import {IDeliveryPackageType} from 'app/blocks/model/delivery-package-type.model';

export interface IDeliveryPackage extends IBaseEntity {
    id?: number;
    identifier?: string;
    barcode?: string;
    weight?: number;
    length?: number;
    width?: number;
    height?: number;
    items?: IProductQuantity[];
}

export class DeliveryPackage extends BaseEntity implements IDeliveryPackage {
    public id?: number;
    public identifier?: string;
    public barcode?: string;
    public weight?: number;
    public length?: number;
    public width?: number;
    public height?: number;
    public items?: IProductQuantity[];
    public type?: IDeliveryPackageType;

    constructor(deliveryPackage?) {
        super();
        if (deliveryPackage) {
            this.id = deliveryPackage.id;
            this.identifier = deliveryPackage.identifier;
            this.barcode = deliveryPackage.barcode;
            this.weight = deliveryPackage.weight;
            this.length = deliveryPackage.length;
            this.width = deliveryPackage.width;
            this.height = deliveryPackage.height;
            this.items = deliveryPackage.items || [];
            this.type = deliveryPackage.type;
        } else {
            this.items = [];
        }
    }

    get discriminator(): any {
        return this.identifier;
    }

    clone(): DeliveryPackage {
        return new DeliveryPackage(this);
    }
}
