import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SiteVisitSkipReasonsService} from 'app/blocks/service/api/site-visit-skip-reasons.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IBranch} from 'app/blocks/model/branch.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ISiteVisitSkipReasons, SiteVisitSkipReasons} from 'app/blocks/model/site-visit-skip-reasons.model';

@Injectable({
    providedIn: 'root'
})
export class SiteVisitSkipReasonsResource extends AbstractEntityResource<ISiteVisitSkipReasons> {
    constructor(siteVisitSkipReasonsService: SiteVisitSkipReasonsService, router: Router) {
        super(
            Resource.SITE_VISIT_SKIP_REASONS,
            siteVisitSkipReasonsService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SITE_VISIT_SKIP_REASONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SITE_VISIT_SKIP_REASONS),
            (entity?) => new SiteVisitSkipReasons(entity),
            (entity) => entity.code,
            'share'
        );
    }
}
