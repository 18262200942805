import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IBranch} from 'app/blocks/model/branch.model';
import {IEmployee} from 'app/blocks/model/employee.model';

export interface IBranchTransaction extends IBaseFranchiseEntity {
    id?: number;
    reference?: string;
    startingCash?: number;
    driverCashCollected?: number;
    backOfficeCashCollected?: number;
    cashDeposited?: number;
    endingCash?: number;
    reportDate?: string;
    reportTime?: any;
    utcTime?: number;
    branch?: IBranch;
    paymentType?: string;
    triggeredBy?: IEmployee;
    branchTransactionType?: string;
}

export class BranchTransaction extends BaseFranchiseEntity implements IBranchTransaction {
    public id: number;
    public reference: string;
    public startingCash: number;
    public driverCashCollected: number;
    public backOfficeCashCollected: number;
    public cashDeposited: number;
    public endingCash: number;
    public reportDate: string;
    public reportTime: any;
    public utcTime: number;
    public branch: IBranch;
    public paymentType: string;
    public triggeredBy: IEmployee;
    public branchTransactionType: string;

    constructor(branchTransaction?) {
        super();
        if (branchTransaction) {
            this.id = branchTransaction.id;
            this.reference = branchTransaction.reference;
            this.startingCash = branchTransaction.startingCash;
            this.driverCashCollected = branchTransaction.driverCashCollected;
            this.backOfficeCashCollected = branchTransaction.backOfficeCashCollected;
            this.cashDeposited = branchTransaction.cashDeposited;
            this.endingCash = branchTransaction.endingCash;
            this.reportDate = branchTransaction.reportDate;
            this.reportTime = branchTransaction.reportTime;
            this.utcTime = branchTransaction.utcTime;
            this.branch = branchTransaction.branch;
            this.paymentType = branchTransaction.paymentType;
            this.triggeredBy = branchTransaction.triggeredBy;
            this.branchTransactionType = branchTransaction.branchTransactionType;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): BranchTransaction {
        return new BranchTransaction(this);
    }
}
