import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBudget, Budget} from 'app/blocks/model/budget.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class BudgetService extends EntityCrudService<IBudget> {
    constructor(http: HttpClient) {
        super(http, Resource.BUDGETS, (obj?) => new Budget(obj));
    }
}
