import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {BranchPayment, IBranchPayment} from 'app/blocks/model/branch-payment.model';
import {BranchPaymentService} from 'app/blocks/service/api/branch-payment.service';

@Injectable({
    providedIn: 'root'
})
export class BranchPaymentResource extends AbstractEntityResource<IBranchPayment> {
    constructor(branchService: BranchPaymentService, router: Router) {
        super(
            Resource.BRANCH_PAYMENTS,
            branchService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.BRANCH_PAYMENTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.BRANCH_PAYMENTS),
            (entity?) => new BranchPayment(entity),
            (entity) => entity.id,
            'share'
        );
    }
}
