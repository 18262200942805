import {Resource} from 'app/constants/resource';
import {Organisation} from 'app/blocks/model/organisation.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IOrganisation} from 'app/blocks/model/organisation.model';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends EntityCrudService<IOrganisation> {
    constructor(http: HttpClient) {
        super(http, Resource.ORGANISATIONS, (obj?) => new Organisation(obj));
    }
}
