import {IncomingInventory} from 'app/blocks/model/inventory-movement.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IIncomingInventory} from 'app/blocks/model/inventory-movement.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class IncomingInventoryService extends EntityCrudService<IIncomingInventory> {
    constructor(http: HttpClient) {
        super(http, Resource.INCOMING_INVENTORIES, (obj?) => new IncomingInventory(obj));
    }

    getCompanySettings = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/all-company-settings').toPromise();
    };

    receiveInventory = (id, adjustmentDate, adjustmentTime): Promise<HttpResponse<any>> => {
        const payload = {
            adjustmentDate: adjustmentDate,
            adjustmentTime: adjustmentTime
        };
        return this.http
            .post<any>(SERVER_API_URL + 'api/incoming-inventories/' + id + '/receive-inventory', payload, {observe: 'response'})
            .toPromise();
    };

    uploadFile = (label: string, file: File, incomingInventoryId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('label', label);
        return this.http.post<any>(`${SERVER_API_URL}api/incoming-inventory/${incomingInventoryId}/docs`, fd).toPromise();
    };
    downloadMergedIncomingInventories = (ids: number[]): Promise<any> => {
        const requestParams = new HttpParams().set('ids', ids.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/incoming-inventories/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
    downloadFile = (inventoryId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/incoming-inventory/${inventoryId}/docs`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    downloadPDF = (inventoryId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/incoming-inventories/${inventoryId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    getIncomingInventories = (params: any): Promise<any> => {
        const requestParams = createRequestOption(params);
        const REQUEST_URI = `${SERVER_API_URL}api/incoming-inventories`;
        return this.http.get(REQUEST_URI, {params: requestParams}).toPromise();
    };

    getInventoryMovementWithId(inventoryMovementId: number): Promise<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/incoming-inventories/${inventoryMovementId}`;
        return this.http.get(REQUEST_URI).toPromise();
    }

    getInventoryMovementsForPurchaseInvoice(inventoryMovementIds: number[]): Promise<any[]> {
        const REQUEST_URI = `${SERVER_API_URL}api/incoming-inventories-for-purchase-invoices`;
        const params = new HttpParams().set('ids', inventoryMovementIds.join(','));
        return this.http.get<any[]>(REQUEST_URI, {params}).toPromise();
    }

    createWMSCustomerAssignedProducts(customerId: number, products: any[]): Promise<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/products/wms/customer/${customerId}`;
        return this.http.post(REQUEST_URI, products).toPromise();
    }
}
