import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IIntegrationMapping extends IBaseEntity {
    id?: number;
    system?: string;
    function?: string;
    fromValue?: string;
    toValue?: string;
}

export class IntegrationMapping extends BaseEntity implements IIntegrationMapping {
    public id?: number;
    public system?: string;
    public function?: string;
    public fromValue?: string;
    public toValue?: string;

    constructor(integrationMapping?: any) {
        super();
        if (integrationMapping) {
            this.id = integrationMapping.id;
            this.system = integrationMapping.system;
            this.function = integrationMapping.function;
            this.fromValue = integrationMapping.fromValue;
            this.toValue = integrationMapping.toValue;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): IntegrationMapping {
        return new IntegrationMapping(this);
    }
}
