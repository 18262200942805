import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBin, Bin} from 'app/blocks/model/bin.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class BinService extends EntityCrudService<IBin> {
    constructor(http: HttpClient) {
        super(http, Resource.BINS, (obj?) => new Bin(obj));
    }

    getBinsForCustomer = (customerId): Promise<HttpResponse<any>> => {
        const url = SERVER_API_URL + 'api/bins/customer/' + customerId;
        return this.http
            .get<any>(url, {
                observe: 'response'
            })
            .toPromise();
    };

    getWarehouseBinLocationsForBin = (binId): Promise<HttpResponse<any>> => {
        const url = SERVER_API_URL + 'api/warehouse-bin-locations/bin/' + binId;
        return this.http
            .get<any>(url, {
                observe: 'response'
            })
            .toPromise();
    };

    getWarehouseBinLocationsForProduct = (productId): Promise<HttpResponse<any>> => {
        const url = SERVER_API_URL + 'api/warehouse-bin-locations/product/' + productId;
        return this.http
            .get<any>(url, {
                observe: 'response'
            })
            .toPromise();
    };

    public allocateCustomerToSelectedBins = (binIds: number[], customerId: number): Promise<any> => {
        const requestBody = {binIds: binIds, customerId: customerId};
        return this.http
            .post<any>(SERVER_API_URL + 'api/bins/bulk-allocate-customers', requestBody, {
                observe: 'response'
            })
            .toPromise();
    };

    public bulkUnAllocateCustomersForBins = (binIds: number[]): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/bins/bulk-unallocate-customers', binIds, {
                observe: 'response'
            })
            .toPromise();
    };

    public bulkCreateBins = (
        warehouseId: number,
        binCodePrefix: string,
        numberOfBins: number,
        binTypeId: number,
        quantityCapacity: number
    ): Promise<any> => {
        const requestBody = {
            warehouse: warehouseId,
            binCodePrefix: binCodePrefix,
            numberOfBins: numberOfBins,
            binType: binTypeId ?? null,
            quantityCapacity: quantityCapacity
        };
        return this.http
            .post<any>(SERVER_API_URL + 'api/bins/bulk-create-bins', requestBody, {
                observe: 'response'
            })
            .toPromise();
    };

    getWarehouseBins = (warehouseId): Promise<HttpResponse<any>> => {
        const url = SERVER_API_URL + 'api/bins/warehouse/' + warehouseId;
        return this.http
            .get<any>(url, {
                observe: 'response'
            })
            .toPromise();
    };
}
