import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import {Resource} from 'app/constants/resource';
import {IShortfallReport, ShortfallReport} from 'app/blocks/model/shortfall-report.model';
import {IProductCostChange, ProductCostChange} from 'app/blocks/model/product-cost-change.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ProductCostChangeService extends EntityCrudService<IProductCostChange> {
    constructor(http: HttpClient) {
        super(http, Resource.PRODUCT_COST_CHANGES, (obj?) => new ProductCostChange(obj));
    }

    public getReport = (params: any): Promise<HttpResponse<any>> => {
        const requestParams = createRequestOption(params);
        const REQUEST_URI = `${SERVER_API_URL}api/product-cost-changes`;
        return this.http.get(REQUEST_URI, {params: requestParams, observe: 'response'}).toPromise();
    };
}
