import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IImage} from 'app/blocks/model/image.model';

export interface ICataloguePresentation extends IBaseEntity {
    id?: number;
    name?: string;
    slides?: IImage[];
}

export class CataloguePresentation extends BaseEntity implements ICataloguePresentation {
    public id?: number;
    public name?: string;
    public slides?: IImage[];

    constructor(presentation?: any) {
        super();
        if (presentation) {
            this.id = presentation.id;
            this.name = presentation.name;
            this.slides = presentation.slides ?? [];
        }
    }

    clone(): IBaseEntity {
        return new CataloguePresentation(this);
    }
}
