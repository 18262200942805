import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IVehicleType} from 'app/blocks/model/vehicle-type.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';
import {IWorkshop} from 'app/blocks/model/workshop.model';
import {IVehicleSkill} from 'app/blocks/model/vehicle-skill.model';
import {DiTime} from 'app/blocks/util/di-time';

export interface IVehicle extends IBaseEntity {
    id?: number;
    vehicleId?: string;
    description?: string;
    make?: string;
    model?: string;
    year?: number;
    vin?: string;
    licencePlate?: string;
    mobileWarehouse?: IWarehouse;
    loadingWarehouse?: IWarehouse;
    defaultDriver?: IEmployee;
    vehicleType?: IVehicleType;
    nextMotTest?: any;
    workshop?: IWorkshop;
    active?: boolean;
    skills?: IVehicleSkill[];
    maxDistance?: number;
    maxDrivingTime?: number;
    minJobs?: number;
    maxJobs?: number;
    moveToEndAddress?: boolean;
    breakStartTime?: any;
    breakEndTime?: any;
}
export class Vehicle extends BaseEntity implements IVehicle {
    public id?: number;
    public vehicleId?: string;
    public description?: string;
    public make?: string;
    public model?: string;
    public year?: number;
    public vin?: string;
    public licencePlate?: string;
    public mobileWarehouse?: IWarehouse;
    public loadingWarehouse?: IWarehouse;
    public defaultDriver?: IEmployee;
    public vehicleType?: IVehicleType;
    public nextMotTest?: any;
    public workshop?: IWorkshop;
    public active?: boolean;
    public skills?: IVehicleSkill[];
    public maxDistance?: number;
    public maxDrivingTime?: number;
    public minJobs?: number;
    public maxJobs?: number;
    public moveToEndAddress?: boolean;
    public breakStartTime?: any;
    public breakEndTime?: any;

    constructor(vehicle?) {
        super();
        if (vehicle) {
            this.id = vehicle.id;
            this.vehicleId = vehicle.vehicleId;
            this.description = vehicle.description;
            this.make = vehicle.make;
            this.model = vehicle.model;
            this.year = vehicle.year;
            this.vin = vehicle.vin;
            this.licencePlate = vehicle.licencePlate;
            this.mobileWarehouse = vehicle.mobileWarehouse;
            this.loadingWarehouse = vehicle.loadingWarehouse;
            this.defaultDriver = vehicle.defaultDriver;
            this.vehicleType = vehicle.vehicleType;
            this.nextMotTest = MposDate.newInstance(vehicle.nextMotTest);
            this.workshop = vehicle.workshop;
            this.active = vehicle.active;
            this.skills = vehicle.skills;
            this.maxDistance = vehicle.maxDistance;
            this.maxDrivingTime = vehicle.maxDrivingTime;
            this.minJobs = vehicle.minJobs;
            this.maxJobs = vehicle.maxJobs;
            this.moveToEndAddress = vehicle.moveToEndAddress;
            this.breakStartTime = DiTime.newInstance(vehicle.breakStartTime);
            this.breakEndTime = DiTime.newInstance(vehicle.breakEndTime);
        }
    }
    get discriminator(): any {
        return this.vehicleId;
    }
    clone(): Vehicle {
        return new Vehicle(this);
    }
}
