import {SaleOrderType} from 'app/blocks/model/sale-order-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ISaleOrderType} from 'app/blocks/model/sale-order-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class SaleOrderTypeService extends EntityCrudService<ISaleOrderType> {
    constructor(http: HttpClient) {
        super(http, Resource.SALE_ORDER_TYPES, (obj?) => new SaleOrderType(obj));
    }
}
