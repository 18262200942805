import {IEmployee} from 'app/blocks/model/employee.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {ISupplier} from 'app/blocks/model/supplier.model';
import {DiTime} from 'app/blocks/util/di-time';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IPurchaseInvoice} from 'app/blocks/model/purchase-invoice.model';
import {IPurchaseCreditNote} from 'app/blocks/model/purchase-credit-note.model';

export interface IPurchasePayment extends IBaseEntity {
    id?: number;
    collectedBy?: IEmployee;
    paymentDate?: any;
    paymentTime?: any;
    chequeDate?: any;
    cashAmount?: number;
    cashReference?: string;
    chequeAmount?: number;
    chequeRef?: string;
    ccAmount?: number;
    ccRef?: string;
    bankPaymentAmount?: number;
    bankPaymentRef?: string;
    totalAmount?: number;
    supplier?: ISupplier;
    chequeNo?: string;
    paidBy?: string;
    reference?: string;
    paymentStatus?: string;
    allocatedAmount?: number;
    exported?: boolean;
    pgRef?: string;
    pgAmount?: number;
    unappliedAmount?: number;
    purchasePaymentAllocationStatus?: string;
    sign?: any;
    directDebitRef?: string;
    directDebitAmount?: number;
    purchaseInvoicesPaid?: IPurchaseInvoice[];
    purchaseCreditNotesApplied?: IPurchaseCreditNote[];
    purchasePaymentAllocation?: any[];
    approveOnCreateOrUpdate?: boolean;
}

export class PurchasePayment extends BaseEntity implements IPurchasePayment {
    public id?: number;
    public collectedBy?: IEmployee;
    public paymentDate?: any;
    public chequeDate?: any;
    public paymentTime?: any;
    public cashAmount?: number;
    public cashReference?: string;
    public chequeAmount?: number;
    public ccAmount?: number;
    public ccRef?: string;
    public totalAmount?: number;
    public supplier?: ISupplier;
    public chequeNo?: string;
    public paymentStatus?: string;
    public paidBy?: string;
    public reference?: string;
    public allocatedAmount?: number;
    public exported?: boolean;
    public pgRef?: string;
    public pgAmount?: number;
    public unappliedAmount?: number;
    public bankPaymentAmount?: number;
    public bankPaymentRef?: string;
    public purchasePaymentAllocationStatus?: string;
    public sign?: any;
    public directDebitRef?: string;
    public directDebitAmount?: number;
    public purchaseInvoicesPaid?: IPurchaseInvoice[];
    public purchaseCreditNotesApplied?: IPurchaseCreditNote[];
    public purchasePaymentAllocation?: any[];
    public approveOnCreateOrUpdate?: boolean;

    constructor(purchasePayment?) {
        super();
        if (purchasePayment) {
            this.id = purchasePayment.id;
            this.collectedBy = purchasePayment.collectedBy;
            this.paymentDate = purchasePayment.paymentDate;
            this.chequeDate = purchasePayment.chequeDate;
            this.chequeAmount = purchasePayment.chequeAmount;
            this.paymentTime = purchasePayment.paymentTime;
            this.cashAmount = purchasePayment.cashAmount;
            this.cashReference = purchasePayment.cashReference;
            this.chequeAmount = purchasePayment.chequeAmount;
            this.ccAmount = purchasePayment.ccAmount;
            this.ccRef = purchasePayment.ccRef;
            this.totalAmount = purchasePayment.totalAmount;
            this.supplier = purchasePayment.supplier;
            this.chequeNo = purchasePayment.chequeNo;
            this.paymentStatus = purchasePayment.paymentStatus;
            this.paidBy = purchasePayment.paidBy;
            this.reference = purchasePayment.reference;
            this.allocatedAmount = purchasePayment.allocatedAmount || 0;
            this.exported = purchasePayment.exported;
            this.pgRef = purchasePayment.pgRef;
            this.pgAmount = purchasePayment.pgAmount;
            this.unappliedAmount = purchasePayment.unappliedAmount;
            this.bankPaymentAmount = purchasePayment.bankPaymentAmount;
            this.bankPaymentRef = purchasePayment.bankPaymentRef;
            this.purchasePaymentAllocationStatus = purchasePayment.purchasePaymentAllocationStatus;
            this.sign = purchasePayment.sign;
            this.directDebitRef = purchasePayment.directDebitRef;
            this.directDebitAmount = purchasePayment.directDebitAmount;
            this.purchaseInvoicesPaid = purchasePayment.purchaseInvoicesPaid || [];
            this.purchaseCreditNotesApplied = purchasePayment.purchaseCreditNotesApplied || [];
            this.purchasePaymentAllocation = purchasePayment.purchasePaymentAllocations || [];
            this.approveOnCreateOrUpdate = purchasePayment.approveOnCreateOrUpdate;
        } else {
            this.paymentDate = MposDate.currentDate();
            this.paymentTime = DiTime.currentTime();
            this.cashAmount = 0;
            this.chequeAmount = 0;
            this.bankPaymentAmount = 0;
            this.totalAmount = 0;
            this.unappliedAmount = 0;
            this.ccAmount = 0;
            this.allocatedAmount = 0;
            this.purchaseInvoicesPaid = [];
            this.purchaseCreditNotesApplied = [];
            this.purchasePaymentAllocation = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): PurchasePayment {
        return new PurchasePayment(this);
    }
}

export interface IApplyPurchaseInvoicePaymentDTO {
    purchaseInvoice: IPurchaseInvoice;
    amount: number;
}

export class ApplyPurchaseInvoicePaymentDTO implements IApplyPurchaseInvoicePaymentDTO {
    public purchaseInvoice: IPurchaseInvoice;
    public amount: number;

    constructor(purchaseInvoice?: IPurchaseInvoice) {
        if (purchaseInvoice) {
            this.purchaseInvoice = purchaseInvoice;
        }
        this.amount = 0;
    }
}

export interface IApplyPurchaseCreditNotePaymentDTO {
    purchaseCreditNote: IPurchaseCreditNote;
    amount: number;
}

export class ApplyPurchaseCreditNotePaymentDTO implements IApplyPurchaseCreditNotePaymentDTO {
    public purchaseCreditNote: IPurchaseCreditNote;
    public amount: number;

    constructor(purchaseCreditNote?: IPurchaseCreditNote) {
        if (purchaseCreditNote) {
            this.purchaseCreditNote = purchaseCreditNote;
        }
        this.amount = 0;
    }
}

export class SavePurchaseCreditNotePaymentDTO {
    public purchaseCreditNoteId: number;
    public amount: number;

    constructor(purchaseCreditNotePaymentDTO: IApplyPurchaseCreditNotePaymentDTO) {
        if (purchaseCreditNotePaymentDTO.purchaseCreditNote) {
            this.purchaseCreditNoteId = purchaseCreditNotePaymentDTO.purchaseCreditNote.id;
        }
        this.amount = purchaseCreditNotePaymentDTO.amount;
    }
}
