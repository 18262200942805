import {Branch} from 'app/blocks/model/branch.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IVehicleUnload, VehicleUnload} from 'app/blocks/model/vehicle-unload.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class VehicleUnloadService extends EntityCrudService<IVehicleUnload> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLE_UNLOADS, (obj?) => new VehicleUnload(obj));
    }

    public downloadPDF = (id: number): any => {
        const REQUEST_URI = SERVER_API_URL + `api/vehicle-unloads/${id}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };
}
