import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {IBrand, Brand} from 'app/blocks/model/brand.model';

@Injectable({
    providedIn: 'root'
})
export class BrandService extends EntityCrudService<IBrand> {
    constructor(http: HttpClient) {
        super(http, Resource.BRANDS, (obj?) => new Brand(obj));
    }

    uploadFile = (label: string, file: File, brandId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', label);
        return this.http.post<any>(`${SERVER_API_URL}api/brands/${brandId}/images`, fd).toPromise();
    };

    downloadFile = (brandId: number, imageId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/brands/${brandId}/images/${imageId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    deleteFile = (brandId: number, imageId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/brands/${brandId}/images/${imageId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    downloadImage(brandId: number, imageId: number): Observable<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/brands/${brandId}/images/${imageId}`;
        return this.http.get(REQUEST_URI, {responseType: 'blob'});
    }
}
