import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {ICreditClass} from 'app/blocks/model/credit-class.model';

export interface IPaymentType extends IBaseEntity {
    id?: number;
    code?: string;
    description?: string;
    creditClass?: ICreditClass;
}

export class PaymentType extends BaseFranchiseEntity implements IPaymentType {
    public id?: number;
    public code?: string;
    public description?: string;
    public creditClass?: ICreditClass;

    constructor(paymentType?) {
        super();
        if (paymentType) {
            this.id = paymentType.id;
            this.code = paymentType.code;
            this.description = paymentType.description;
            this.creditClass = paymentType.creditClass;
            this.editable = paymentType.editable;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): PaymentType {
        return new PaymentType(this);
    }
}
