import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OrderStatusReasonService} from 'app/blocks/service/api/order-status-reason.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IOrderStatusReason} from 'app/blocks/model/order-status-reason.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {OrderStatusReason} from 'app/blocks/model/order-status-reason.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class OrderStatusReasonResource extends AbstractEntityResource<IOrderStatusReason> {
    constructor(orderStatusReasonService: OrderStatusReasonService, router: Router) {
        super(
            Resource.ORDER_STATUS_REASONS,
            orderStatusReasonService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ORDER_STATUS_REASONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ORDER_STATUS_REASONS),
            (entity?) => new OrderStatusReason(entity),
            (entity) => entity.code
        );
    }
}
