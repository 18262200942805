import {IBaseFranchiseEntity, BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IDeliveryPackageType extends IBaseFranchiseEntity {
    id?: number;
    code?: string;
    description?: string;
}

export class DeliveryPackageType extends BaseFranchiseEntity implements IDeliveryPackageType {
    public id?: number;
    public code?: string;
    public description?: string;

    constructor(deliveryPackageType?) {
        super();
        if (deliveryPackageType) {
            this.id = deliveryPackageType.id;
            this.code = deliveryPackageType.code;
            this.description = deliveryPackageType.description;
            this.editable = deliveryPackageType.editable;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DeliveryPackageType {
        return new DeliveryPackageType(this);
    }
}
