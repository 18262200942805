import {CGSTCategory, ICGSTCategory} from 'app/blocks/model/cgst-category.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IGSTCategory, IIGSTCategory} from 'app/blocks/model/igst-category.model';

@Injectable({
    providedIn: 'root'
})
export class IgstCategoryService extends EntityCrudService<IIGSTCategory> {
    constructor(http: HttpClient) {
        super(http, Resource.IN_IGST_RATES, (obj?) => new IGSTCategory(obj));
    }
}
