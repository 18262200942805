import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
export interface IRouteType extends IBaseEntity {
    id?: number;
    type?: string;
    description?: string;
    color?: string;
    multiDayRoute?: boolean;
}
export class RouteType extends BaseEntity implements IRouteType {
    public id?: number;
    public type?: string;
    public description?: string;
    public color?: string;
    public multiDayRoute?: boolean;
    constructor(routeType?) {
        super();
        if (routeType) {
            this.id = routeType.id;
            this.type = routeType.type;
            this.description = routeType.description;
            this.color = routeType.color;
            this.multiDayRoute = routeType.multiDayRoute;
        }
    }
    get discriminator(): any {
        return this.type;
    }
    clone(): RouteType {
        return new RouteType(this);
    }
}
