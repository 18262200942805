import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPurchasePayment, PurchasePayment, IApplyPurchaseInvoicePaymentDTO} from 'app/blocks/model/purchase-payment.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PurchasePaymentService extends EntityCrudService<IPurchasePayment> {
    constructor(http: HttpClient) {
        super(http, Resource.PURCHASE_PAYMENTS, (obj?) => new PurchasePayment(obj));
    }

    public bulkUpdatePurchasePaymentStatus = (ids: string, status: string): any => {
        return this.http
            .put<any>(SERVER_API_URL + `api/purchase-payments/mark-as-${status}`, ids, {
                observe: 'response'
            })
            .toPromise();
    };

    public getPurchasePayment = (purchasePaymentId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-payments/${purchasePaymentId}`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    cancelPurchasePayment = (purchasePaymentId: number): Promise<any> => {
        return this.http.put<any>(SERVER_API_URL + 'api/purchase-payments/' + purchasePaymentId + '/cancel', null, {observe: 'response'}).toPromise();
    };

    togglePaymentExportedStatus(ids: number[]) {
        const requestParams = new HttpParams().set('ids', ids.join(','));
        const responseObservable$ = this.http.post<any>(SERVER_API_URL + 'api/purchase-payments/toggle-export-status', null, {
            observe: 'response',
            params: requestParams
        });
        return lastValueFrom(responseObservable$);
    }

    applyPaymentToInvoices = (purchasePaymentId: string, purchaseInvoices: IApplyPurchaseInvoicePaymentDTO[]): any => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/purchase-payments/${purchasePaymentId}/apply-purchase-invoices`, purchaseInvoices, {observe: 'response'})
            .toPromise();
    };

    applyPurchasePaymentToCreditNotes = (purchasePaymentId: string, purchaseCreditNotes: any[]): any => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/purchase-payments/${purchasePaymentId}/apply-purchase-credit-notes`, purchaseCreditNotes, {
                observe: 'response'
            })
            .toPromise();
    };

    unAllocatePurchaseInvoicePayment = (purchasePaymentId: any, purchaseInvoiceId: any): Promise<any> => {
        const requestParams = new HttpParams().set('purchasePaymentId', purchasePaymentId).set('purchaseInvoiceId', purchaseInvoiceId);
        return this.http
            .post<any>(SERVER_API_URL + 'api/purchase-payments/un-allocate-purchase-invoice', null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    public getPayment = (purchasePaymentId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-payments/${purchasePaymentId}`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public unAllocatePurchaseCreditNotePayment = (purchasePaymentId: any, purchaseCreditNoteId: any): Promise<any> => {
        const requestParams = new HttpParams().set('purchasePaymentId', purchasePaymentId).set('purchaseCreditNoteId', purchaseCreditNoteId);
        return this.http
            .post<any>(SERVER_API_URL + 'api/purchase-payments/un-allocate-purchase-credit-note', null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };
}
