import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface ICustomerStatus extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;
}

export class CustomerStatus extends BaseEntity implements ICustomerStatus {
    public id?: number;

    public code?: string;

    public description?: string;

    constructor(customerStatus?) {
        super();
        if (customerStatus) {
            this.id = customerStatus.id;

            this.code = customerStatus.code;

            this.description = customerStatus.description;
        } else {
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): CustomerStatus {
        return new CustomerStatus(this);
    }
}
