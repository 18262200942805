import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ISupplierPaymentTerm, SupplierPaymentTerm} from 'app/blocks/model/supplier-payment-term.model';

@Injectable({
    providedIn: 'root'
})
export class SupplierPaymentTermService extends EntityCrudService<ISupplierPaymentTerm> {
    constructor(http: HttpClient) {
        super(http, Resource.SUPPLIER_PAYMENT_TERMS, (obj?) => new SupplierPaymentTerm(obj));
    }
}
