import {CreditTerm} from 'app/blocks/model/credit-term.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICreditTerm} from 'app/blocks/model/credit-term.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class CreditTermService extends EntityCrudService<ICreditTerm> {
    constructor(http: HttpClient) {
        super(http, Resource.CREDIT_TERMS, (obj?) => new CreditTerm(obj));
    }
}
