import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IBillingFrequencyType extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;
}

export class BillingFrequencyType extends BaseEntity implements IBillingFrequencyType {
    public id?: number;

    public code?: string;

    public description?: string;

    constructor(billingFrequencyType?) {
        super();
        if (billingFrequencyType) {
            this.id = billingFrequencyType.id;

            this.code = billingFrequencyType.code;

            this.description = billingFrequencyType.description;
        } else {
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): BillingFrequencyType {
        return new BillingFrequencyType(this);
    }
}
