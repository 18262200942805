import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IProductAttributeType, ProductAttributeType} from 'app/blocks/model/product-attribute-type.model';

@Injectable({
    providedIn: 'root'
})
export class ProductAttributeTypeService extends EntityCrudService<IProductAttributeType> {
    constructor(http: HttpClient) {
        super(http, Resource.PRODUCT_ATTRIBUTE_TYPES, (obj?) => new ProductAttributeType(obj));
    }
}
