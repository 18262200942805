import {IInventoryProductEntry, InventoryProductEntry} from 'app/blocks/model/inventory-product-entry.model';
import {IWarehouse, Warehouse} from 'app/blocks/model/warehouse.model';
import {IEmployee, Employee} from 'app/blocks/model/employee.model';
import {ISupplier, Supplier} from 'app/blocks/model/supplier.model';
import {IInventoryReason, InventoryReason} from 'app/blocks/model/inventory-reason.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {DiTime} from 'app/blocks/util/di-time';
import {PurchaseOrder} from 'app/blocks/model/purchase-order.model';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line';
import {SaleOrder} from 'app/blocks/model/sale-order.model';
export interface IInventoryMovement extends IBaseEntity {
    id?: number;
    documentRef?: string;
    items?: IInventoryProductEntry[];
    verifier?: IEmployee;
    reason?: IInventoryReason;
    destWarehouse?: IWarehouse;
    supplier?: ISupplier;
    incomingInventoryStatus?: string;
    customer?: any;
}
export class InventoryMovement extends BaseEntity implements IInventoryMovement {
    public id?: number;
    public documentRef?: string;
    public items?: InventoryProductEntry[];
    public verifier?: Employee;
    public reason?: InventoryReason;
    public destWarehouse?: Warehouse;
    public purchaseInvoiceNo?: string;
    public purchaseInvoiceValue?: number;
    public purchaseInvoiceDate?: string;
    public supplier?: ISupplier;
    public incomingInventoryStatus?: string;
    public isPurchaseInvoiceAvailable?: boolean;
    public total?: any;
    public subTotal?: any;
    public discount?: any;
    public tax?: any;
    public note?: string;
    public underDispute?: boolean;
    public underDisputeNote?: string;
    public adHocLineItems?: IAdHocLine[];
    public storageOrder?: SaleOrder;
    public customer?: any;

    constructor(inventoryMovement?) {
        super();
        if (inventoryMovement) {
            this.id = inventoryMovement.id;
            this.documentRef = inventoryMovement.documentRef;
            this.verifier = inventoryMovement.verifier;
            this.reason = inventoryMovement.reason;
            this.items = inventoryMovement.items || [];
            this.destWarehouse = inventoryMovement.destWarehouse;
            this.supplier = inventoryMovement.supplier;
            this.incomingInventoryStatus = inventoryMovement.incomingInventoryStatus;
            this.note = inventoryMovement.note;
            this.adHocLineItems = inventoryMovement.adHocLineItems || [];
            this.storageOrder = inventoryMovement.storageOrder;
            this.customer = inventoryMovement.customer;
        } else {
            this.items = [];
            this.adHocLineItems = [];
        }
    }
    get discriminator(): any {
        return this.documentRef;
    }
    clone(): InventoryMovement {
        return new InventoryMovement(this);
    }
}

export interface IIncomingInventory extends IInventoryMovement {
    loadOrderNo?: string;
    incomingInventoryStatus?: string;
    purchaseOrder?: PurchaseOrder;
    incomingDate?: any;
    incomingTime?: any;
    purchaseInvoiceNo?: string;
    purchaseInvoiceValue?: number;
    purchaseInvoiceDate?: string;
    isPurchaseInvoiceAvailable?: boolean;
    total?: any;
    subTotal?: any;
    discount?: any;
    tax?: any;
    note?: string;
    underDispute?: boolean;
    underDisputeNote?: string;
    adHocLineItems?: IAdHocLine[];
    storageOrder?: SaleOrder;
    approveOnCreateOrUpdate?: boolean;
}
export class IncomingInventory extends InventoryMovement implements IIncomingInventory {
    // public loadOrderNo?: string;
    public incomingInventoryStatus?: string;
    public purchaseOrder?: PurchaseOrder;
    public incomingDate?: any;
    public incomingTime?: any;
    public approveOnCreateOrUpdate?: boolean;

    constructor(incomingInventory?) {
        super(incomingInventory);
        if (incomingInventory) {
            // this.loadOrderNo = incomingInventory.loadOrderNo;
            this.incomingInventoryStatus = incomingInventory.incomingInventoryStatus;
            this.purchaseOrder = incomingInventory.purchaseOrder;
            this.incomingDate = MposDate.newInstance(incomingInventory.incomingDate);
            this.incomingTime = DiTime.newInstance(incomingInventory.incomingTime);
            this.purchaseInvoiceNo = incomingInventory.purchaseInvoiceNo;
            this.purchaseInvoiceDate = incomingInventory.purchaseInvoiceDate;
            this.purchaseInvoiceValue = incomingInventory.purchaseInvoiceValue;
            this.isPurchaseInvoiceAvailable = incomingInventory.isPurchaseInvoiceAvailable;
            this.tax = incomingInventory.tax;
            this.total = incomingInventory.total;
            this.subTotal = incomingInventory.subTotal;
            this.discount = incomingInventory.discount;
            this.note = incomingInventory.note;
            this.underDispute = incomingInventory.underDispute;
            this.underDisputeNote = incomingInventory.underDisputeNote;
            this.adHocLineItems = incomingInventory.adHocLineItems || [];
            this.approveOnCreateOrUpdate = incomingInventory.approveOnCreateOrUpdate;
        } else {
            this.items = [];
            this.incomingDate = MposDate.currentDate();
            this.incomingTime = DiTime.currentTime();
            this.adHocLineItems = [];
        }
    }
}

export interface IWarehouseTransfer extends IInventoryMovement {
    sourceWarehouse?: IWarehouse;
    movementDate?: any;
    movementTime?: any;
}
export class WarehouseTransfer extends InventoryMovement implements IIncomingInventory {
    public sourceWarehouse?: Warehouse;
    public movementDate?: any;
    public movementTime?: any;

    constructor(warehouseTransfer?) {
        super(warehouseTransfer);
        if (warehouseTransfer) {
            this.sourceWarehouse = warehouseTransfer.sourceWarehouse;
            this.movementDate = MposDate.newInstance(warehouseTransfer.movementDate);
            this.movementTime = DiTime.newInstance(warehouseTransfer.movementTime);
        } else {
            this.items = [];
            this.movementDate = MposDate.currentDate();
            this.movementTime = DiTime.currentTime();
        }
    }
}
