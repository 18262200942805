import {IProductPackaging, ProductPackaging} from 'app/blocks/model/product-packaging.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
export interface IPackaging extends IBaseEntity {
    id?: number;
    name?: string;
    cost?: number;
    productPackaging?: IProductPackaging;
}
export class Packaging extends BaseFranchiseEntity implements IPackaging {
    public id?: number;
    public name?: string;
    public cost?: number;
    public productPackaging?: IProductPackaging;
    constructor(packaging?) {
        super();
        if (packaging) {
            this.id = packaging.id;
            this.name = packaging.name;
            this.cost = packaging.cost;
            this.productPackaging = packaging.productPackaging;
            this.editable = packaging.editable;
        }
    }
    get discriminator(): any {
        return this.name;
    }
    clone(): Packaging {
        return new Packaging(this);
    }
}
