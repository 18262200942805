import {CgstCategoryService} from 'app/blocks/service/api/cgst-category.service';
import {CGSTCategory} from 'app/blocks/model/cgst-category.model';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ICGSTCategory} from 'app/blocks/model/cgst-category.model';

@Injectable({
    providedIn: 'root'
})
export class CGSTCategoryResource extends AbstractEntityResource<ICGSTCategory> {
    constructor(cgstCategoryService: CgstCategoryService, router: Router) {
        super(
            Resource.IN_CGST_RATES,
            cgstCategoryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.IN_CGST_RATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.IN_CGST_RATES),
            (entity?) => new CGSTCategory(entity),
            (entity) => entity.label,
            'attach_money'
        );
    }
}
