import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {CatalogueEntity, ICatalogueEntity} from 'app/blocks/model/catalogue.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class CatalogueService extends EntityCrudService<ICatalogueEntity> {
    constructor(http: HttpClient) {
        super(http, Resource.CATALOGUES, (obj?) => new CatalogueEntity(obj));
    }

    uploadImage(label: string, file: File) {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', label);
        return this.http.post<any>(`${SERVER_API_URL}api/catalogues/upload-cover`, fd).toPromise();
    }
}
