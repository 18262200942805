//import { IOrderStatusReasonGroup, OrderStatusReasonGroup } from './order-status-reason-group.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IOrderStatusReason extends IBaseEntity {
    id?: number;

    code?: string;

    reason?: string;

    sequence?: number;

    isDefaultInGroup?: boolean;

    active?: boolean;
    //group?: IOrderStatusReasonGroup;
}

export class OrderStatusReason extends BaseEntity implements IOrderStatusReason {
    public id?: number;

    public code?: string;

    public reason?: string;

    public sequence?: number;

    public isDefaultInGroup?: boolean;

    public active?: boolean;

    //public group?: IOrderStatusReasonGroup;

    constructor(orderStatusReason?) {
        super();
        if (orderStatusReason) {
            this.id = orderStatusReason.id;

            this.code = orderStatusReason.code;

            this.reason = orderStatusReason.reason;

            this.sequence = orderStatusReason.sequence;

            this.isDefaultInGroup = orderStatusReason.isDefaultInGroup;

            this.active = orderStatusReason.active;

            //this.group = orderStatusReason.group;
        } else {
            //this.group = new OrderStatusReasonGroup();
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): OrderStatusReason {
        return new OrderStatusReason(this);
    }
}
