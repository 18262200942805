import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {CustomFieldGroup, ICustomFieldGroup} from 'app/blocks/model/custom-field-group.model';
import {CustomFieldGroupService} from 'app/blocks/service/api/custom-field-group.service';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldGroupResource extends AbstractEntityResource<ICustomFieldGroup> {
    constructor(customFieldGroupService: CustomFieldGroupService, router: Router) {
        super(
            Resource.CUSTOM_FIELD_GROUPS,
            customFieldGroupService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CUSTOM_FIELD_GROUPS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CUSTOM_FIELD_GROUPS),
            (entity?) => new CustomFieldGroup(entity),
            (entity) => entity.code,
            'priority_high'
        );
    }
}
