import {Packaging} from 'app/blocks/model/packaging.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPackaging} from 'app/blocks/model/packaging.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PackagingService extends EntityCrudService<IPackaging> {
    constructor(http: HttpClient) {
        super(http, Resource.PACKAGINGS, (obj?) => new Packaging(obj));
    }
}
