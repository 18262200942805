import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
export interface IEmployeeGroup extends IBaseFranchiseEntity {
    id?: number;
    name?: string;
    description?: string;
}

export class EmployeeGroup extends BaseFranchiseEntity implements IEmployeeGroup {
    public id?: number;
    public name?: string;
    public description?: string;

    constructor(employeeGroup?) {
        super();
        if (employeeGroup) {
            this.id = employeeGroup.id;
            this.name = employeeGroup.name;
            this.description = employeeGroup.description;
            this.editable = employeeGroup.editable;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): EmployeeGroup {
        return new EmployeeGroup(this);
    }
}
