import {DurationProductPriceInfo} from 'app/blocks/model/duration-product-price-info.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDurationProductPriceInfo} from 'app/blocks/model/duration-product-price-info.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DurationProductPriceInfoService extends EntityCrudService<IDurationProductPriceInfo> {
    constructor(http: HttpClient) {
        super(http, Resource.DURATION_PRODUCT_PRICE_INFOS, (obj?) => new DurationProductPriceInfo(obj));
    }
}
