import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {WarehouseTypeService} from 'app/blocks/service/api/warehouse-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IWarehouseType} from 'app/blocks/model/warehouse-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {WarehouseType} from 'app/blocks/model/warehouse-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class WarehouseTypeResource extends AbstractEntityResource<IWarehouseType> {
    constructor(warehouseTypeService: WarehouseTypeService, router: Router) {
        super(
            Resource.WAREHOUSE_TYPES,
            warehouseTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.WAREHOUSE_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.WAREHOUSE_TYPES),
            (entity?) => new WarehouseType(entity),
            (entity) => entity.code
        );
    }
}
