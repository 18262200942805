import {IDeliveryItem} from 'app/blocks/model/delivery-item.model';
import {IDeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IRoute} from 'app/blocks/model/route.model';
import {DiTime} from 'app/blocks/util/di-time';
import {INote, Note} from 'app/blocks/model/note.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {LoadOrderStatus} from 'app/blocks/model/load-order-status';
import {IEmployee} from 'app/blocks/model/employee.model';

export interface ILoadOrder extends IBaseFranchiseEntity {
    id?: number;
    loadOrderNo?: string;
    loadOrderDate?: any;
    loadRefId?: string;
    loaded?: boolean;
    type?: any;
    route?: IRoute;
    processed?: boolean;
    items?: IDeliveryItem[];
    packages?: IDeliveryPackage[];
    processDate?: any;
    processTime?: any;
    note?: INote;
    exported?: boolean;
    processOnCreation?: boolean;
    loadOrderStatus?: LoadOrderStatus;
    loadedBy?: IEmployee;
    approveOnCreateOrUpdate?: boolean;
}

export class LoadOrder extends BaseFranchiseEntity implements ILoadOrder {
    public id?: number;
    public loadOrderNo?: string;
    public loadOrderDate?: any;
    public loadRefId?: string;
    public loaded?: boolean;
    public type?: any;
    public route?: IRoute;
    public items?: IDeliveryItem[];
    public packages?: IDeliveryPackage[];
    public processed?: boolean;
    public processDate?: any;
    public processTime?: any;
    public notes?: INote;
    public exported?: boolean;
    public processOnCreation?: boolean;
    public loadOrderStatus?: LoadOrderStatus;
    public loadedBy?: IEmployee;
    public approveOnCreateOrUpdate?: boolean;

    constructor(loadOrder?) {
        super();
        if (loadOrder) {
            this.id = loadOrder.id;
            this.loadOrderNo = loadOrder.loadOrderNo;
            this.loadOrderDate = MposDate.newInstance(loadOrder.loadOrderDate);
            this.loadRefId = loadOrder.loadRefId;
            this.loaded = loadOrder.loaded || false;
            this.type = loadOrder.type;
            this.route = loadOrder.route;
            this.items = loadOrder.items || [];
            this.packages = loadOrder.packages || [];
            this.processed = loadOrder.processed || false;
            this.processDate = loadOrder.processDate;
            this.processTime = DiTime.newInstance(loadOrder.processTime);
            this.notes = new Note(loadOrder.notes);
            this.exported = loadOrder.exported;
            this.processOnCreation = loadOrder.processOnCreation;
            this.assignedFranchise = loadOrder.assignedFranchise;
            this.loadOrderStatus = loadOrder.loadOrderStatus;
            this.loadedBy = loadOrder.loadedBy;
            this.approveOnCreateOrUpdate = loadOrder.approveOnCreateOrUpdate;
        } else {
            this.items = [];
            this.packages = [];
            this.notes = new Note();
        }
    }

    get discriminator(): any {
        return this.loadOrderNo;
    }

    clone(): LoadOrder {
        return new LoadOrder(this);
    }
}

export enum LoadOrderType {
    MANUAL = 'MANUAL',
    STANDARD = 'STANDARD',
    BULK = 'BULK',
    PICK_LIST = 'PICK_LIST'
}
