import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {CatalogueResourceGroup, ICatalogueResourceGroup} from 'app/blocks/model/resource-group.model';

@Injectable({
    providedIn: 'root'
})
export class CatalogueResourceGroupService extends EntityCrudService<ICatalogueResourceGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.RESOURCE_GROUPS, (obj?) => new CatalogueResourceGroup(obj));
    }
}
