import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IIGSTCategory extends IBaseEntity {
    id?: number;
    label?: string;
    isDefaultInGroup?: boolean;
    taxPercentage: number;
}

export class IGSTCategory extends BaseEntity implements IIGSTCategory {
    public id?: number;
    public label?: string;
    public isDefaultInGroup?: boolean;
    public taxPercentage: number;

    constructor(igstCategory?) {
        super();
        if (igstCategory) {
            this.id = igstCategory.id;
            this.label = igstCategory.label;
            this.isDefaultInGroup = igstCategory.isDefaultInGroup;
            this.taxPercentage = igstCategory.taxPercentage;
        }
    }

    get discriminator(): any {
        return this.label;
    }

    clone(): IGSTCategory {
        return new IGSTCategory(this);
    }
}
