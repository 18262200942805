import {Company} from 'app/blocks/model/company.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICompany} from 'app/blocks/model/company.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {SwCacheService} from 'app/common/sw-cache/sw-cache.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends EntityCrudService<ICompany> {
    constructor(
        http: HttpClient,
        private _swCacheService: SwCacheService
    ) {
        super(http, Resource.COMPANIES, (obj?) => new Company(obj));
    }

    private _invalidateCache = async <T>(res: T) => {
        await this._swCacheService.resetSwCacheForGroup('company-api-cache');
        return res;
    };

    getCompany = (): Promise<any> => {
        return this.http
            .get<any>(SERVER_API_URL + 'api/company')
            .toPromise()
            .then((response) => this._entityCreator(response));
    };

    addCompany = (entity: ICompany): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/company', entity)
            .toPromise()
            .then(this._invalidateCache);
    };

    updateCompany = (entity: ICompany): Promise<any> => {
        return this.http
            .put<any>(SERVER_API_URL + 'api/company', entity)
            .toPromise()
            .then(this._invalidateCache);
    };

    getConfig = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/config').toPromise();
    };

    getSettingValue = (settingKey: string): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/settings/' + settingKey).toPromise();
    };

    getTenantMenu = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/tenant-menu').toPromise();
    };

    saveTenantMenu = (tenantMenus: any): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/tenant-menu', tenantMenus)
            .toPromise()
            .then(this._invalidateCache);
    };

    updateSettingValue = (settingKey: string, value: any): Promise<any> => {
        const body = {};
        body[settingKey] = value;
        return this.http
            .post(SERVER_API_URL + 'api/company/settings/' + settingKey, body)
            .toPromise()
            .then(this._invalidateCache);
    };

    uploadCustomerLogo = (selectedFile: any, width: number, height: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', selectedFile, selectedFile.name);
        fd.append('width', '' + width);
        fd.append('height', '' + height);
        return this.http
            .post<any>(SERVER_API_URL + `api/company/logo`, fd)
            .toPromise()
            .then(this._invalidateCache);
    };

    getLogo(): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + 'api/company/logo';
        return this.http.get(REQUEST_URI, {responseType: 'blob'}).toPromise();
    }

    public getTemplates = (templateType: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/templates/` + templateType;
        return this.http.get(REQUEST_URI).toPromise();
    };

    getPartnerCompanies(): Promise<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/company/partner-companies`;
        return this.http.get(REQUEST_URI).toPromise();
    }

    uploadLogoForPartnerCompany = (selectedFile: any, width: number, height: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', selectedFile, selectedFile.name);
        fd.append('width', '' + width);
        fd.append('height', '' + height);
        return this.http
            .post<any>(SERVER_API_URL + `api/company/partner-company-logo`, fd)
            .toPromise()
            .then(this._invalidateCache);
    };

    getPartnerCompanyLogo = (partnerCompanyId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/company/partner-company-logo/` + partnerCompanyId;
        return this.http.get(REQUEST_URI, {responseType: 'blob'}).toPromise();
    };

    uploadDocumentProofFile = (documents: {file: File; label: string; documentType: string}[], companyId: number): Promise<any> => {
        const fd = new FormData();
        documents.forEach((doc, index) => {
            if (doc != null) {
                if (doc.file instanceof File) {
                    fd.append(`files`, doc.file, doc.file.name);
                    fd.append(`labels`, doc.label);
                    fd.append(`documentTypes`, doc.documentType);
                } else {
                    console.error('Invalid file type:', doc.file);
                }
            } else {
                console.error('Document is null or undefined');
            }
        });
        return this.http.post<any>(`${SERVER_API_URL}api/company/${companyId}/upload-document-proof`, fd).toPromise().then(this._invalidateCache);
    };

    downloadAddressProofFile(companyId: number): Observable<Blob> {
        return this.http.get(`${SERVER_API_URL}api/company/${companyId}/download-address-proof`, {responseType: 'blob'});
    }

    getAddressProofDocumentDetails = (companyId: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/address-proof/' + companyId).toPromise();
    };

    downloadIdProofFile(companyId: number): Observable<Blob> {
        return this.http.get(`${SERVER_API_URL}api/company/${companyId}/download-id-proof`, {responseType: 'blob'});
    }

    getIdProofDocumentDetails = (companyId: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/id-proof/' + companyId).toPromise();
    };

    downloadPanProofFile(companyId: number): Observable<Blob> {
        return this.http.get(`${SERVER_API_URL}api/company/${companyId}/download-pan-proof`, {responseType: 'blob'});
    }

    getPanProofDocumentDetails = (companyId: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/pan-proof/' + companyId).toPromise();
    };

    downloadTaxRegistrationNoProofFile(companyId: number): Observable<Blob> {
        return this.http.get(`${SERVER_API_URL}api/company/${companyId}/download-tax-registration-no-proof`, {responseType: 'blob'});
    }

    getTaxRegistrationNoProofDocumentDetails = (companyId: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/tax-registration-no-proof/' + companyId).toPromise();
    };
}
