import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PackagingService} from 'app/blocks/service/api/packaging.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IPackaging} from 'app/blocks/model/packaging.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Packaging} from 'app/blocks/model/packaging.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class PackagingResource extends AbstractEntityResource<IPackaging> {
    constructor(packagingService: PackagingService, router: Router) {
        super(
            Resource.PACKAGINGS,
            packagingService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PACKAGINGS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PACKAGINGS),
            (entity?) => new Packaging(entity),
            (entity) => entity.name,
            'redeem'
        );
    }
}
