import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ISupplierPaymentTerm extends IBaseEntity {
    id?: number;
    termName?: string;
    noOfDays?: number;
}

export class SupplierPaymentTerm extends BaseEntity implements ISupplierPaymentTerm {
    public id?: number;
    public termName?: string;
    public noOfDays?: number;

    constructor(supplierPaymentTerm?) {
        super();
        if (supplierPaymentTerm) {
            this.id = supplierPaymentTerm.id;
            this.termName = supplierPaymentTerm.termName;
            this.noOfDays = supplierPaymentTerm.noOfDays;
        }
    }

    get discriminator(): any {
        return this.termName;
    }

    clone(): SupplierPaymentTerm {
        return new SupplierPaymentTerm(this);
    }
}
