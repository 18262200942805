import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ProductListService} from 'app/blocks/service/api/product-list.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IProductList} from 'app/blocks/model/product-list.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ProductList} from 'app/blocks/model/product-list.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ProductListResource extends AbstractEntityResource<IProductList> {
    constructor(productListService: ProductListService, router: Router) {
        super(
            Resource.PRODUCT_LISTS,
            productListService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_LISTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_LISTS),
            (entity?) => new ProductList(entity),
            (entity) => entity.name,
            'list_alt'
        );
    }
}
