import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {StatementTypeService} from 'app/blocks/service/api/statement-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IStatementType} from 'app/blocks/model/statement-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {StatementType} from 'app/blocks/model/statement-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class StatementTypeResource extends AbstractEntityResource<IStatementType> {
    constructor(statementTypeService: StatementTypeService, router: Router) {
        super(
            Resource.STATEMENT_TYPES,
            statementTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.STATEMENT_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.STATEMENT_TYPES),
            (entity?) => new StatementType(entity),
            (entity) => entity.code
        );
    }
}
