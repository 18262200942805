import {ICustomer} from 'app/blocks/model/customer.model';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';
import {MposDate} from 'app/blocks/util/mpos-date';

import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IDiscount} from 'app/blocks/model/discount.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IMajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {IStatementCode} from 'app/blocks/model/statement-code.model';
import {IInvoiceLine} from 'app/blocks/model/invoice-line.model';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line';
import {IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {IEquipment} from 'app/blocks/model/equipment.model';
import {ICreditNote} from 'app//blocks/model/credit-note.model';

export interface IInvoice extends IBaseFranchiseEntity {
    id?: number;
    invoiceNo?: string;
    reference?: string;
    invoiceStatus?: any;
    paymentStatus?: any;
    deleted?: boolean;
    carriedForward?: boolean;
    consolidated?: boolean;
    dueDate?: any;
    lastReminder?: any;
    paid?: number;
    balance?: number;
    carriedBalance?: number;
    consolidatedInvoice?: boolean;
    consolidatedInvoices?: IInvoice[];
    subTotal?: number;
    discountedSubTotal?: number;
    shippingCost?: number;
    tax?: number;
    discountedTax?: number;
    total?: number;
    notes?: string;
    customer?: ICustomer;
    deliveryPoint?: IDeliveryPoint;
    saleOrder?: ISaleOrder;
    invoiceLines?: IInvoiceLine[];
    adHocLines?: IAdHocLine[];
    creationDate?: any;
    updateDate?: any;
    statementCode?: IStatementCode;
    majorAccountCode?: IMajorAccountCode;
    createdBy?: IEmployee;
    discount?: IDiscount;
    exported?: boolean;
    consolidatedInto?: IInvoice;
    paymentAllocation?: any[];
    deliverySignedBy?: any;
    unpaidTotal?: number;
    creditNoteAllocation?: any[];
    purchaseOrderNumber?: string;
    assignedFranchise?: IFranchise;
    equipment?: IEquipment;
    serviceDescription?: string;
    adjustments?: any[];
    saleOrderStatus?: string;
    serviceEquipmentItems?: any[];
    saleOrderId?: any;
    routeSaleOrderId?: any;
    returnOrderId?: any;
}

export class Invoice extends BaseEntity implements IInvoice {
    public id?: number;
    public invoiceNo?: string;
    public reference?: string;
    public invoiceStatus?: string;
    public paymentStatus?: string;
    public deleted?: boolean;
    public carriedForward?: boolean;
    public consolidated?: boolean;
    public dueDate?: any;
    public lastReminder?: any;
    public paid?: number;
    public balance?: number;
    public carriedBalance?: number;
    public consolidatedInvoice?: boolean;
    public consolidatedInvoices?: IInvoice[];
    public subTotal?: number;
    public discountedSubTotal?: number;
    public shippingCost?: number;
    public tax?: number;
    public discountedTax?: number;
    public total?: number;
    public notes?: string;
    public customer?: ICustomer;
    public deliveryPoint?: IDeliveryPoint;
    public saleOrder?: ISaleOrder;
    public invoiceLines?: IInvoiceLine[];
    public adHocLines?: IAdHocLine[];
    public creationDate?: any;
    public updateDate?: any;
    public statementCode?: IStatementCode;
    public majorAccountCode?: IMajorAccountCode;
    public createdBy?: IEmployee;
    public discount?: IDiscount;
    public exported?: boolean;
    public consolidatedInto?: IInvoice;
    public paymentAllocation?: any[];
    public deliverySignedBy?: any;
    public unpaidTotal?: number;
    public creditNoteAllocation?: any[];
    public purchaseOrderNumber?: string;
    public assignedFranchise?: IFranchise;
    public equipment?: IEquipment;
    public serviceDescription?: string;
    public adjustments?: any[];
    public saleOrderStatus?: any;
    public serviceEquipmentItems?: any[];

    constructor(invoice?: any) {
        super();
        if (invoice) {
            this.id = invoice.id;
            this.invoiceNo = invoice.invoiceNo;
            this.reference = invoice.reference;
            this.invoiceStatus = invoice.invoiceStatus;
            this.paymentStatus = invoice.paymentStatus;
            this.deleted = invoice.deleted;
            this.carriedForward = invoice.carriedForward;
            this.consolidated = invoice.consolidated;
            this.dueDate = MposDate.newInstance(invoice.dueDate);
            this.lastReminder = MposDate.newInstance(invoice.lastReminder);
            this.paid = invoice.paid;
            this.balance = invoice.balance;
            this.carriedBalance = invoice.carriedBalance;
            this.consolidatedInvoice = invoice.consolidatedInvoice;
            this.consolidatedInvoices = invoice.consolidatedInvoices;
            this.subTotal = invoice.subTotal;
            this.discountedSubTotal = invoice.discountedSubTotal;
            this.shippingCost = invoice.shippingCost || 0;
            this.tax = invoice.tax;
            this.discountedTax = invoice.discountedTax;
            this.total = invoice.total;
            this.notes = invoice.notes;
            this.customer = invoice.customer;
            this.deliverySignedBy = invoice.deliverySignedBy;
            this.discount = invoice.discount || {
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            };
            this.consolidatedInto = invoice.consolidatedInto;

            if (invoice.customer) {
                if (invoice.customer.statementCode) {
                    this.statementCode = invoice.customer.statementCode.description;
                }
                if (invoice.customer.majorAccountCode) {
                    this.majorAccountCode = invoice.customer.majorAccountCode.description;
                }
            }
            this.paymentAllocation = invoice.paymentAllocation || [];
            this.createdBy = invoice.createdBy;
            this.deliveryPoint = invoice.deliveryPoint;
            this.saleOrder = invoice.saleOrder;
            this.invoiceLines = invoice.invoiceLines || [];
            this.adHocLines = invoice.adHocLines || [];
            this.creationDate = MposDate.newInstance(invoice.creationDate);
            this.dueDate = MposDate.newInstance(invoice.dueDate);
            this.lastReminder = MposDate.newInstance(invoice.lastReminder);
            this.updateDate = MposDate.newInstance(invoice.updateDate);
            this.exported = invoice.exported;
            this.unpaidTotal = invoice.unpaidTotal;
            this.creditNoteAllocation = invoice.creditNoteAllocation || [];
            this.purchaseOrderNumber = invoice.purchaseOrderNumber;
            this.assignedFranchise = invoice.assignedFranchise;
            this.equipment = invoice.equipment;
            this.serviceDescription = invoice.serviceDescription;
            this.adjustments = invoice.adjustments;
            this.saleOrderStatus = invoice.saleOrderStatus;
            this.serviceEquipmentItems = invoice.serviceEquipmentItems;
        } else {
            this.invoiceStatus = 'READY';
            this.paymentStatus = 'UNPAID';
            this.carriedBalance = 0;
            this.paid = 0;
            this.creationDate = MposDate.currentDate();
            this.dueDate = MposDate.currentDate();
            this.lastReminder = MposDate.currentDate();
            this.invoiceLines = [];
            this.adHocLines = [];
            this.paymentAllocation = [];
            this.creditNoteAllocation = [];
            this.subTotal = 0;
            this.discountedSubTotal = 0;
            this.shippingCost = 0;
            this.total = 0;
            this.tax = 0;
            this.discountedTax = 0;
            this.adjustments = [];
            this.serviceEquipmentItems = [];
        }
    }
    get discriminator(): any {
        return this.invoiceNo;
    }

    clone(): Invoice {
        return new Invoice(this);
    }
}

export interface IApplyCreditNoteInvoiceDTO {
    creditNote: ICreditNote;
    amount: number;
}
export class ApplyCreditNoteInvoiceDTO implements IApplyCreditNoteInvoiceDTO {
    public creditNote: ICreditNote;
    public amount: number;
    constructor(creditNote?: ICreditNote) {
        if (creditNote) {
            this.creditNote = creditNote;
        }
        this.amount = 0;
    }
}

export class SaveCreditNoteInvoiceDTO {
    public creditNoteId: number;
    public amount: number;
    constructor(creditNoteInvoiceDTO?: IApplyCreditNoteInvoiceDTO) {
        if (creditNoteInvoiceDTO.creditNote) {
            this.creditNoteId = creditNoteInvoiceDTO.creditNote.id;
        }
        this.amount = creditNoteInvoiceDTO.amount;
    }
}
