import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IShortfallReport extends IBaseEntity {
    saleOrderNo?: string;
    promisedDeliveryDate?: any;
    actualDeliveryDate?: any;
    routeName?: string;
    accountNo?: string;
    customerName?: string;
    productCode?: number;
    uom?: string;
    orderedQuantity?: number;
    deliveredQuantity?: number;
    shortfall?: number;
    pickedBy?: string;
    salesRepresentative?: string;
}

export class ShortfallReport extends BaseEntity implements IShortfallReport {
    public saleOrderNo?: string;
    public promisedDeliveryDate?: any;
    public actualDeliveryDate?: any;
    public routeName?: string;
    public accountNo?: string;
    public customerName?: string;
    public productCode?: number;
    public uom?: string;
    public orderedQuantity?: number;
    public deliveredQuantity?: number;
    public shortfall?: number;
    public pickedBy?: string;
    public salesRepresentative?: string;

    constructor(shortfallReport?) {
        super();
        if (shortfallReport) {
            this.saleOrderNo = shortfallReport.saleOrderNo;
            this.promisedDeliveryDate = MposDate.newInstance(shortfallReport.promisedDeliveryDate);
            this.actualDeliveryDate = MposDate.newInstance(shortfallReport.actualDeliveryDate);
            this.routeName = shortfallReport.routeName;
            this.accountNo = shortfallReport.accountNo;
            this.customerName = shortfallReport.customerName;
            this.productCode = shortfallReport.productCode;
            this.uom = shortfallReport.uom;
            this.orderedQuantity = shortfallReport.orderedQuantity;
            this.deliveredQuantity = shortfallReport.deliveredQuantity;
            this.shortfall = shortfallReport.shortfall;
            this.pickedBy = shortfallReport.pickedBy;
            this.salesRepresentative = shortfallReport.salesRepresentative;
        } else {
            // else
        }
    }
    clone(): ShortfallReport {
        return new ShortfallReport(this);
    }
}
