import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeliveryItemService} from 'app/blocks/service/api/delivery-item.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDeliveryItem} from 'app/blocks/model/delivery-item.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DeliveryItem} from 'app/blocks/model/delivery-item.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DeliveryItemResource extends AbstractEntityResource<IDeliveryItem> {
    constructor(deliveryItemService: DeliveryItemService, router: Router) {
        super(
            Resource.DELIVERY_ITEMS,
            deliveryItemService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DELIVERY_ITEMS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DELIVERY_ITEMS),
            (entity?) => new DeliveryItem(entity),
            (entity) => entity.code
        );
    }
}
