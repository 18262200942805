import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UnitOfMeasureService} from 'app/blocks/service/api/unit-of-measure.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IUnitOfMeasure} from 'app/blocks/model/unit-of-measure.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {UnitOfMeasure} from 'app/blocks/model/unit-of-measure.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class UnitOfMeasureResource extends AbstractEntityResource<IUnitOfMeasure> {
    constructor(unitOfMeasureService: UnitOfMeasureService, router: Router) {
        super(
            Resource.UNIT_OF_MEASURES,
            unitOfMeasureService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.UNIT_OF_MEASURES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.UNIT_OF_MEASURES),
            (entity?) => new UnitOfMeasure(entity),
            (entity) => entity.uom,
            'looks_one'
        );
    }
}
