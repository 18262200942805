import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ListViewParams} from 'app/blocks/util/list-view-params';

import {DEFAULT_ENTITY_NAMED_LIST} from 'app/app.constants';
import {EntityView} from 'app/blocks/util/entity-view';

export interface ActionMeta {
    currentPage?: string;
}

export interface IAction {
    suppressMessages?: boolean;
}

export interface IEntityAction extends IAction {
    resource: AbstractEntityResource<any>;
    currentPage?: EntityView;
}

export interface IUpdateEntity extends IEntityAction {
    entity: any;
}

export interface IStartInlineEntityUpdate extends IEntityAction {
    id: number;
}

export type IStartInlineEntityAdd = IEntityAction;

export interface ICancelInlineEntityUpdate extends IEntityAction {
    id?: number;
}

export interface IUpdateInlineEntity extends IEntityAction {
    entity: any;
}

export interface ISelectEntity extends IEntityAction {
    entityIds: number[];
}
export interface ISelectAllCurrentPageEntities extends IEntityAction {
    pageIndex?: number;
}

export interface IUnselectEntity extends IEntityAction {
    entityIds: number[];
}

export interface IUnselectAllCurrentPageEntities extends IEntityAction {
    pageIndex?: number;
}

export interface ISelectEntityTabIndex extends IEntityAction {
    tabIndex: number;
}

export interface IGetEntity extends IEntityAction {
    id: number;
}

export interface IGetNewEntity extends IEntityAction {
    // ID is not required, but tslint won't allow us to have empty interfaces
    id?: number;
}

export interface IGetEntityList extends IEntityAction {
    viewParams?: ListViewParams;
}

export interface IChangeEntityListViewParams extends IEntityAction {
    viewParams?: ListViewParams;
}

export interface ICreateEntity extends IEntityAction {
    entity: any;
}

export interface ICreateInlineEntity extends IEntityAction {
    entity: any;
}

export interface IDeleteEntity extends IEntityAction {
    id: number;
}

export interface IGetNamedEntityList extends IEntityAction {
    name?: string;
}

export interface IGetNewEntityWithSettings extends IEntityAction {
    settingResourceName?: string;
}

export interface IExportEntities extends IEntityAction {
    ids: Set<number>;
    filename: string;
    params?: any;
}

export class AbstractGetNamedEntityList implements IEntityAction {
    constructor(
        public resource: AbstractEntityResource<any>,
        public name: string = DEFAULT_ENTITY_NAMED_LIST,
        public forceReload: boolean = false,
        public meta?: ActionMeta
    ) {}
}

export interface IDuplicateEntity extends IEntityAction {
    id: number;
}

// New Entity Actions

export class UpdateEntity implements IUpdateEntity {
    static readonly type = '[Generic] Update Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: any,
        public meta?: ActionMeta
    ) {}
}

export class UpdateInlineEntity implements IUpdateInlineEntity {
    static readonly type = '[Generic] Update Inline Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: any,
        public meta?: ActionMeta
    ) {}
}

export class StartInlineEntityUpdate implements IStartInlineEntityUpdate {
    static readonly type = '[Generic] Start Update of Inline Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class StartInlineEntityAdd implements IStartInlineEntityAdd {
    static readonly type = '[Generic] Start Addition of Inline Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public meta?: ActionMeta
    ) {}
}

export class CancelInlineEntityUpdate implements ICancelInlineEntityUpdate {
    static readonly type = '[Generic] Cancel Update of Inline Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id?: number,
        public meta?: ActionMeta
    ) {}
}

export class SelectEntity implements ISelectEntity {
    static readonly type = '[Generic] Select Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entityIds: number[],
        public meta?: ActionMeta
    ) {}
}

export class SelectAllCurrentPageEntities implements ISelectAllCurrentPageEntities {
    static readonly type = '[Generic] Select All Current Page Entities';
    constructor(
        public resource: AbstractEntityResource<any>,
        public pageIndex?: number,
        public meta?: ActionMeta
    ) {}
}

export class UnselectEntity implements IUnselectEntity {
    static readonly type = '[Generic] Unselect Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entityIds: number[],
        public meta?: ActionMeta
    ) {}
}

export class UnselectAllCurrentPageEntities implements IUnselectAllCurrentPageEntities {
    static readonly type = '[Generic] Unselect all Current Page Entities';
    constructor(
        public resource: AbstractEntityResource<any>,
        public pageIndex?: number,
        public meta?: ActionMeta
    ) {}
}

export class SelectEntityTabIndex implements ISelectEntityTabIndex {
    static readonly type = '[Generic] Select Entity Tab Index';
    constructor(
        public resource: AbstractEntityResource<any>,
        public tabIndex: number,
        public meta?: ActionMeta
    ) {}
}

export class GetEntity implements IGetEntity {
    static readonly type = '[Generic] Get Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class GetNewEntity implements IGetNewEntity {
    static readonly type = '[Generic] Get New Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id?: number,
        public meta?: ActionMeta
    ) {}
}

export class GetEntityList implements IGetEntityList {
    static readonly type = '[Generic] Get Entity List';
    constructor(
        public resource: AbstractEntityResource<any>,
        public meta?: ActionMeta,
        public viewParams?: ListViewParams
    ) {}
    // constructor(public resource: AbstractEntityResource<any>, public viewParams?: ListViewParams, public meta?: ActionMeta){}
}

export class ChangeEntityListViewParams implements IChangeEntityListViewParams {
    static readonly type = '[Generic] Change Entity List View Params';
    constructor(
        public resource: AbstractEntityResource<any>,
        public viewParams?: ListViewParams,
        public meta?: ActionMeta
    ) {}
}

export class CreateEntity implements ICreateEntity {
    static readonly type = '[Generic] Create Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: any,
        public meta?: ActionMeta
    ) {}
}

export class CreateInlineEntity implements ICreateInlineEntity {
    static readonly type = '[Generic] Create Inline Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: any,
        public meta?: ActionMeta
    ) {}
}

export class DeleteEntity implements IDeleteEntity {
    static readonly type = '[Generic] Delete Entity';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class GetNamedEntityList extends AbstractGetNamedEntityList {
    static readonly type = '[Generic] Get Named Entity List';
}

export class GetNewEntityWithSettings implements IGetNewEntityWithSettings {
    static readonly type = '[Generic] Get New Entity With Settings';
    constructor(
        public resource: AbstractEntityResource<any>,
        public settingResourceName,
        public id?: number,
        public meta?: ActionMeta
    ) {}
}

export class ExportEntities implements IExportEntities {
    static readonly type = '[Generic] Export Entities';
    constructor(
        public resource: AbstractEntityResource<any>,
        public ids: Set<number>,
        public filename: string,
        public params?: any,
        public meta?: ActionMeta
    ) {}
}

export class DuplicateEntity implements IDuplicateEntity {
    static readonly type = '[Generic] Duplicate Entity and Update Create / Edit';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number
    ) {}
}

export const actionTypes = [
    GetEntity,
    GetEntityList,
    CreateEntity,
    UpdateEntity,
    UpdateInlineEntity,
    SelectEntity,
    UnselectEntity,
    SelectAllCurrentPageEntities,
    UnselectAllCurrentPageEntities,
    SelectEntityTabIndex,
    DeleteEntity,
    GetNamedEntityList,
    GetNewEntity,
    ChangeEntityListViewParams,
    ExportEntities,
    DuplicateEntity
];
