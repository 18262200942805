import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LoadOrderService} from 'app/blocks/service/api/load-order.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ILoadOrder} from 'app/blocks/model/load-order.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {LoadOrder} from 'app/blocks/model/load-order.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class LoadOrderResource extends AbstractEntityResource<ILoadOrder> {
    constructor(loadOrderService: LoadOrderService, router: Router) {
        super(
            Resource.LOAD_ORDERS,
            loadOrderService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.LOAD_ORDERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.LOAD_ORDERS),
            (entity?) => new LoadOrder(entity),
            (entity) => entity.loadOrderNo,
            'airport_shuttle'
        );
    }
}
