import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IExpenseType extends IBaseEntity {
    id?: number;
    name: string;
    maxAmount?: number;
}

export class ExpenseType extends BaseEntity implements IExpenseType {
    public id?: number;
    public name: string;
    public maxAmount?: number;

    constructor(expenseType?: any) {
        super();
        if (expenseType) {
            console.log('expenseType: ', expenseType);
            this.id = expenseType.id;
            this.name = expenseType.name;
            this.maxAmount = expenseType.maxAmount;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ExpenseType {
        return new ExpenseType(this);
    }
}
