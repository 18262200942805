import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {StatementCodeService} from 'app/blocks/service/api/statement-code.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IStatementCode} from 'app/blocks/model/statement-code.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {StatementCode} from 'app/blocks/model/statement-code.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class StatementCodeResource extends AbstractEntityResource<IStatementCode> {
    constructor(statementCodeService: StatementCodeService, router: Router) {
        super(
            Resource.STATEMENT_CODES,
            statementCodeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.STATEMENT_CODES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.STATEMENT_CODES),
            (entity?) => new StatementCode(entity),
            (entity) => entity.code,
            'library_books'
        );
    }
}
