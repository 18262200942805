import {IListProductPrice, ListProductPrice} from 'app/blocks/model/list-product-price.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {DiscountTypes} from 'app/blocks/model/discount-types';

export interface IDurationPriceList extends IBaseEntity {
    id?: number;
    name?: string;
    fromDate?: any;
    toDate?: any;
    isDiscountPriceList?: boolean;
    discountValue?: number;
    listproductprice?: IListProductPrice[];
    discountType?: DiscountTypes;
}

export class DurationPriceList extends BaseEntity implements IDurationPriceList {
    public id?: number;
    public name?: string;
    public fromDate?: any;
    public toDate?: any;
    public isDiscountPriceList?: boolean;
    public discountValue?: number;
    public listProductPrices?: IListProductPrice[];
    public discountType?: DiscountTypes;

    constructor(durationPriceList?) {
        super();
        if (durationPriceList) {
            this.id = durationPriceList.id;
            this.name = durationPriceList.name;
            this.fromDate = MposDate.newInstance(durationPriceList.fromDate);
            this.toDate = MposDate.newInstance(durationPriceList.toDate);
            this.isDiscountPriceList = durationPriceList.isDiscountPriceList;
            this.discountValue = durationPriceList.discountValue;
            this.discountType = durationPriceList.discountType;
            this.listProductPrices = durationPriceList.listProductPrices || [];
        } else {
            this.listProductPrices = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DurationPriceList {
        return new DurationPriceList(this);
    }
}
