import {IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {CustomField, ICustomField} from 'app/blocks/model/custom-field.model';

export interface ICustomFieldGroup extends IBaseEntity {
    id?: number;

    name?: string;

    entityName?: string;

    customFields: ICustomField[];

    allowedVariantIds: number[];
}

export class CustomFieldGroup extends BaseFranchiseEntity implements ICustomFieldGroup {
    public id?: number;

    public name?: string;

    public entityName?: string;

    public customFields: CustomField[] = [];

    public allowedVariantIds: number[] = [];

    constructor(customFieldGroup?) {
        super();
        if (customFieldGroup) {
            this.id = customFieldGroup.id;

            this.name = customFieldGroup.name;

            this.entityName = customFieldGroup.entityName;

            if (customFieldGroup.customFields) {
                this.customFields = customFieldGroup.customFields;
            }

            if (customFieldGroup.allowedVariantIds) {
                this.allowedVariantIds = customFieldGroup.allowedVariantIds;
            }

            if (customFieldGroup.editable !== undefined) {
                this.editable = customFieldGroup.editable;
            }
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): CustomFieldGroup {
        return new CustomFieldGroup(this);
    }
}
