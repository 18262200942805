import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {IEmployee, Employee} from 'app/blocks/model/employee.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
export interface IMajorAccountCode extends IBaseEntity {
    id?: number;
    code?: string;
    description?: string;
    requirePurchaseOrderForSales?: boolean;
    internalAccountManager?: IEmployee;
    externalAccountManager?: IEmployee;
    otherEmployee?: IEmployee;
    creditController?: IEmployee;
}
export class MajorAccountCode extends BaseEntity implements IMajorAccountCode {
    public id?: number;
    public code?: string;
    public description?: string;
    public requirePurchaseOrderForSales?: boolean;
    public contactInfo?: IContactInfo;
    public internalAccountManager?: IEmployee;
    public externalAccountManager?: IEmployee;
    public otherEmployee?: IEmployee;
    public creditController?: IEmployee;
    constructor(majorAccountCode?) {
        super();
        if (majorAccountCode) {
            this.id = majorAccountCode.id;
            this.code = majorAccountCode.code;
            this.description = majorAccountCode.description;
            this.requirePurchaseOrderForSales = majorAccountCode.requirePurchaseOrderForSales;
            this.internalAccountManager = majorAccountCode.internalAccountManager;
            this.externalAccountManager = majorAccountCode.externalAccountManager;
            // this.contactInfo = new ContactInfo(majorAccountCode.contactInfo);
            this.otherEmployee = majorAccountCode.otherEmployee;
            this.creditController = majorAccountCode.creditController;
        } else {
            // this.contactInfo = new ContactInfo();
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): MajorAccountCode {
        return new MajorAccountCode(this);
    }
}
