import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IPackOrder, PackOrder} from 'app/blocks/model/pack-order.model';
import {Resource} from 'app/constants/resource';
import {createRequestOption} from 'app/blocks/util/request-util';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PackOrderService extends EntityCrudService<IPackOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.PACK_ORDERS, (obj?) => new PackOrder(obj));
    }

    completePackOrder = (request): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/pack-orders/' + request.id + '/complete', request, {observe: 'response'}).toPromise();
    };

    putInPacks = (request): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/pack-orders/' + request.id + '/put-in-pack', request).toPromise();
    };

    startPackOrder = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/pack-orders/' + id + '/start', null, {observe: 'response'}).toPromise();
    };

    createLoadOrderFromPackOrders = (request): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/pack-orders/load-order', request, {
                observe: 'response'
            })
            .toPromise();
    };

    downloadPDF = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/pack-orders/${id}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'}).toPromise();
    };

    downloadMergedPackOrders = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/pack-orders/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    completePackOrders = (ids: number[], packDate: string, packTime: string, employee: object): Promise<any> => {
        const request = {ids, employee, packDate, packTime};
        return this.http.post<any>(SERVER_API_URL + 'api/pack-orders/bulk-complete', request, {observe: 'response'}).toPromise();
    };

    downloadConsolidatedPdf = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/pack-orders/download-consolidated`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
}
