import {PurchaseCreditNote, IPurchaseCreditNote, IApplyPurchaseCreditNoteDTO} from 'app/blocks/model/purchase-credit-note.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PurchaseCreditNoteService extends EntityCrudService<IPurchaseCreditNote> {
    constructor(http: HttpClient) {
        super(http, Resource.PURCHASE_CREDIT_NOTES, (obj?) => new PurchaseCreditNote(obj));
    }

    public approvePurchaseCreditNote = (id): any => {
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-credit-notes/' + id + '/approve' + status, null, {observe: 'response'}).toPromise();
    };

    public bulkApprovePurchaseCreditNote = async (ids): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-credit-notes/bulk-approve' + status, ids, {observe: 'response'}).toPromise();
    };

    public cancelPurchaseCreditNote = (id): any => {
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-credit-notes/' + id + '/cancel' + status, null, {observe: 'response'}).toPromise();
    };

    public savePurchaseCreditNote(purchaseCreditNote: any): any {
        return this.http
            .post<any>(SERVER_API_URL + 'api/purchase-credit-notes', purchaseCreditNote, {
                observe: 'response'
            })
            .toPromise();
    }

    togglePurchaseCreditNoteExportedStatus = (ids: number[]): any => {
        const requestParams = new HttpParams().set('ids', ids.join(','));
        const responseObservable$ = this.http.post<any>(SERVER_API_URL + 'api/purchase-credit-notes/toggle-export-status', null, {
            observe: 'response',
            params: requestParams
        });
        return lastValueFrom(responseObservable$);
    };

    getUnAppliedCreditNotesForSupplier = (supplierId: number): any => {
        return this.http.get(SERVER_API_URL + `api/suppliers/${supplierId}/unapplied-purchase-credit-notes`, {observe: 'response'}).toPromise();
    };

    applyPurchaseCreditNotesToPurchaseInvoices = (purchaseCreditNoteId: string, allocations: IApplyPurchaseCreditNoteDTO[]): any => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/purchase-credit-notes/${purchaseCreditNoteId}/allocate`, allocations, {observe: 'response'})
            .toPromise();
    };

    public unAllocatePurchaseInvoiceFromPurchaseCreditNote = (purchaseCreditNoteId: number, purchaseInvoiceId: any): any => {
        const requestParams = new HttpParams().set('purchaseInvoiceId', purchaseInvoiceId);
        return this.http
            .post<any>(SERVER_API_URL + `api/purchase-credit-notes/${purchaseCreditNoteId}/un-allocate`, null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    public approvePurchaseCreditNoteWithInventoryAdjustment = (purchaseCreditNoteId, approveDate, approveTime): any => {
        const requestParams = new HttpParams().set('approveDate', approveDate).set('approveTime', approveTime);

        return this.http
            .post<any>(SERVER_API_URL + 'api/purchase-credit-notes/' + purchaseCreditNoteId + '/approve-with-inv-adjustment' + status, null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    public bulkApprovePurchaseCreditNoteWithInventoryAdjustment = async (purchaseCreditNoteIds, approvalDate, approvalTime): Promise<any> => {
        const body = {
            purchaseCreditNoteIds: purchaseCreditNoteIds,
            approvalDate: approvalDate,
            approvalTime: approvalTime
        };

        return this.http
            .post<any>(SERVER_API_URL + 'api/purchase-credit-notes/bulk-approve-with-inv-adjustment' + status, body, {observe: 'response'})
            .toPromise();
    };

    generatePdf = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + `api/purchase-credit-notes/${id}/generate-pdf`, null, {observe: 'response'}).toPromise();
    };

    public downloadPDF = (purchaseCreditNoteId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-credit-notes/${purchaseCreditNoteId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public downloadMergedCreditNotes = (purchaseCreditNoteIds: number[]): Promise<any> => {
        const requestParams = new HttpParams().set('ids', purchaseCreditNoteIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-credit-notes/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
}
