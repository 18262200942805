import {StockChangeConditionResource} from 'app/blocks/resource/stock-change-condition-resource';
import {ProfileResource} from 'app/blocks/resource/profile-resource';
import {TenantResource} from 'app/blocks/resource/tenants-resource';
import {Role} from 'app/blocks/model/role.model';
import {DailyActivitySummaryResource} from 'app/blocks/resource/daily-activity-summary-resource';
import {CGSTCategoryResource} from 'app/blocks/resource/cgst-category-resource';
import {FuelSurchargeGroupResource} from 'app/blocks/resource/fuel-surcharge-group-resource';
import {Injectable, Injector, Type} from '@angular/core';
import {Store} from '@ngxs/store';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {Observable} from 'rxjs';

import {DEFAULT_ENTITY_NAMED_LIST} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {BranchResource} from 'app/blocks/resource/branch-resource';
import {CountryResource} from 'app/blocks/resource/country-resource';
import {WarehouseResource} from 'app/blocks/resource/warehouse-resource';
import {WarehouseTypeResource} from 'app/blocks/resource/warehouse-type-resource';
import {CompanyResource} from 'app/blocks/resource/company-resource';
import {CurrencyResource} from 'app/blocks/resource/currency-resource';
import {MajorAccountCodeResource} from 'app/blocks/resource/major-account-code-resource';
import {EmployeeResource} from 'app/blocks/resource/employee-resource';
import {EmployeeGroupResource} from 'app/blocks/resource/employee-group-resource';
import {StatementCodeResource} from 'app/blocks/resource/statement-code-resource';
import {BillingFrequencyTypeResource} from 'app/blocks/resource/billing-frequency-type-resource';
import {StatementTypeResource} from 'app/blocks/resource/statement-type-resource';
import {DunningMessageCodeResource} from 'app/blocks/resource/dunning-message-code-resource';
import {CreditClassResource} from 'app/blocks/resource/credit-class-resource';
import {CreditTermResource} from 'app/blocks/resource/credit-term-resource';
import {DeliveryPointResource} from 'app/blocks/resource/delivery-point-resource';
import {DeliveryPointMessageTypeResource} from 'app/blocks/resource/delivery-point-message-type-resource';
import {DynamicHeaderCodeResource} from 'app/blocks/resource/dynamic-header-code-resource';
import {StopTypeResource} from 'app/blocks/resource/stop-type-resource';
import {CustomerResource} from 'app/blocks/resource/customer-resource';
import {PaymentTypeResource} from 'app/blocks/resource/payment-type-resource';
import {CustomerStatusResource} from 'app/blocks/resource/customer-status-resource';
import {ServicePriorityCodeResource} from 'app/blocks/resource/service-priority-code-resource';
import {CustomerStatusReasonResource} from 'app/blocks/resource/customer-status-reason-resource';
import {CustomerStatusReasonGroupResource} from 'app/blocks/resource/customer-status-reason-group-resource';
import {CustomerGroupResource} from 'app/blocks/resource/customer-group-resource';
import {PriceListResource} from 'app/blocks/resource/price-list-resource';
import {PriceListTypeResource} from 'app/blocks/resource/price-list-type-resource';
import {DurationPriceListResource} from 'app/blocks/resource/duration-price-list-resource';
import {ListProductPriceResource} from 'app/blocks/resource/list-product-price-resource';
import {CustomerSmsTextResource} from 'app/blocks/resource/customer-sms-text-resource';

import {ProductClassResource} from 'app/blocks/resource/product-class-resource';
import {ProductGroupResource} from 'app/blocks/resource/product-group-resource';
import {UnitOfMeasureResource} from 'app/blocks/resource/unit-of-measure-resource';
import {PackagingResource} from 'app/blocks/resource/packaging-resource';
import {ProductPackagingResource} from 'app/blocks/resource/product-packaging-resource';
import {ProductResource} from 'app/blocks/resource/product-resource';
import {ProductListResource} from 'app/blocks/resource/product-list-resource';
import {ProductTaxCategoryResource} from 'app/blocks/resource/product-tax-category-resource';
import {DurationProductPriceInfoResource} from 'app/blocks/resource/duration-product-price-info-resource';
import {DeliveryPackageTypeResource} from 'app/blocks/resource/delivery-package-type-resource';

import {VehicleResource} from 'app/blocks/resource/vehicle-resource';
import {VehicleTypeResource} from 'app/blocks/resource/vehicle-type-resource';
import {TrailerResource} from 'app/blocks/resource/trailer-resource';
import {TrailerTypeResource} from 'app/blocks/resource/trailer-type-resource';
import {RouteTypeResource} from 'app/blocks/resource/route-type-resource';
import {RouteMapSymbolResource} from 'app/blocks/resource/route-map-symbol-resource';
import {RouteResource} from 'app/blocks/resource/route-resource';

import {SaleOrderResource} from 'app/blocks/resource/sale-order-resource';
import {SaleOrderTypeResource} from 'app/blocks/resource/sale-order-type-resource';
import {DeliveryMethodResource} from 'app/blocks/resource/delivery-method-resource';
import {OrderPriorityResource} from 'app/blocks/resource/order-priority-resource';
import {OrderStatusReasonResource} from 'app/blocks/resource/order-status-reason-resource';
import {OrderItemResource} from 'app/blocks/resource/order-item-resource';
import {OrderRejectionCodeResource} from 'app/blocks/resource/order-rejection-code-resource';
import {IncomingInventoryResource} from 'app/blocks/resource/incoming-inventory-resource';
import {InventoryProductEntryResource} from 'app/blocks/resource/inventory-product-entry-resource';
import {InventoryReasonResource} from 'app/blocks/resource/inventory-reason-resource';
import {InventoryReasonGroupResource} from 'app/blocks/resource/inventory-reason-group-resource';
import {LoadOrderResource} from 'app/blocks/resource/load-order-resource';
import {DeliveryItemResource} from 'app/blocks/resource/delivery-item-resource';
import {DeliveryPackageResource} from 'app/blocks/resource/delivery-package-resource';
import {PackOrderResource} from 'app/blocks/resource/pack-order-resource';
import {PickOrderResource} from 'app/blocks/resource/pick-order-resource';
import {OrderItemReasonResource} from 'app/blocks/resource/order-item-reason-resource';
import {DeliveryOrderResource} from 'app/blocks/resource/delivery-order-resource';

import {HandheldProfileResource} from 'app/blocks/resource/handheld-profile-resource';
import {OrganisationResource} from 'app/blocks/resource/organisation.resource';
import {VehicleUnloadResource} from 'app/blocks/resource/vehicle-unload.resource';
import {SupplierResource} from 'app/blocks/resource/supplier-resource';
import {ServiceOrderResource} from 'app/blocks/resource/service-order-resource';
import {EquipmentResource} from 'app/blocks/resource/equipment-resource';
import {GetNamedEntityList} from 'app/blocks/store/actions/entity-actions';
import {DepositItemResource} from 'app/blocks/resource/deposit-item-resource';
import {ServiceItemResource} from 'app/blocks/resource/service-item-resource';
import {ClientSaleOrderResource} from 'app/blocks/resource/client-sale-order-resource';
import {PurchaseOrderResource} from 'app/blocks/resource/purchase-order-resource';
import {SGSTCategoryResource} from 'app/blocks/resource/sgst-category-resource';
import {IGSTCategoryResource} from 'app/blocks/resource/igst-category-resource';
import {VehicleCheckListResource} from 'app/blocks/resource/vehicle-check-list-resource';
import {WorkshopResource} from 'app/blocks/resource/workshop-resource';
import {RoiVatCategoryResource} from 'app/blocks/resource/roi-vat-category-resource';
import {RestoreResource} from 'app/blocks/resource/restore-resource';
import {BackupResource} from 'app/blocks/resource/backup-resource';
import {Execution} from 'app/blocks/model/execution.model';
import {ExecutionResource} from 'app/blocks/resource/execution-resource';
import {UserResource} from 'app/blocks/resource/user-resource';
import {ShortfallReportResource} from 'app/blocks/resource/shortfall-report-resource';
import {ProductCostChangeResource} from 'app/blocks/resource/product-cost-change-resource';
import {SurveyReportResource} from 'app/blocks/resource/survey-report-resource';
import {GctTaxCategoryResource} from 'app/blocks/resource/gct-tax-category-resource';
import {SiteVisitSkipReasonsResource} from 'app/blocks/resource/site-visit-skip-reasons-resource';
import {CustomFieldGroupResource} from 'app/blocks/resource/custom-field-group-resource';
import {CustomFieldResource} from 'app/blocks/resource/custom-field-resource';
import {AsyncRequestResource} from 'app/blocks/resource/async-request-resource';
import {BranchPaymentResource} from 'app/blocks/resource/branch-payment-resource';
import {BranchTransactionResource} from 'app/blocks/resource/branch-transaction-resource';
import {PurchasePaymentResource} from 'app/blocks/resource/purchase-payment-resource';
import {PurchaseCreditNoteResource} from 'app/blocks/resource/purchase-credit-note-resource';
import {ProductAttributeTypeResource} from 'app/blocks/resource/product-attribute-type-resource';
import {CatalogueResource} from 'app/blocks/resource/catalogue-resource';
import {PresentationResource} from 'app/blocks/resource/presentation-resource';
import {CatalogueResourceItemResource} from 'app/blocks/resource/resource-item-resource';
import {SupplierPaymentTermResource} from 'app/blocks/resource/supplier-payment-term-resource';
import {ExpensePaymentMethodResource} from 'app/blocks/resource/expense-payment-method-resource';
import {ExpenseTypeResource} from 'app/blocks/resource/expense-type-resource';
import {EmployeeExpenseResource} from 'app/blocks/resource/employee-expense-resource';
import {BrandResource} from 'app/blocks/resource/brand-resource';
import {IntegrationMappingResource} from 'app/blocks/resource/integration-mapping-resource';
import {VehicleSkillResource} from 'app/blocks/resource/vehicle-skill';
import {CatalogueResourceGroupResource} from 'app/blocks/resource/resource-group-resource';
import {BinTypeResource} from 'app/blocks/resource/bin-type-resource';
import {BinResource} from 'app/blocks/resource/bin-resource';
import {InvoiceResource} from 'app/blocks/resource/invoice-resource';
import {CustomPdfTemplateResource} from 'app/blocks/resource/custom-pdf-template-resource';
import {SupplerGroupResource} from 'app/blocks/resource/supplier-group-resource';
import {BudgetResource} from 'app/blocks/resource/budget-resource';

@Injectable({
    providedIn: 'root'
})
export class NamedListService {
    private _listApiService = new Map<Resource, Type<any>>();

    constructor(
        private _store: Store,
        private _injector: Injector
    ) {
        this._initializeProviders();
    }

    for(resource: Resource, name: string = DEFAULT_ENTITY_NAMED_LIST, forceReload = false): Observable<any[]> {
        // console.log(`[NamedListService] Requested a named list for ${resource}: ${name}`);
        const listResource = this._injector.get(this._listApiService.get(resource)) as AbstractEntityResource<any>;
        this._store.dispatch(new GetNamedEntityList(listResource, name, forceReload));
        return this._store.select((state) => state.entities[resource].partialEntity[name].list);
    }

    private _initializeProviders(): void {
        this._listApiService.set(Resource.DAILY_ACTIVITY_SUMMARIES, DailyActivitySummaryResource);
        this._listApiService.set(Resource.SUPPLIERS, SupplierResource);
        this._listApiService.set(Resource.ROLES, Role);
        this._listApiService.set(Resource.BRANCHES, BranchResource);
        this._listApiService.set(Resource.COUNTRIES, CountryResource);
        this._listApiService.set(Resource.WAREHOUSES, WarehouseResource);
        this._listApiService.set(Resource.WORKSHOP, WorkshopResource);
        this._listApiService.set(Resource.WAREHOUSE_TYPES, WarehouseTypeResource);
        this._listApiService.set(Resource.COMPANIES, CompanyResource);
        this._listApiService.set(Resource.CURRENCIES, CurrencyResource);
        this._listApiService.set(Resource.MAJOR_ACCOUNT_CODES, MajorAccountCodeResource);
        this._listApiService.set(Resource.EMPLOYEES, EmployeeResource);
        this._listApiService.set(Resource.EMPLOYEE_GROUPS, EmployeeGroupResource);
        this._listApiService.set(Resource.STATEMENT_CODES, StatementCodeResource);
        this._listApiService.set(Resource.STATEMENT_TYPES, StatementTypeResource);
        this._listApiService.set(Resource.BILLING_FREQUENCY_TYPES, BillingFrequencyTypeResource);
        this._listApiService.set(Resource.DUNNING_MESSAGE_CODES, DunningMessageCodeResource);
        this._listApiService.set(Resource.CREDIT_CLASSES, CreditClassResource);
        this._listApiService.set(Resource.CREDIT_TERMS, CreditTermResource);
        this._listApiService.set(Resource.DELIVERY_POINTS, DeliveryPointResource);
        this._listApiService.set(Resource.DYNAMIC_HEADER_CODES, DynamicHeaderCodeResource);
        this._listApiService.set(Resource.STOP_TYPES, StopTypeResource);
        this._listApiService.set(Resource.CUSTOMERS, CustomerResource);
        this._listApiService.set(Resource.PAYMENT_TYPES, PaymentTypeResource);
        this._listApiService.set(Resource.CUSTOMER_STATUSES, CustomerStatusResource);
        this._listApiService.set(Resource.SERVICE_PRIORITY_CODES, ServicePriorityCodeResource);
        this._listApiService.set(Resource.CUSTOM_FIELD_GROUPS, CustomFieldGroupResource);
        this._listApiService.set(Resource.CUSTOM_FIELDS, CustomFieldResource);
        this._listApiService.set(Resource.CUSTOMER_STATUS_REASONS, CustomerStatusReasonResource);
        this._listApiService.set(Resource.CUSTOMER_STATUS_REASON_GROUPS, CustomerStatusReasonGroupResource);
        this._listApiService.set(Resource.CUSTOMER_GROUPS, CustomerGroupResource);
        this._listApiService.set(Resource.PRICE_LISTS, PriceListResource);
        this._listApiService.set(Resource.PRICE_LIST_TYPES, PriceListTypeResource);
        this._listApiService.set(Resource.DURATION_PRICE_LISTS, DurationPriceListResource);
        this._listApiService.set(Resource.LIST_PRODUCT_PRICES, ListProductPriceResource);
        this._listApiService.set(Resource.DELIVERY_POINT_MESSAGE_TYPES, DeliveryPointMessageTypeResource);
        this._listApiService.set(Resource.CUSTOMER_SMS_TEXTS, CustomerSmsTextResource);
        this._listApiService.set(Resource.TENANTS, TenantResource);
        this._listApiService.set(Resource.VEHICLE_UNLOADS, VehicleUnloadResource);
        this._listApiService.set(Resource.DEPOSIT_ITEMS, DepositItemResource);

        this._listApiService.set(Resource.PRODUCT_CLASSES, ProductClassResource);
        this._listApiService.set(Resource.PRODUCT_ATTRIBUTE_TYPES, ProductAttributeTypeResource);
        this._listApiService.set(Resource.PRODUCT_GROUPS, ProductGroupResource);
        this._listApiService.set(Resource.UNIT_OF_MEASURES, UnitOfMeasureResource);
        this._listApiService.set(Resource.PACKAGINGS, PackagingResource);
        this._listApiService.set(Resource.PRODUCT_PACKAGINGS, ProductPackagingResource);
        this._listApiService.set(Resource.PRODUCTS, ProductResource);
        this._listApiService.set(Resource.PRODUCT_LISTS, ProductListResource);
        this._listApiService.set(Resource.UK_VAT_RATES, ProductTaxCategoryResource);
        this._listApiService.set(Resource.ROI_VAT_RATES, RoiVatCategoryResource);
        this._listApiService.set(Resource.DURATION_PRODUCT_PRICE_INFOS, DurationProductPriceInfoResource);
        this._listApiService.set(Resource.DELIVERY_PACKAGE_TYPES, DeliveryPackageTypeResource);
        this._listApiService.set(Resource.DEPOSIT_ITEMS, DepositItemResource);

        this._listApiService.set(Resource.VEHICLES, VehicleResource);
        this._listApiService.set(Resource.VEHICLE_TYPES, VehicleTypeResource);
        this._listApiService.set(Resource.VEHICLE_SKILL, VehicleSkillResource);
        this._listApiService.set(Resource.TRAILERS, TrailerResource);
        this._listApiService.set(Resource.TRAILER_TYPES, TrailerTypeResource);
        this._listApiService.set(Resource.ROUTE_TYPES, RouteTypeResource);
        this._listApiService.set(Resource.ROUTE_MAP_SYMBOLS, RouteMapSymbolResource);
        this._listApiService.set(Resource.ROUTES, RouteResource);

        this._listApiService.set(Resource.SALE_ORDERS, SaleOrderResource);
        this._listApiService.set(Resource.CLIENT_SALE_ORDERS, ClientSaleOrderResource);
        this._listApiService.set(Resource.SALE_ORDER_TYPES, SaleOrderTypeResource);
        this._listApiService.set(Resource.DELIVERY_METHODS, DeliveryMethodResource);
        this._listApiService.set(Resource.ORDER_PRIORITIES, OrderPriorityResource);
        this._listApiService.set(Resource.PROFILES, ProfileResource);
        this._listApiService.set(Resource.ORDER_STATUS_REASONS, OrderStatusReasonResource);
        this._listApiService.set(Resource.ORDER_ITEMS, OrderItemResource);
        this._listApiService.set(Resource.ORDER_REJECTION_CODES, OrderRejectionCodeResource);
        this._listApiService.set(Resource.INCOMING_INVENTORIES, IncomingInventoryResource);
        this._listApiService.set(Resource.INVENTORY_PRODUCT_ENTRIES, InventoryProductEntryResource);
        this._listApiService.set(Resource.INVENTORY_REASONS, InventoryReasonResource);
        this._listApiService.set(Resource.INVENTORY_REASON_GROUPS, InventoryReasonGroupResource);
        this._listApiService.set(Resource.LOAD_ORDERS, LoadOrderResource);
        this._listApiService.set(Resource.DELIVERY_ITEMS, DeliveryItemResource);
        this._listApiService.set(Resource.DELIVERY_PACKAGES, DeliveryPackageResource);
        this._listApiService.set(Resource.PACK_ORDERS, PackOrderResource);
        this._listApiService.set(Resource.PICK_ORDERS, PickOrderResource);
        this._listApiService.set(Resource.ORDER_ITEM_REASONS, OrderItemReasonResource);
        this._listApiService.set(Resource.DELIVERY_ORDERS, DeliveryOrderResource);
        this._listApiService.set(Resource.SERVICE_ITEMS, ServiceItemResource);
        this._listApiService.set(Resource.EQUIPMENTS, EquipmentResource);
        this._listApiService.set(Resource.HANDHELD_PROFILES, HandheldProfileResource);
        this._listApiService.set(Resource.ORGANISATIONS, OrganisationResource);
        this._listApiService.set(Resource.FUEL_SURCHARGE_GROUPS, FuelSurchargeGroupResource);
        this._listApiService.set(Resource.PURCHASE_ORDERS, PurchaseOrderResource);
        this._listApiService.set(Resource.IN_CGST_RATES, CGSTCategoryResource);
        this._listApiService.set(Resource.IN_SGST_RATES, SGSTCategoryResource);
        this._listApiService.set(Resource.IN_IGST_RATES, IGSTCategoryResource);
        this._listApiService.set(Resource.VEHICLE_CHECKLISTS, VehicleCheckListResource);
        this._listApiService.set(Resource.STOCK_CHANGE_CONDITION, StockChangeConditionResource);
        this._listApiService.set(Resource.RESTORES, RestoreResource);
        this._listApiService.set(Resource.BACKUPS, BackupResource);
        this._listApiService.set(Resource.EXECUTIONS, ExecutionResource);
        this._listApiService.set(Resource.USERS, UserResource);
        this._listApiService.set(Resource.SHORTFALL_REPORT, ShortfallReportResource);
        this._listApiService.set(Resource.PRODUCT_COST_CHANGES, ProductCostChangeResource);
        this._listApiService.set(Resource.SURVEY_REPORT, SurveyReportResource);
        this._listApiService.set(Resource.GCT_RATES, GctTaxCategoryResource);
        this._listApiService.set(Resource.SITE_VISIT_SKIP_REASONS, SiteVisitSkipReasonsResource);
        this._listApiService.set(Resource.ASYNC_REQUESTS, AsyncRequestResource);
        this._listApiService.set(Resource.BRANCH_PAYMENTS, BranchPaymentResource);
        this._listApiService.set(Resource.BRANCH_TRANSACTIONS, BranchTransactionResource);
        this._listApiService.set(Resource.PURCHASE_PAYMENTS, PurchasePaymentResource);
        this._listApiService.set(Resource.PURCHASE_CREDIT_NOTES, PurchaseCreditNoteResource);
        this._listApiService.set(Resource.CATALOGUES, CatalogueResource);
        this._listApiService.set(Resource.PRESENTATIONS, PresentationResource);
        this._listApiService.set(Resource.RESOURCE_ITEMS, CatalogueResourceItemResource);
        this._listApiService.set(Resource.RESOURCE_GROUPS, CatalogueResourceGroupResource);
        this._listApiService.set(Resource.SUPPLIER_PAYMENT_TERMS, SupplierPaymentTermResource);
        this._listApiService.set(Resource.EXPENSE_PAYMENT_METHOD, ExpensePaymentMethodResource);
        this._listApiService.set(Resource.EXPENSE_TYPES, ExpenseTypeResource);
        this._listApiService.set(Resource.EMPLOYEE_EXPENSES, EmployeeExpenseResource);
        this._listApiService.set(Resource.BRANDS, BrandResource);
        this._listApiService.set(Resource.INTEGRATION_MAPPINGS, IntegrationMappingResource);
        this._listApiService.set(Resource.BIN_TYPES, BinTypeResource);
        this._listApiService.set(Resource.BINS, BinResource);
        this._listApiService.set(Resource.INVOICES, InvoiceResource);
        this._listApiService.set(Resource.CUSTOM_PDF_TEMPLATES, CustomPdfTemplateResource);
        this._listApiService.set(Resource.SUPPLIER_GROUPS, SupplerGroupResource);
        this._listApiService.set(Resource.BUDGETS, BudgetResource);
    }
}
