import {Injectable} from '@angular/core';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {EmployeeExpenses, IEmployeeExpenses} from 'app/blocks/model/employee-expenses';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class EmployeeExpensesService extends EntityCrudService<IEmployeeExpenses> {
    constructor(http: HttpClient) {
        super(http, Resource.EMPLOYEE_EXPENSES, (obj?) => new EmployeeExpenses(obj));
    }

    public downloadPDF = (expenseId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/employee-expenses/${expenseId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    export = (ids: Set<number>, params?: any): Promise<HttpResponse<ArrayBuffer>> => {
        const requestParams = {ids: Array.from(ids).toString(), ...params};
        const options = createRequestOption(requestParams);
        return this.http
            .get(SERVER_API_URL + 'api/export/EMPLOYEE_EXPENSES', {
                params: options,
                responseType: 'arraybuffer',
                observe: 'response'
            })
            .toPromise();
    };

    bulkApprove = (ids: number[]): Promise<any> => {
        return this.http.post(SERVER_API_URL + 'api/employee-expenses/approve', Array.from(ids), {observe: 'response'}).toPromise();
    };

    bulkReject = (ids: number[]): Promise<any> => {
        return this.http.post(SERVER_API_URL + 'api/employee-expenses/reject', Array.from(ids), {observe: 'response'}).toPromise();
    };
}
