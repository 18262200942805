import {RouteMapSymbol} from 'app/blocks/model/route-map-symbol.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IRouteMapSymbol} from 'app/blocks/model/route-map-symbol.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class RouteMapSymbolService extends EntityCrudService<IRouteMapSymbol> {
    constructor(http: HttpClient) {
        super(http, Resource.ROUTE_MAP_SYMBOLS, (obj?) => new RouteMapSymbol(obj));
    }
}
