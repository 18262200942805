import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IOrderItemReasonGroup} from 'app/blocks/model/order-item-reason-group.model';
export interface IOrderItemReason extends IBaseEntity {
    id?: number;
    code?: string;
    reason?: string;
    sequence?: number;
    isDefaultInGroup?: boolean;
    active?: boolean;
    group?: IOrderItemReasonGroup;
}
export class OrderItemReason extends BaseEntity implements IOrderItemReason {
    public id?: number;
    public code?: string;
    public reason?: string;
    public sequence?: number;
    public isDefaultInGroup?: boolean;
    public active?: boolean;
    public group?: IOrderItemReasonGroup;

    constructor(orderItemReason?) {
        super();
        if (orderItemReason) {
            this.id = orderItemReason.id;
            this.code = orderItemReason.code;
            this.reason = orderItemReason.reason;
            this.sequence = orderItemReason.sequence;
            this.isDefaultInGroup = orderItemReason.isDefaultInGroup;
            this.active = orderItemReason.active;
            this.group = orderItemReason.group;
        }
    }
    get discriminator(): any {
        return this.code;
    }
    clone(): OrderItemReason {
        return new OrderItemReason(this);
    }
}
