import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ListProductPriceService} from 'app/blocks/service/api/list-product-price.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IListProductPrice} from 'app/blocks/model/list-product-price.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ListProductPrice} from 'app/blocks/model/list-product-price.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ListProductPriceResource extends AbstractEntityResource<IListProductPrice> {
    constructor(listProductPriceService: ListProductPriceService, router: Router) {
        super(
            Resource.LIST_PRODUCT_PRICES,
            listProductPriceService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.LIST_PRODUCT_PRICES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.LIST_PRODUCT_PRICES),
            (entity?) => new ListProductPrice(entity),
            (entity) => entity.price
        );
    }
}
