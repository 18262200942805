import {PriceListType} from 'app/blocks/model/price-list-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPriceListType} from 'app/blocks/model/price-list-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PriceListTypeService extends EntityCrudService<IPriceListType> {
    constructor(http: HttpClient) {
        super(http, Resource.PRICE_LIST_TYPES, (obj?) => new PriceListType(obj));
    }
}
