import {IDailyActivitySummary, DailyActivitySummary} from 'app/blocks/model/daily-activity-summary.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Bank, IBank} from 'app/blocks/model/bank.model';

@Injectable({
    providedIn: 'root'
})
export class DailyActivitySummaryService extends EntityCrudService<IDailyActivitySummary> {
    constructor(http: HttpClient) {
        super(http, Resource.DAILY_ACTIVITY_SUMMARIES, (obj?) => new DailyActivitySummary(obj));
    }
}
