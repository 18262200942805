import {RouteType} from 'app/blocks/model/route-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IRouteType} from 'app/blocks/model/route-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class RouteTypeService extends EntityCrudService<IRouteType> {
    constructor(http: HttpClient) {
        super(http, Resource.ROUTE_TYPES, (obj?) => new RouteType(obj));
    }
}
