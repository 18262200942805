import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IStockChangeCondition extends IBaseEntity {
    id: number;
    date: Date;
    productCode: string;
    productName: string;
    oldProductCondition: string;
    newProductCondition: string;
    quanityChanged: number;
    driverName: string;
}

export class StockChangeCondition extends BaseEntity implements IStockChangeCondition {
    public id: number;
    public date: Date;
    public productCode: string;
    public productName: string;
    public oldProductCondition: string;
    public newProductCondition: string;
    public quanityChanged: number;
    public driverName: string;

    constructor(stockChangeCondition?) {
        super();
        if (stockChangeCondition) {
            this.id = stockChangeCondition.id;
            this.date = stockChangeCondition.date;
            this.productCode = stockChangeCondition.productCode;
            this.productName = stockChangeCondition.productName;
            this.oldProductCondition = stockChangeCondition.oldProductCondition;
            this.newProductCondition = stockChangeCondition.newProductCondition;
            this.quanityChanged = stockChangeCondition.quanityChanged;
            this.driverName = stockChangeCondition.driverName;
        } else {
        }
    }
    clone(): StockChangeCondition {
        return new StockChangeCondition(this);
    }
}
