import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IDynamicHeaderCode extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;

    header?: any;
}

export class DynamicHeaderCode extends BaseEntity implements IDynamicHeaderCode {
    public id?: number;

    public code?: string;

    public description?: string;

    public header?: any;

    constructor(dynamicHeaderCode?) {
        super();
        if (dynamicHeaderCode) {
            this.id = dynamicHeaderCode.id;

            this.code = dynamicHeaderCode.code;

            this.description = dynamicHeaderCode.description;

            this.header = dynamicHeaderCode.header;
        } else {
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): DynamicHeaderCode {
        return new DynamicHeaderCode(this);
    }
}
