import {DunningMessageCode} from 'app/blocks/model/dunning-message-code.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDunningMessageCode} from 'app/blocks/model/dunning-message-code.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DunningMessageCodeService extends EntityCrudService<IDunningMessageCode> {
    constructor(http: HttpClient) {
        super(http, Resource.DUNNING_MESSAGE_CODES, (obj?) => new DunningMessageCode(obj));
    }
}
