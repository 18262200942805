import {VehicleType} from 'app/blocks/model/vehicle-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IVehicleType} from 'app/blocks/model/vehicle-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class VehicleTypeService extends EntityCrudService<IVehicleType> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLE_TYPES, (obj?) => new VehicleType(obj));
    }
}
