import {HandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IHandheldProfile} from 'app/blocks/model/handheld-profile.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class HandheldProfileService extends EntityCrudService<IHandheldProfile> {
    constructor(http: HttpClient) {
        super(http, Resource.HANDHELD_PROFILES, (obj?) => new HandheldProfile(obj));
    }

    getDefaultProfile = (): Promise<any> => {
        return this.http
            .get<any>(SERVER_API_URL + 'api/handheld-profiles?name=Default Profile')
            .toPromise()
            .then((response) => this._entityCreator(response[0]));
    };
}
