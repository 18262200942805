import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {InventoryReasonService} from 'app/blocks/service/api/inventory-reason.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IInventoryReason} from 'app/blocks/model/inventory-reason.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {InventoryReason} from 'app/blocks/model/inventory-reason.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class InventoryReasonResource extends AbstractEntityResource<IInventoryReason> {
    constructor(inventoryReasonService: InventoryReasonService, router: Router) {
        super(
            Resource.INVENTORY_REASONS,
            inventoryReasonService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.INVENTORY_REASONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.INVENTORY_REASONS),
            (entity?) => new InventoryReason(entity),
            (entity) => entity.code
        );
    }
}
