import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {ITenant, Tenant} from 'app/blocks/model/tenant.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class TenantService extends EntityCrudService<ITenant> {
    constructor(http: HttpClient) {
        super(http, Resource.TENANTS, (obj?) => new Tenant(obj));
    }

    public createTenant = (tenant: any): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/super-admin/tenants`, tenant, {
                observe: 'response'
            })
            .toPromise();
    };

    public refreshTenant = (): Promise<HttpResponse<any>> => {
        return this.http
            .get<any>(SERVER_API_URL + `api/super-admin/tenants/tenant-details/refresh`, {
                observe: 'response'
            })
            .toPromise();
    };

    public saveTenant = (tenant: any): Promise<HttpResponse<any>> => {
        return this.http
            .put<any>(SERVER_API_URL + `api/super-admin/tenants`, tenant, {
                observe: 'response'
            })
            .toPromise();
    };

    public activateTenant = (tenant: any): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/super-admin/tenants/activate`, tenant, {
                observe: 'response'
            })
            .toPromise();
    };

    public deactivateTenant = (tenant: any): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/super-admin/tenants/deactivate`, tenant, {
                observe: 'response'
            })
            .toPromise();
    };
}
