import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HandheldProfileService} from 'app/blocks/service/api/handheld-profile.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IHandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {HandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class HandheldProfileResource extends AbstractEntityResource<IHandheldProfile> {
    constructor(handheldProfileService: HandheldProfileService, router: Router) {
        super(
            Resource.HANDHELD_PROFILES,
            handheldProfileService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.HANDHELD_PROFILES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.HANDHELD_PROFILES),
            (entity?) => new HandheldProfile(entity),
            (entity) => entity.name,
            'mobile_friendly'
        );
    }
}
