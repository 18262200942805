import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {BranchPayment, IBranchPayment} from 'app/blocks/model/branch-payment.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class BranchPaymentService extends EntityCrudService<IBranchPayment> {
    constructor(http: HttpClient) {
        super(http, Resource.BRANCH_PAYMENTS, (obj?) => new BranchPayment(obj));
    }

    approveBranchPayment = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/branch-payments/' + id + '/approve', null, {observe: 'response'}).toPromise();
    };

    downloadBranchPaymentDataPDFs = (branchPaymentIds: number[]): any => {
        const requestParams = new HttpParams().set('ids', branchPaymentIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/branch-payments/download-PDF`;
        return this.http.get(REQUEST_URI, {
            responseType: 'arraybuffer',
            params: requestParams
        });
    };
}
