import {ListProductPrice} from 'app/blocks/model/list-product-price.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IListProductPrice} from 'app/blocks/model/list-product-price.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ListProductPriceService extends EntityCrudService<IListProductPrice> {
    constructor(http: HttpClient) {
        super(http, Resource.LIST_PRODUCT_PRICES, (obj?) => new ListProductPrice(obj));
    }
}
