import {TrailerType} from 'app/blocks/model/trailer-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ITrailerType} from 'app/blocks/model/trailer-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class TrailerTypeService extends EntityCrudService<ITrailerType> {
    constructor(http: HttpClient) {
        super(http, Resource.TRAILER_TYPES, (obj?) => new TrailerType(obj));
    }
}
