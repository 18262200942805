import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeliveryPointMessageTypeService} from 'app/blocks/service/api/delivery-point-message-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDeliveryPointMessageType, DeliveryPointMessageType} from 'app/blocks/model/delivery-point-message-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DeliveryPointMessageTypeResource extends AbstractEntityResource<IDeliveryPointMessageType> {
    constructor(deliveryPointMessageTypeService: DeliveryPointMessageTypeService, router: Router) {
        super(
            Resource.DELIVERY_POINT_MESSAGE_TYPES,
            deliveryPointMessageTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DELIVERY_POINT_MESSAGE_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DELIVERY_POINT_MESSAGE_TYPES),
            (entity?) => new DeliveryPointMessageType(entity),
            (entity) => entity.type
        );
    }
}
