import {IInventoryReasonGroup} from 'app/blocks/model/inventory-reason-group.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IInventoryReason extends IBaseEntity {
    id?: number;
    code?: string;
    reason?: string;
    sequence?: number;
    isDefaultInGroup?: boolean;
    active?: boolean;
    group?: IInventoryReasonGroup;
}

export class InventoryReason extends BaseEntity implements IInventoryReason {
    public id?: number;
    public code?: string;
    public reason?: string;
    public sequence?: number;
    public isDefaultInGroup?: boolean;
    public active?: boolean;
    public group?: IInventoryReasonGroup;
    constructor(inventoryReason?) {
        super();
        if (inventoryReason) {
            this.id = inventoryReason.id;
            this.code = inventoryReason.code;
            this.reason = inventoryReason.reason;
            this.sequence = inventoryReason.sequence;
            this.isDefaultInGroup = inventoryReason.isDefaultInGroup;
            this.active = inventoryReason.active;
            this.group = inventoryReason.group;
        } else {
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): InventoryReason {
        return new InventoryReason(this);
    }
}
