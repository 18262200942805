import {CustomerStatus} from 'app/blocks/model/customer-status.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICustomerStatus} from 'app/blocks/model/customer-status.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class CustomerStatusService extends EntityCrudService<ICustomerStatus> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOMER_STATUSES, (obj?) => new CustomerStatus(obj));
    }
}
