import {CustomerSmsText} from 'app/blocks/model/customer-sms-text.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICustomerSmsText} from 'app/blocks/model/customer-sms-text.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class CustomerSmsTextService extends EntityCrudService<ICustomerSmsText> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOMER_SMS_TEXTS, (obj?) => new CustomerSmsText(obj));
    }
}
