import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DynamicHeaderCodeService} from 'app/blocks/service/api/dynamic-header-code.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDynamicHeaderCode} from 'app/blocks/model/dynamic-header-code.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DynamicHeaderCode} from 'app/blocks/model/dynamic-header-code.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DynamicHeaderCodeResource extends AbstractEntityResource<IDynamicHeaderCode> {
    constructor(dynamicHeaderCodeService: DynamicHeaderCodeService, router: Router) {
        super(
            Resource.DYNAMIC_HEADER_CODES,
            dynamicHeaderCodeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DYNAMIC_HEADER_CODES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DYNAMIC_HEADER_CODES),
            (entity?) => new DynamicHeaderCode(entity),
            (entity) => entity.code
        );
    }
}
