import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ISiteVisitSkipReasons extends IBaseEntity {
    id?: number;
    code?: string;
    reason?: string;
    mposSequence?: number;
    isDefaultInGroup?: boolean;
    active?: boolean;
    siteVisitStatusReasonGroup?: any;
}

export class SiteVisitSkipReasons extends BaseEntity implements ISiteVisitSkipReasons {
    id?: number;
    code?: string;
    reason?: string;
    mposSequence?: number;
    isDefaultInGroup?: boolean;
    active?: boolean;
    siteVisitStatusReasonGroup?: any;

    constructor(siteVisitSkipReasons?) {
        super();
        if (siteVisitSkipReasons) {
            this.id = siteVisitSkipReasons.id;
            this.code = siteVisitSkipReasons.code;
            this.reason = siteVisitSkipReasons.reason;
            this.mposSequence = siteVisitSkipReasons.mposSequence;
            this.isDefaultInGroup = siteVisitSkipReasons.isDefaultInGroup;
            this.active = siteVisitSkipReasons.active;
            this.siteVisitStatusReasonGroup = siteVisitSkipReasons.siteVisitStatusReasonGroup;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): SiteVisitSkipReasons {
        return new SiteVisitSkipReasons(this);
    }
}
