import {IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IProductAttributeType extends IBaseEntity {
    id?: number;

    name?: string;

    attributes?: string[];
}

export class ProductAttributeType extends BaseFranchiseEntity implements IProductAttributeType {
    public id?: number;

    public name?: string;

    public attributes?: string[];

    constructor(attributeType?) {
        super();
        if (attributeType) {
            this.id = attributeType.id;

            this.name = attributeType.name;

            if (attributeType.attributes) {
                this.attributes = attributeType.attributes;
            }

            if (attributeType.editable !== undefined) {
                this.editable = attributeType.editable;
            }
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): ProductAttributeType {
        return new ProductAttributeType(this);
    }
}
