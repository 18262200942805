import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {CatalogueResourceGroup, ICatalogueResourceGroup} from 'app/blocks/model/resource-group.model';
import {CatalogueResourceGroupService} from 'app/blocks/service/api/resource-group.service';

@Injectable({
    providedIn: 'root'
})
export class CatalogueResourceGroupResource extends AbstractEntityResource<ICatalogueResourceGroup> {
    constructor(resourceGroupService: CatalogueResourceGroupService, router: Router) {
        super(
            Resource.RESOURCE_GROUPS,
            resourceGroupService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.RESOURCE_GROUPS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.RESOURCE_GROUPS),
            (entity?) => new CatalogueResourceGroup(entity),
            (entity) => entity.id
        );
    }
}
