import {OrderRejectionCode} from 'app/blocks/model/order-rejection-code.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IOrderRejectionCode} from 'app/blocks/model/order-rejection-code.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class OrderRejectionCodeService extends EntityCrudService<IOrderRejectionCode> {
    constructor(http: HttpClient) {
        super(http, Resource.ORDER_REJECTION_CODES, (obj?) => new OrderRejectionCode(obj));
    }
}
