import {OrderPriority} from 'app/blocks/model/order-priority.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IOrderPriority} from 'app/blocks/model/order-priority.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class OrderPriorityService extends EntityCrudService<IOrderPriority> {
    constructor(http: HttpClient) {
        super(http, Resource.ORDER_PRIORITIES, (obj?) => new OrderPriority(obj));
    }
}
