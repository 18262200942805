import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ISurveyReport, SurveyReport} from 'app/blocks/model/survey-report.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class SurveyReportService extends EntityCrudService<ISurveyReport> {
    constructor(http: HttpClient) {
        super(http, Resource.SURVEY_REPORT, (obj?) => new SurveyReport(obj));
    }

    public downloadSurveyReport = (surveyReportId: number): any => {
        const type = 'arraybuffer';
        const REQUEST_URI = SERVER_API_URL + `api/survey-report/${surveyReportId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: type as 'json'});
    };
}
