import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ICatalogueResourceItem} from 'app/blocks/model/resource-item.model';

export interface ICatalogueResourceGroup extends IBaseEntity {
    id?: number;
    name?: string;
    resources?: ICatalogueResourceItem[];
}

export class CatalogueResourceGroup extends BaseEntity implements ICatalogueResourceGroup {
    public id?: number;
    public name?: string;
    public resources?: ICatalogueResourceItem[];

    constructor(resource?: any) {
        super();
        if (resource) {
            this.id = resource.id;
            this.name = resource.name;
            this.resources = resource.resources ?? [];
        }
    }

    clone(): IBaseEntity {
        return new CatalogueResourceGroup(this);
    }
}
