import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IBranch} from 'app/blocks/model/branch.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IBranchPaymentCurrencyDenomination} from 'app/blocks/model/branch-payment-currency-denomination.model';

export interface IBranchPayment extends IBaseFranchiseEntity {
    id?: number;
    reference?: string;
    branch?: IBranch;
    depositedBy?: IEmployee;
    paymentDate?: any;
    paymentTime?: any;
    cashAmount?: number;
    chequeAmount?: number;
    bankPaymentAmount?: number;
    creditCardAmount?: number;
    totalAmount?: number;
    cashReference?: string;
    chequeReference?: string;
    bankPaymentReference?: string;
    creditCardReference?: string;
    branchPaymentStatus?: string;
    branchPaymentCurrencyDenominations?: IBranchPaymentCurrencyDenomination[];
}

export class BranchPayment extends BaseFranchiseEntity implements IBranchPayment {
    public id: number;
    public reference: string;
    public branch: IBranch;
    public depositedBy: IEmployee;
    public paymentDate: any;
    public paymentTime: any;
    public cashAmount: number;
    public chequeAmount: number;
    public bankPaymentAmount: number;
    public creditCardAmount: number;
    public totalAmount: number;
    public cashReference: string;
    public chequeReference: string;
    public bankPaymentReference: string;
    public creditCardReference: string;
    public branchPaymentStatus: string;
    public branchPaymentCurrencyDenominations?: IBranchPaymentCurrencyDenomination[];

    constructor(branchPayment?) {
        super();
        if (branchPayment) {
            this.id = branchPayment.id;
            this.reference = branchPayment.reference;
            this.branch = branchPayment.branch;
            this.depositedBy = branchPayment.depositedBy;
            this.paymentDate = branchPayment.paymentDate;
            this.paymentTime = branchPayment.paymentTime;
            this.cashAmount = branchPayment.cashAmount;
            this.chequeAmount = branchPayment.chequeAmount;
            this.bankPaymentAmount = branchPayment.bankPaymentAmount;
            this.creditCardAmount = branchPayment.creditCardAmount;
            this.totalAmount = branchPayment.totalAmount;
            this.cashReference = branchPayment.cashReference;
            this.chequeReference = branchPayment.chequeReference;
            this.bankPaymentReference = branchPayment.bankPaymentReference;
            this.creditCardReference = branchPayment.creditCardReference;
            this.branchPaymentStatus = branchPayment.branchPaymentStatus;
            this.branchPaymentCurrencyDenominations = branchPayment.branchPaymentCurrencyDenominations;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): BranchPayment {
        return new BranchPayment(this);
    }
}
