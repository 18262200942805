import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {CustomFieldGroup, ICustomFieldGroup} from 'app/blocks/model/custom-field-group.model';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldGroupService extends EntityCrudService<ICustomFieldGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOM_FIELD_GROUPS, (obj?) => new CustomFieldGroup(obj));
    }
}
