import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IDepositItem extends IBaseEntity {
    id?: number;

    name?: string;

    depositPrice?: number;
}

export class DepositItem extends BaseEntity implements IDepositItem {
    public id?: number;

    public name?: string;

    public depositPrice?: number;

    constructor(depositItem?) {
        super();
        if (depositItem) {
            this.id = depositItem.id;

            this.name = depositItem.name;

            this.depositPrice = depositItem.depositPrice;
        } else {
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): DepositItem {
        return new DepositItem(this);
    }
}
