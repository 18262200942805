import {IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IHandheldProfile extends IBaseEntity {
    id: number;
    name?: string;
    description?: string;
    writeSettings?: any;
    readSettings?: any[];
    selectedSettingOptionsValues?: any;
}

export class HandheldProfile extends BaseFranchiseEntity implements IHandheldProfile {
    public id;
    public name;
    public description;
    public writeSettings: any;
    public readSettings: any[];
    public selectedSettingOptionsValues: any;

    constructor(handheldProfile?) {
        super();
        if (handheldProfile) {
            this.id = handheldProfile.id;
            this.name = handheldProfile.name;
            this.description = handheldProfile.description;
            this.writeSettings = handheldProfile.writeSettings;
            this.readSettings = handheldProfile.readSettings || [];
            this.selectedSettingOptionsValues = handheldProfile.selectedSettingOptionsValues || {};
            this.editable = handheldProfile.editable;
        } else {
            this.readSettings = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): HandheldProfile {
        return new HandheldProfile(this);
    }
}
