import {ServicePriorityCode} from 'app/blocks/model/service-priority-code.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IServicePriorityCode} from 'app/blocks/model/service-priority-code.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ServicePriorityCodeService extends EntityCrudService<IServicePriorityCode> {
    constructor(http: HttpClient) {
        super(http, Resource.SERVICE_PRIORITY_CODES, (obj?) => new ServicePriorityCode(obj));
    }
}
