import {FuelSurchargeGroup, IFuelSurchargeGroup} from 'app/blocks/model/fuel-surcharge-group.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class FuelSurchargeGroupService extends EntityCrudService<IFuelSurchargeGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.FUEL_SURCHARGE_GROUPS, (obj?) => new FuelSurchargeGroup(obj));
    }
}
