import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {Brand, IBrand} from 'app/blocks/model/brand.model';
import {BrandService} from 'app/blocks/service/api/brand.service';

@Injectable({
    providedIn: 'root'
})
export class BrandResource extends AbstractEntityResource<IBrand> {
    constructor(brandService: BrandService, router: Router) {
        super(
            Resource.BRANDS,
            brandService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.BRANDS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.BRANDS),
            (entity?) => new Brand(entity),
            (entity) => entity.id
        );
    }
}
