import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SUPER_ADMIN_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ExecutionService} from 'app/blocks/service/api/execution.service';
import {Execution, IExecution} from 'app/blocks/model/execution.model';

@Injectable({
    providedIn: 'root'
})
export class ExecutionResource extends AbstractEntityResource<IExecution> {
    constructor(executionService: ExecutionService, router: Router) {
        super(
            Resource.EXECUTIONS,
            executionService,
            new EntityNavigator(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.EXECUTIONS, router),
            new EntityUrlProvider(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.EXECUTIONS),
            (entity?) => new Execution(entity),
            (entity) => entity.name,
            'share'
        );
    }
}
