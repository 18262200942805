import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DunningMessageCodeService} from 'app/blocks/service/api/dunning-message-code.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDunningMessageCode} from 'app/blocks/model/dunning-message-code.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DunningMessageCode} from 'app/blocks/model/dunning-message-code.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DunningMessageCodeResource extends AbstractEntityResource<IDunningMessageCode> {
    constructor(dunningMessageCodeService: DunningMessageCodeService, router: Router) {
        super(
            Resource.DUNNING_MESSAGE_CODES,
            dunningMessageCodeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DUNNING_MESSAGE_CODES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DUNNING_MESSAGE_CODES),
            (entity?) => new DunningMessageCode(entity),
            (entity) => entity.code,
            'message'
        );
    }
}
