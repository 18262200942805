import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IWarehouseType extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;
}

export class WarehouseType extends BaseEntity implements IWarehouseType {
    public id?: number;

    public code?: string;

    public description?: string;

    constructor(warehouseType?) {
        super();
        warehouseType = warehouseType || {};
        if (warehouseType) {
            this.id = warehouseType.id;

            this.code = warehouseType.code;

            this.description = warehouseType.description;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): WarehouseType {
        return new WarehouseType(this);
    }
}
