import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';
import {HISTORY_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ShortfallReportService} from 'app/blocks/service/api/shortfall-report.service';
import {IShortfallReport, ShortfallReport} from 'app/blocks/model/shortfall-report.model';

@Injectable({
    providedIn: 'root'
})
export class ShortfallReportResource extends AbstractEntityResource<IShortfallReport> {
    constructor(shortfallReportService: ShortfallReportService, router: Router) {
        super(
            Resource.SHORTFALL_REPORT,
            shortfallReportService,
            new EntityNavigator(HISTORY_BASE_URL, Resource.SHORTFALL_REPORT, router),
            new EntityUrlProvider(HISTORY_BASE_URL, Resource.SHORTFALL_REPORT),
            (entity?) => new ShortfallReport(entity),
            (entity) => entity.name,
            'shortfall'
        );
    }
}
