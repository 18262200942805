import {IStockChangeCondition, StockChangeCondition} from 'app/blocks/model/stock-change-condition.model';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';
import {HISTORY_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {StockChangeConditionService} from 'app/blocks/service/api/stock-change-condition.service';

@Injectable({
    providedIn: 'root'
})
export class StockChangeConditionResource extends AbstractEntityResource<IStockChangeCondition> {
    constructor(stockChangeConditionService: StockChangeConditionService, router: Router) {
        super(
            Resource.STOCK_CHANGE_CONDITION,
            stockChangeConditionService,
            new EntityNavigator(HISTORY_BASE_URL, Resource.STOCK_CHANGE_CONDITION, router),
            new EntityUrlProvider(HISTORY_BASE_URL, Resource.STOCK_CHANGE_CONDITION),
            (entity?) => new StockChangeCondition(entity),
            (entity) => entity.name,
            'inventory'
        );
    }
}
