import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IStopType extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;

    color?: string;

    averageServiceTimeInMins?: number;
}

export class StopType extends BaseEntity implements IStopType {
    public id?: number;

    public code?: string;

    public description?: string;

    public color?: string;

    public averageServiceTimeInMins?: number;

    constructor(stopType?) {
        super();
        if (stopType) {
            this.id = stopType.id;

            this.code = stopType.code;

            this.description = stopType.description;

            this.color = stopType.color;

            this.averageServiceTimeInMins = stopType.averageServiceTimeInMins;
        } else {
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): StopType {
        return new StopType(this);
    }
}
