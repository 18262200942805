import {OrderStatusReason} from 'app/blocks/model/order-status-reason.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IOrderStatusReason} from 'app/blocks/model/order-status-reason.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class OrderStatusReasonService extends EntityCrudService<IOrderStatusReason> {
    constructor(http: HttpClient) {
        super(http, Resource.ORDER_STATUS_REASONS, (obj?) => new OrderStatusReason(obj));
    }
}
