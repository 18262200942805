import {CustomerStatusReasonGroup} from 'app/blocks/model/customer-status-reason-group.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICustomerStatusReasonGroup} from 'app/blocks/model/customer-status-reason-group.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class CustomerStatusReasonGroupService extends EntityCrudService<ICustomerStatusReasonGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOMER_STATUS_REASON_GROUPS, (obj?) => new CustomerStatusReasonGroup(obj));
    }
}
