import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {InvoiceService} from 'app/blocks/service/api/invoice.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IInvoice} from 'app/blocks/model/invoice.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Invoice} from 'app/blocks/model/invoice.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class InvoiceResource extends AbstractEntityResource<IInvoice> {
    constructor(invoiceService: InvoiceService, router: Router) {
        super(
            Resource.INVOICES,
            invoiceService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.INVOICES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.INVOICES),
            (entity?) => new Invoice(entity),
            (entity) => entity.invoiceNo,
            'request_quote'
        );
    }
}
