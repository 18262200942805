import {IDeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {IRoute} from 'app/blocks/model/route.model';

import {DiTime} from 'app/blocks/util/di-time';
import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {IDeliveryMethod} from 'app/blocks/model/delivery-method.model';
import {DeliveryPoint, IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {Discount, IDiscount} from 'app/blocks/model/discount.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {Mposfile} from 'app/blocks/model/mpos-file.model';
import {INote} from 'app/blocks/model/note.model';
import {IOrderItem} from 'app/blocks/model/order-item.model';
import {IOrderPriority} from 'app/blocks/model/order-priority.model';
import {IOrderStatusReason} from 'app/blocks/model/order-status-reason.model';
import {ITimeWindow} from 'app/blocks/model/time-window.model';
import {Note} from 'app/blocks/model/note.model';
import {SaleOrderStatus} from 'app/blocks/model/sale-order-status';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {Promotion} from 'app/blocks/model/promotion.model';
import {IPaymentType} from 'app/blocks/model/payment-type.model';
import {IVehicleSkill} from 'app/blocks/model/vehicle-skill.model';

export interface ISaleOrder extends IBaseFranchiseEntity {
    id?: number;
    saleOrderNo?: string;
    sourceDocumentNo?: string;
    requestDate?: any;
    requestTime?: any;
    scheduledDate?: any;
    scheduledTime?: any;
    approved?: boolean;
    isBulkPick?: boolean;
    discount?: IDiscount;
    tax?: number;
    discountedTax?: number;
    subTotal?: number;
    discountedSubTotal?: number;
    total?: number;
    deliveryPoint?: IDeliveryPoint;
    customer?: ICustomer;
    type?: any;
    deliveryMethod?: IDeliveryMethod;
    route?: IRoute;
    priority?: IOrderPriority;
    statusReason?: IOrderStatusReason;
    deliveryAddress?: IContactInfo;
    requestedTimeWindows?: ITimeWindow[];
    orderItems?: IOrderItem[];
    packages?: IDeliveryPackage[];
    requestTakenBy?: IEmployee;
    saleOrderStatus?: SaleOrderStatus;
    orderDocuments?: Mposfile[];
    paymentType?: IPaymentType;
    notes?: INote;
    internalNotes?: INote;
    partnerCommissionPercentage?: number;
    totalCommission?: number;
    shippingCost?: number;
    promotions?: Promotion[];
    isQuote?: boolean;
    quoteNotes?: INote;
    invoice?: any;
    sla?: number;
    requiredVehicleSkills?: IVehicleSkill[];
    serviceEquipmentItems?: any;
    approveOnCreateOrUpdate?: boolean | null;
    probability?: number;
}

export class SaleOrder extends BaseFranchiseEntity implements ISaleOrder {
    public id?: number;
    public saleOrderNo?: string;
    public sourceDocumentNo?: string;
    public requestDate?: any;
    public requestTime?: any;
    public scheduledDate?: any;
    public scheduledTime?: any;
    public approved?: boolean;
    public isBulkPick?: boolean;
    public discount?: IDiscount;
    public tax?: number;
    public discountedTax?: number;
    public subTotal?: number;
    public discountedSubTotal?: number;
    public total?: number;
    public type?: any;
    public deliveryMethod?: IDeliveryMethod;
    public route?: IRoute;
    public priority?: IOrderPriority;
    public statusReason?: IOrderStatusReason;
    public deliveryAddress?: IContactInfo;
    public requestedTimeWindows?: ITimeWindow[];
    public deliveryPoint?: IDeliveryPoint;
    public customer?: ICustomer;
    public orderItems?: IOrderItem[];
    public packages?: IDeliveryPackage[];
    public requestTakenBy?: IEmployee;
    public saleOrderStatus?: SaleOrderStatus;
    public orderDocuments?: Mposfile[];
    public paymentType?: IPaymentType;
    public notes?: INote;
    public internalNotes?: INote;
    public partnerCommissionPercentage?: number;
    public totalCommission?: number;
    public shippingCost?: number;
    public promotions?: Promotion[];
    public isQuote?: boolean;
    public quoteNotes?: INote;
    public invoice?: any;
    public sla?: number;
    public requiredVehicleSkills?: IVehicleSkill[];
    public serviceEquipmentItems?: any;
    public approveOnCreateOrUpdate?: boolean | null;
    public probability?: number;

    constructor(saleOrder?) {
        super();
        if (saleOrder) {
            this.id = saleOrder.id;
            this.saleOrderNo = saleOrder.saleOrderNo;
            this.sourceDocumentNo = saleOrder.sourceDocumentNo;
            this.requestDate = MposDate.newInstance(saleOrder.requestDate);
            this.requestTime = DiTime.newInstance(saleOrder.requestTime);
            this.scheduledDate = MposDate.newInstance(saleOrder.scheduledDate);
            this.scheduledTime = DiTime.newInstance(saleOrder.scheduledTime);
            this.approved = saleOrder.approved;
            this.isBulkPick = saleOrder.isBulkPick;
            this.discount = saleOrder.discount || {
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            };
            this.tax = saleOrder.tax;
            this.discountedTax = saleOrder.discountedTax;
            this.shippingCost = saleOrder.shippingCost;
            this.subTotal = saleOrder.subTotal;
            this.discountedSubTotal = saleOrder.discountedSubTotal;
            this.total = saleOrder.total;
            this.type = saleOrder.type;
            this.deliveryMethod = saleOrder.deliveryMethod;
            this.route = saleOrder.route;
            this.customer = saleOrder.customer;
            this.priority = saleOrder.priority;
            this.statusReason = saleOrder.statusReason;
            this.deliveryPoint = saleOrder.deliveryPoint;
            this.deliveryAddress = new ContactInfo(saleOrder.deliveryAddress);
            this.requestedTimeWindows = saleOrder.requestedTimeWindows || [];
            this.orderItems = saleOrder.orderItems || [];
            this.packages = saleOrder.packages || [];
            this.requestTakenBy = saleOrder.requestTakenBy;
            this.saleOrderStatus = saleOrder.saleOrderStatus;
            this.orderDocuments = saleOrder.orderDocuments || [];
            this.paymentType = saleOrder.paymentType;
            this.notes = saleOrder.notes || new Note();
            this.internalNotes = saleOrder.internalNotes || new Note();
            this.totalCommission = saleOrder.totalCommission;
            this.partnerCommissionPercentage = saleOrder.partnerCommissionPercentage;
            this.assignedFranchise = saleOrder.assignedFranchise;
            this.editable = saleOrder.editable;
            this.promotions = saleOrder.promotions;
            this.isQuote = saleOrder.isQuote;
            this.quoteNotes = saleOrder.quoteNotes || new Note();
            this.invoice = saleOrder.invoice;
            this.sla = saleOrder.sla;
            this.requiredVehicleSkills = saleOrder.requiredVehicleSkills || [];
            this.serviceEquipmentItems = saleOrder.serviceEquipmentItems;
            this.approveOnCreateOrUpdate = saleOrder.approveOnCreateOrUpdate;
            this.probability = saleOrder.probability;
        } else {
            this.deliveryAddress = new ContactInfo();
            // FIXME: Why this next line?
            this.deliveryPoint = new DeliveryPoint();
            this.requestedTimeWindows = [];
            this.orderItems = [];
            this.packages = [];
            this.requestDate = MposDate.currentDate();
            this.requestTime = DiTime.currentTime();
            this.saleOrderStatus = SaleOrderStatus.PENDING;
            this.discount = new Discount({
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            });
            this.notes = new Note();
            this.internalNotes = new Note();
            this.quoteNotes = new Note();
        }
    }
    get discriminator(): any {
        return this.saleOrderNo;
    }
    clone(): SaleOrder {
        return new SaleOrder(this);
    }
}
