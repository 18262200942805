import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DurationPriceListService} from 'app/blocks/service/api/duration-price-list.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDurationPriceList} from 'app/blocks/model/duration-price-list.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DurationPriceList} from 'app/blocks/model/duration-price-list.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DurationPriceListResource extends AbstractEntityResource<IDurationPriceList> {
    constructor(durationPriceListService: DurationPriceListService, router: Router) {
        super(
            Resource.DURATION_PRICE_LISTS,
            durationPriceListService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DURATION_PRICE_LISTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DURATION_PRICE_LISTS),
            (entity?) => new DurationPriceList(entity),
            (entity) => entity.code
        );
    }
}
