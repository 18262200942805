import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IEquipment, Equipment} from 'app/blocks/model/equipment.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class EquipmentService extends EntityCrudService<IEquipment> {
    constructor(http: HttpClient) {
        super(http, Resource.EQUIPMENTS, (obj?) => new Equipment(obj));
    }

    public serviceOrderForEquipment = (id: any): Promise<HttpResponse<any>> => {
        return this.http
            .get<any>(SERVER_API_URL + `api/equipment-service-order/${id}`, {
                observe: 'response'
            })
            .toPromise();
    };
}
