import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IBinType extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
}

export class BinType extends BaseEntity implements IBinType {
    id: number;
    name: string;
    description: string;

    constructor(binType?) {
        super();
        if (binType) {
            this.id = binType.id;
            this.name = binType.name;
            this.description = binType.description;
        } else {
        }
    }

    clone(): BinType {
        return new BinType(this);
    }
}
