import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {WorkshopService} from 'app/blocks/service/api/workshop.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IWorkshop} from 'app/blocks/model/workshop.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Workshop} from 'app/blocks/model/workshop.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class WorkshopResource extends AbstractEntityResource<IWorkshop> {
    constructor(workshopService: WorkshopService, router: Router) {
        super(
            Resource.WORKSHOP,
            workshopService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.WORKSHOP, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.WORKSHOP),
            (entity?) => new Workshop(entity),
            (entity) => entity.code,
            'location_city'
        );
    }
}
