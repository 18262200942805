import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SaleOrderService} from 'app/blocks/service/api/sale-order.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {SaleOrder} from 'app/blocks/model/sale-order.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IProductCostChange, ProductCostChange} from 'app/blocks/model/product-cost-change.model';
import {ProductCostChangeService} from 'app/blocks/service/api/product-cost-change.service';

@Injectable({
    providedIn: 'root'
})
export class ProductCostChangeResource extends AbstractEntityResource<IProductCostChange> {
    constructor(productCostChangeService: ProductCostChangeService, router: Router) {
        super(
            Resource.PRODUCT_COST_CHANGES,
            productCostChangeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_COST_CHANGES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_COST_CHANGES),
            (entity?) => new ProductCostChange(entity),
            (entity) => entity.id,
            'productCostChanges'
        );
    }
}
