import {DiTime} from 'app/blocks/util/di-time';
import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {DeliveryPoint, IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {Discount, IDiscount} from 'app/blocks/model/discount.model';
import {INote} from 'app/blocks/model/note.model';
import {IOrderItem} from 'app/blocks/model/order-item.model';
import {Note} from 'app/blocks/model/note.model';

export interface IClientSaleOrder extends IBaseEntity {
    id?: number;
    saleOrderNo?: string;
    scheduledDate?: any;
    scheduledTime?: any;
    discount?: IDiscount;
    tax?: number;
    subTotal?: number;
    total?: number;
    deliveryPoint?: IDeliveryPoint;
    deliveryAddress?: IContactInfo;
    orderItems?: IOrderItem[];
    saleOrderStatus?: string;
    notes?: INote;
    sourceDocumentNo?: string;
    type?: string;
    serviceEquipmentItems?: any;
    invoice?: any;
    createdDate?: any;
    creationTime?: any;
    poNumber?: string;
    podAvailable?: boolean;
}

export class ClientSaleOrder extends BaseEntity implements IClientSaleOrder {
    public id?: number;
    public saleOrderNo?: string;
    public scheduledDate?: any;
    public scheduledTime?: any;
    public discount?: IDiscount;
    public tax?: number;
    public subTotal?: number;
    public total?: number;
    public deliveryAddress?: IContactInfo;
    public deliveryPoint?: IDeliveryPoint;
    public orderItems?: IOrderItem[];
    public saleOrderStatus?: string;
    public notes?: INote;
    public sourceDocumentNo?: string;
    public type?: string;
    public requestDate?: any;
    public requestTime?: any;
    public serviceEquipmentItems?: any;
    public invoice?: any;
    public createdDate?: any;
    public creationTime?: any;
    public poNumber?: string;
    public podAvailable?: boolean;

    constructor(clientSaleOrder?) {
        super();
        if (clientSaleOrder) {
            this.id = clientSaleOrder.id;
            this.saleOrderNo = clientSaleOrder.saleOrderNo;
            this.scheduledDate = MposDate.newInstance(clientSaleOrder.scheduledDate);
            this.scheduledTime = DiTime.newInstance(clientSaleOrder.scheduledTime);
            this.discount = clientSaleOrder.discount || {
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            };
            this.tax = clientSaleOrder.tax;
            this.subTotal = clientSaleOrder.subTotal;
            this.total = clientSaleOrder.total;
            this.deliveryPoint = clientSaleOrder.deliveryPoint;
            this.deliveryAddress = new ContactInfo(clientSaleOrder.deliveryAddress);
            this.orderItems = clientSaleOrder.orderItems || [];
            this.saleOrderStatus = clientSaleOrder.saleOrderStatus;
            this.notes = clientSaleOrder.notes || new Note();
            this.sourceDocumentNo = clientSaleOrder.sourceDocumentNo;
            this.type = clientSaleOrder.type;
            this.requestDate = MposDate.newInstance(clientSaleOrder.requestDate);
            this.requestTime = DiTime.newInstance(clientSaleOrder.requestTime);
            this.serviceEquipmentItems = clientSaleOrder.serviceEquipmentItems || [];
            this.invoice = clientSaleOrder.invoice;
            this.createdDate = MposDate.newInstance(clientSaleOrder.createdDate);
            this.creationTime = DiTime.newInstance(clientSaleOrder.creationTime);
            this.poNumber = clientSaleOrder.poNumber;
            this.podAvailable = clientSaleOrder.podAvailable;
        } else {
            this.deliveryAddress = new ContactInfo();
            this.deliveryPoint = new DeliveryPoint();
            this.orderItems = [];
            this.saleOrderStatus = 'PENDING';
            this.discount = new Discount({
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            });
            this.notes = new Note();
        }
    }
    get discriminator(): any {
        return this.saleOrderNo;
    }
    clone(): ClientSaleOrder {
        return new ClientSaleOrder(this);
    }
}
