import {OrderItem} from 'app/blocks/model/order-item.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IOrderItem} from 'app/blocks/model/order-item.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class OrderItemService extends EntityCrudService<IOrderItem> {
    constructor(http: HttpClient) {
        super(http, Resource.ORDER_ITEMS, (obj?) => new OrderItem(obj));
    }
}
