import {StatementCode} from 'app/blocks/model/statement-code.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IStatementCode} from 'app/blocks/model/statement-code.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class StatementCodeService extends EntityCrudService<IStatementCode> {
    constructor(http: HttpClient) {
        super(http, Resource.STATEMENT_CODES, (obj?) => new StatementCode(obj));
    }
}
