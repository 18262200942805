import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IInventoryReasonGroup extends IBaseEntity {
    id?: number;

    code?: string;

    group?: string;

    sequence?: number;

    active?: boolean;
}

export class InventoryReasonGroup extends BaseEntity implements IInventoryReasonGroup {
    public id?: number;

    public code?: string;

    public group?: string;

    public sequence?: number;

    public active?: boolean;

    constructor(inventoryReasonGroup?) {
        super();
        if (inventoryReasonGroup) {
            this.id = inventoryReasonGroup.id;

            this.code = inventoryReasonGroup.code;

            this.group = inventoryReasonGroup.group;

            this.sequence = inventoryReasonGroup.sequence;

            this.active = inventoryReasonGroup.active;
        } else {
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): InventoryReasonGroup {
        return new InventoryReasonGroup(this);
    }
}
