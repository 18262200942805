import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IRestore, Restore} from 'app/blocks/model/restore.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class RestoreService extends EntityCrudService<IRestore> {
    constructor(http: HttpClient) {
        super(http, Resource.RESTORES, (obj?) => new Restore(obj));
    }

    public restore = (data: any): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/super-admin/restores`, data, {
                observe: 'response'
            })
            .toPromise();
    };
}
