export enum SaleOrderStatus {
    PENDING = 'PENDING',
    CONFIRMED_APPROVED = 'CONFIRMED_APPROVED',
    PICKED = 'PICKED',
    PACKED = 'PACKED',
    LOADED = 'LOADED',
    OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
    ON_HOLD = 'ON_HOLD',
    QUOTE = 'QUOTE',
    QUOTE_SENT_TO_CUSTOMER = 'QUOTE_SENT_TO_CUSTOMER',
    QUOTE_APPROVED_BY_CUSTOMER = 'QUOTE_APPROVED_BY_CUSTOMER',
    QUOTE_REJECTED_BY_CUSTOMER = 'QUOTE_REJECTED_BY_CUSTOMER'
}
