import {IVehicle} from 'app/blocks/model/vehicle.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ITrailerType} from 'app/blocks/model/trailer-type.model';

export interface ITrailer extends IBaseEntity {
    id?: number;
    trailerId?: string;
    description?: string;
    make?: string;
    model?: string;
    year?: number;
    defaultVehicle?: IVehicle;
    trailerType?: ITrailerType;
}

export class Trailer extends BaseEntity implements ITrailer {
    public id?: number;
    public trailerId?: string;
    public description?: string;
    public make?: string;
    public model?: string;
    public year?: number;
    public defaultVehicle?: IVehicle;
    public trailerType?: ITrailerType;

    constructor(trailer?) {
        super();
        if (trailer) {
            this.id = trailer.id;
            this.trailerId = trailer.trailerId;
            this.description = trailer.description;
            this.make = trailer.make;
            this.model = trailer.model;
            this.year = trailer.year;
            this.defaultVehicle = trailer.defaultVehicle;
            this.trailerType = trailer.trailerType;
        }
    }
    get discriminator(): any {
        return this.trailerId;
    }
    clone(): Trailer {
        return new Trailer(this);
    }
}
