import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SaleOrderService} from 'app/blocks/service/api/sale-order.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {SaleOrder} from 'app/blocks/model/sale-order.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class SaleOrderResource extends AbstractEntityResource<ISaleOrder> {
    constructor(saleOrderService: SaleOrderService, router: Router) {
        super(
            Resource.SALE_ORDERS,
            saleOrderService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SALE_ORDERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SALE_ORDERS),
            (entity?) => new SaleOrder(entity),
            (entity) => entity.saleOrderNo,
            'file_copy'
        );
    }
}
