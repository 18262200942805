import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IServiceItem extends IBaseEntity {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    price?: number;
    quantity?: number;
}

export class ServiceItem extends BaseEntity implements IServiceItem {
    public id?: number;
    public code?: string;
    public name?: string;
    public description?: string;
    public price?: number;
    public quantity?: number;

    constructor(serviceItem?) {
        super();
        if (serviceItem) {
            this.id = serviceItem.id;
            this.code = serviceItem.code;
            this.name = serviceItem.name;
            this.description = serviceItem.description;
            this.price = serviceItem.price;
            this.quantity = serviceItem.quantity;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): ServiceItem {
        return new ServiceItem(this);
    }
}
