import {IEmployee} from 'app/blocks/model/employee.model';
import {IRoute} from 'app/blocks/model/route.model';

import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IDailyActivitySummary extends IBaseEntity {
    id: number;
    date: Date;
    driver: IEmployee;
    firstName: string;
    lastName: string;
    route: IRoute;
    routeStart: string;
    dsdDeliveriesCount: number;
    vanSalesCount: number;
    visitsSkippedCount: number;
    routeCompletion: string;
    endDate: Date;
}

export class DailyActivitySummary extends BaseEntity implements IDailyActivitySummary {
    public id: number;
    public date: Date;
    public driver: IEmployee;
    public firstName: string;
    public lastName: string;
    public route: IRoute;
    public routeStart: string;
    public dsdDeliveriesCount: number;
    public vanSalesCount: number;
    public visitsSkippedCount: number;
    public routeCompletion: string;
    public endDate: Date;

    constructor(dailyActivitySummary?) {
        super();
        if (dailyActivitySummary) {
            this.id = dailyActivitySummary.id;
            this.date = dailyActivitySummary.date;
            this.driver = dailyActivitySummary.driver;
            this.firstName = dailyActivitySummary.firstName;
            this.lastName = dailyActivitySummary.lastName;
            this.route = dailyActivitySummary.route;
            this.routeStart = dailyActivitySummary.routeStart;
            this.dsdDeliveriesCount = dailyActivitySummary.dsdDeliveries;
            this.vanSalesCount = dailyActivitySummary.vanSales;
            this.visitsSkippedCount = dailyActivitySummary.visitsSkipped;
            this.routeCompletion = dailyActivitySummary.routeCompletion;
            this.endDate = dailyActivitySummary.endDate;
        } else {
        }
    }
    clone(): DailyActivitySummary {
        return new DailyActivitySummary(this);
    }
}
