import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ProductPackagingService} from 'app/blocks/service/api/product-packaging.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IProductPackaging} from 'app/blocks/model/product-packaging.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ProductPackaging} from 'app/blocks/model/product-packaging.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ProductPackagingResource extends AbstractEntityResource<IProductPackaging> {
    constructor(productPackagingService: ProductPackagingService, router: Router) {
        super(
            Resource.PRODUCT_PACKAGINGS,
            productPackagingService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_PACKAGINGS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_PACKAGINGS),
            (entity?) => new ProductPackaging(entity),
            (entity) => entity.code
        );
    }
}
