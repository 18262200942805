import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IExecution extends IBaseEntity {
    executionArn: string;
    stateMachineArn: string;
    name: string;
    status: string;
    startDate: string;
    stopDate: string;
}

export class Execution extends BaseEntity implements IExecution {
    public executionArn: string;
    public stateMachineArn: string;
    public name: string;
    public status: string;
    public startDate: string;
    public stopDate: string;

    constructor(execution?) {
        super();
        if (execution) {
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Execution {
        return new Execution(this);
    }
}
