import {InventoryProductEntry} from 'app/blocks/model/inventory-product-entry.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IInventoryProductEntry} from 'app/blocks/model/inventory-product-entry.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class InventoryProductEntryService extends EntityCrudService<IInventoryProductEntry> {
    constructor(http: HttpClient) {
        super(http, Resource.INVENTORY_PRODUCT_ENTRIES, (obj?) => new InventoryProductEntry(obj));
    }
}
