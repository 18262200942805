import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {ISafetyProfile, SafetyProfile} from 'app/blocks/model/safety-profile.model';

export interface IVehicleType extends IBaseEntity {
    id?: number;
    type?: string;
    description?: string;
    maxWeight?: number;
    maxVolume?: number;
    emptyWeight?: number;
    bayCount?: number;
    maxPackageCount?: number;
    checkList?: ISafetyProfile;
    eodCheckList?: ISafetyProfile;
    ghVehicleType?: string;
    speedFactor?: number;
    serviceTimeFactor?: number;
    costPerKiloMeter?: number;
    costPerHour?: number;
    costPerActivation?: number;
}
export class VehicleType extends BaseFranchiseEntity implements IVehicleType {
    public id?: number;
    public type?: string;
    public description?: string;
    public maxWeight?: number;
    public maxVolume?: number;
    public emptyWeight?: number;
    public bayCount?: number;
    public maxPackageCount?: number;
    public checkList?: SafetyProfile;
    public eodCheckList?: SafetyProfile;
    public ghVehicleType?: string;
    public speedFactor?: number;
    public serviceTimeFactor?: number;
    public costPerKiloMeter?: number;
    public costPerHour?: number;
    public costPerActivation?: number;

    constructor(vehicleType?) {
        super();
        if (vehicleType) {
            this.id = vehicleType.id;
            this.type = vehicleType.type;
            this.description = vehicleType.description;
            this.maxWeight = vehicleType.maxWeight;
            this.maxVolume = vehicleType.maxVolume;
            this.emptyWeight = vehicleType.emptyWeight;
            this.bayCount = vehicleType.bayCount;
            this.maxPackageCount = vehicleType.maxPackageCount;
            this.checkList = vehicleType.checkList;
            this.editable = vehicleType.editable;
            this.eodCheckList = vehicleType.eodCheckList;
            this.ghVehicleType = vehicleType.ghVehicleType;
            this.speedFactor = vehicleType.speedFactor;
            this.serviceTimeFactor = vehicleType.serviceTimeFactor;
            this.costPerKiloMeter = vehicleType.costPerKiloMeter;
            this.costPerHour = vehicleType.costPerHour;
            this.costPerActivation = vehicleType.costPerActivation;
        }
    }
    get discriminator(): any {
        return this.type;
    }
    clone(): VehicleType {
        return new VehicleType(this);
    }

    static getValue(ghVehicleType: String): String {
        switch (ghVehicleType) {
            case 'CAR':
                return 'car';
            case 'SMALL_TRUCK':
                return 'small_truck';
            case 'TRUCK':
                return 'truck';
            default:
                return 'truck';
        }
    }
}
