import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ICustomPdfTemplate extends IBaseEntity {
    id?: number;
    name?: string;
    schemas?: string;
    columns?: string;
    basePdf?: string;
    templateMappings: TemplateMapping[];
}

export interface TemplateMapping {
    id: number;
    fieldName: string;
    propertyName: string;
    customPdfTemplateId: number;
}

export class CustomPdfTemplate extends BaseEntity implements ICustomPdfTemplate {
    public id?: number;
    public name?: string;
    public schemas?: string;
    public columns?: string;
    public basePdf?: string;
    public templateMappings: TemplateMapping[];

    constructor(value?: any) {
        super();
        if (value) {
            this.id = value.id;
            this.name = value.name;
            this.schemas = value.schemas;
            this.columns = value.columns;
            this.basePdf = value.basePdf;
            this.templateMappings = value.templateMappings;
        }
    }

    clone(): IBaseEntity {
        return new CustomPdfTemplate(this);
    }
}
