import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {CatalogueResourceItem, ICatalogueResourceItem} from 'app/blocks/model/resource-item.model';

@Injectable({
    providedIn: 'root'
})
export class CatalogueResourceItemService extends EntityCrudService<ICatalogueResourceItem> {
    constructor(http: HttpClient) {
        super(http, Resource.RESOURCE_ITEMS, (obj?) => new CatalogueResourceItem(obj));
    }

    uploadLinkingImage = (file: File, name: string): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', name);
        return this.http.post<any>(`${SERVER_API_URL}api/resource-items/upload-link-image`, fd).toPromise();
    };

    uploadThumbnail = (file: File, name: string): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', name);
        return this.http.post<any>(`${SERVER_API_URL}api/resource-items/upload-thumbnail`, fd).toPromise();
    };

    uploadAsset = (resourceItemId: number, name: string, file: File): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', name);
        if (resourceItemId) {
            fd.append('resourceId', resourceItemId.toString());
        }
        return this.http.post<any>(`${SERVER_API_URL}api/resource-items/upload-asset`, fd).toPromise();
    };

    downloadFile = (resourceItemId: number, slideId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/resource-items/${resourceItemId}/assets/${slideId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    deleteFile = (resourceItemId: number, slideId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/resource-items/${resourceItemId}/assets/${slideId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };
}
