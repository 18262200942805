import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IImage} from 'app/blocks/model/image.model';

export interface IBrand extends IBaseEntity {
    id?: number;
    name?: string;
    images?: IImage[];
}

export class Brand extends BaseEntity implements IBrand {
    public id?: number;
    public name?: string;
    public images?: IImage[];

    constructor(brand?: any) {
        super();
        if (brand) {
            this.id = brand.id;
            this.name = brand.name;
            this.images = brand.images ?? [];
        }
    }

    clone(): IBaseEntity {
        return new Brand(this);
    }
}
