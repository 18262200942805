import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface ICustomerStatusReasonGroup extends IBaseEntity {
    id?: number;

    code?: string;

    group?: string;

    sequence?: number;

    active?: boolean;
}

export class CustomerStatusReasonGroup extends BaseEntity implements ICustomerStatusReasonGroup {
    public id?: number;

    public code?: string;

    public group?: string;

    public sequence?: number;

    public active?: boolean;

    constructor(customerStatusReasonGroup?) {
        super();
        if (customerStatusReasonGroup) {
            this.id = customerStatusReasonGroup.id;

            this.code = customerStatusReasonGroup.code;

            this.group = customerStatusReasonGroup.group;

            this.sequence = customerStatusReasonGroup.sequence;

            this.active = customerStatusReasonGroup.active;
        } else {
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): CustomerStatusReasonGroup {
        return new CustomerStatusReasonGroup(this);
    }
}
