import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IRouteMapSymbol extends IBaseEntity {
    id?: number;

    name?: string;
}

export class RouteMapSymbol extends BaseEntity implements IRouteMapSymbol {
    public id?: number;

    public name?: string;

    constructor(routeMapSymbol?) {
        super();
        if (routeMapSymbol) {
            this.id = routeMapSymbol.id;

            this.name = routeMapSymbol.name;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): RouteMapSymbol {
        return new RouteMapSymbol(this);
    }
}
