import {PriceList} from 'app/blocks/model/price-list.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPriceList} from 'app/blocks/model/price-list.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class PriceListService extends EntityCrudService<IPriceList> {
    constructor(http: HttpClient) {
        super(http, Resource.PRICE_LISTS, (obj?) => new PriceList(obj));
    }

    getPriceLists = (type): Promise<HttpResponse<any>> => {
        return this.http
            .get(SERVER_API_URL + 'api/price-lists/type/' + type, {
                observe: 'response'
            })
            .toPromise();
    };

    applyPriceListToCustomers = (id: any, customers: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/price-lists/apply/' + id, customers, {observe: 'response'}).toPromise();
    };

    public downloadPDF = (priceListId: number): any => {
        const REQUEST_URI = SERVER_API_URL + `api/price-lists/${priceListId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public downloadDataInCSVFormat = (priceListId: number): any => {
        const REQUEST_URI = SERVER_API_URL + `api/price-lists/export-data-csv/${priceListId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };
}
