import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IIntegrationMapping, IntegrationMapping} from 'app/blocks/model/integration-mapping.model';

@Injectable({
    providedIn: 'root'
})
export class IntegrationMappingService extends EntityCrudService<IIntegrationMapping> {
    constructor(http: HttpClient) {
        super(http, Resource.INTEGRATION_MAPPINGS, (obj?) => new IntegrationMapping(obj));
    }
}
