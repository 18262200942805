import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
export interface IUnitOfMeasure extends IBaseEntity {
    id?: number;
    group?: string;
    uom?: string;
    isUserDefinedUom?: boolean;
    isReferenceWithinGroup?: boolean;
    refMultiplicationFactor?: number;
}
export class UnitOfMeasure extends BaseEntity implements IUnitOfMeasure {
    public id?: number;
    public group?: string;
    public uom?: string;
    public isUserDefinedUom?: boolean;
    public isReferenceWithinGroup?: boolean;
    public refMultiplicationFactor?: number;
    constructor(unitOfMeasure?) {
        super();
        if (unitOfMeasure) {
            this.id = unitOfMeasure.id;
            this.group = unitOfMeasure.group;
            this.uom = unitOfMeasure.uom;
            this.isUserDefinedUom = unitOfMeasure.isUserDefinedUom;
            this.isReferenceWithinGroup = unitOfMeasure.isReferenceWithinGroup;
            this.refMultiplicationFactor = unitOfMeasure.refMultiplicationFactor;
        } else {
        }
    }
    get discriminator(): any {
        return this.uom;
    }
    clone(): UnitOfMeasure {
        return new UnitOfMeasure(this);
    }
}
