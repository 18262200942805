import {IAddress} from 'app/blocks/model/address.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IBin} from 'app/blocks/model/bin.model';
import {IBranch} from 'app/blocks/model/branch.model';

export interface IWorkshop extends IBaseFranchiseEntity {
    id?: number;
    code?: string;
    workshopName?: string;
    binLocationEnabled?: boolean;
    binLocationMask?: string;
    bins?: IBin[];
    address?: IAddress;
    branch?: IBranch;
    latitude?: number;
    longitude?: number;
}

export class Workshop extends BaseFranchiseEntity implements IWorkshop {
    public id?: number;
    public code?: string;
    public workshopName?: string;
    public binLocationEnabled?: boolean;
    public binLocationMask?: string;
    public branch?: IBranch;
    public latitude?: number;
    public longitude?: number;
    public bins?: IBin[];

    constructor(workshop?) {
        super();
        if (workshop) {
            this.id = workshop.id;
            this.code = workshop.code;
            this.workshopName = workshop.workshopName;
            this.binLocationEnabled = workshop.binLocationEnabled;
            this.binLocationMask = workshop.binLocationMask;
            this.branch = workshop.branch;
            this.latitude = workshop.latitude;
            this.longitude = workshop.longitude;
            this.bins = workshop.bins;
            this.editable = workshop.editable;
        } else {
            this.bins = [];
        }
    }
    get discriminator(): any {
        return this.code;
    }
    clone(): Workshop {
        return new Workshop(this);
    }
}
