import {IBaseFranchiseEntity, BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface ICreditTerm extends IBaseFranchiseEntity {
    id?: number;
    code?: string;
    description?: string;
}

export class CreditTerm extends BaseFranchiseEntity implements ICreditTerm {
    public id?: number;
    public code?: string;
    public description?: string;

    constructor(creditTerm?) {
        super();
        if (creditTerm) {
            this.id = creditTerm.id;
            this.code = creditTerm.code;
            this.description = creditTerm.description;
            this.editable = creditTerm.editable;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): CreditTerm {
        return new CreditTerm(this);
    }
}
