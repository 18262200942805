import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CreditTermService} from 'app/blocks/service/api/credit-term.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICreditTerm} from 'app/blocks/model/credit-term.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {CreditTerm} from 'app/blocks/model/credit-term.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CreditTermResource extends AbstractEntityResource<ICreditTerm> {
    constructor(creditTermService: CreditTermService, router: Router) {
        super(
            Resource.CREDIT_TERMS,
            creditTermService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CREDIT_TERMS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CREDIT_TERMS),
            (entity?) => new CreditTerm(entity),
            (entity) => entity.code,
            'money'
        );
    }
}
