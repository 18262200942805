import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ProductTaxCategoryService} from 'app/blocks/service/api/product-tax-category.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ProductTaxCategoryResource extends AbstractEntityResource<IProductTaxCategory> {
    constructor(productTaxCategoryService: ProductTaxCategoryService, router: Router) {
        super(
            Resource.UK_VAT_RATES,
            productTaxCategoryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.UK_VAT_RATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.UK_VAT_RATES),
            (entity?) => new ProductTaxCategory(entity),
            (entity) => entity.code,
            'attach_money'
        );
    }
}
