import {Injectable} from '@angular/core';
import {CACHE_ENABLED, ENABLE_SERVICE_ORDER_ON_SALE_ORDER_PAGE} from 'app/constants/setting';
import {SettingsService} from 'app/common/settings.service';

@Injectable({
    providedIn: 'root'
})
export class SwCacheService {
    private cacheEnabled: boolean = true;

    constructor(private settingsService: SettingsService) {}

    subscribeToCacheSettings(): void {
        this.settingsService.subscribeToSetting(CACHE_ENABLED, (value) => {
            console.log('CacheConfigService: ngOnInit: value: ', value);
            this.cacheEnabled = !!value || true;
        });
    }

    isCacheEnabled(): boolean {
        return this.cacheEnabled;
    }

    async resetSwCacheForGroup(group: string): Promise<void> {
        const deletePromises = [];
        const {caches} = window;
        const keys = await caches.keys();

        keys.forEach((eachCacheName) => {
            let regExPat = new RegExp(`^(ngsw).*${group}.*`);
            if (regExPat.test(eachCacheName)) {
                caches.open(eachCacheName).then((eachCache) => {
                    eachCache.keys().then((requests) => {
                        requests.forEach((eachRequest) => {
                            // console.log(`Deleting cache for ${eachCacheName} -> ${eachCache} -> ${eachRequest.url}`);
                            deletePromises.push(eachCache.delete(eachRequest));
                        });
                    });
                });
            }
        });

        await Promise.all(deletePromises);
    }

    async resetSwCache(): Promise<void> {
        await this.resetSwCacheForGroup('api');
    }
}
