import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RoiVatCategoryService} from 'app/blocks/service/api/roi-vat-category.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {GctTaxCategoryService} from 'app/blocks/service/api/gct-tax-category.service';

@Injectable({
    providedIn: 'root'
})
export class GctTaxCategoryResource extends AbstractEntityResource<IProductTaxCategory> {
    constructor(gctTaxCategoryService: GctTaxCategoryService, router: Router) {
        super(
            Resource.GCT_RATES,
            gctTaxCategoryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.GCT_RATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.GCT_RATES),
            (entity?) => new ProductTaxCategory(entity),
            (entity) => entity.code,
            'attach_money'
        );
    }
}
