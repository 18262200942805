import {ISupplier} from 'app/blocks/model/supplier.model';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';
import {MposDate} from 'app/blocks/util/mpos-date';

import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IPurchaseCreditNoteLine} from 'app/blocks/model/purchase-credit-note-line.model';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line';
import {IPurchaseInvoice} from 'app/blocks/model/purchase-invoice.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';

export interface IApplyPurchaseCreditNoteDTO {
    purchaseInvoice: IPurchaseInvoice;
    amount: number;
}

export class ApplyPurchaseCreditNoteDTO implements IApplyPurchaseCreditNoteDTO {
    public purchaseInvoice: IPurchaseInvoice;
    public amount: number;
    constructor(purchaseInvoice?: IPurchaseInvoice) {
        if (purchaseInvoice) {
            this.purchaseInvoice = purchaseInvoice;
        }
        this.amount = 0;
    }
}

export interface IPurchaseCreditNote extends IBaseEntity {
    id?: number;
    purchaseCreditNoteNo?: string;
    reference?: string;
    status?: any;
    deleted?: boolean;
    subTotal?: number;
    tax?: number;
    shippingCost?: number;
    total?: number;
    notes?: string;
    supplier?: ISupplier;
    saleOrder?: ISaleOrder;
    purchaseCreditNoteLines?: IPurchaseCreditNoteLine[];
    adHocLines?: IAdHocLine[];
    creationDate?: any;
    updateDate?: any;
    allocatedAmount?: number;
    createdBy?: IEmployee;
    approved?: boolean;
    exported?: boolean;
    purchaseCreditNoteDate?: any;
    purchasePaymentAllocations?: any[];
    purchaseCreditNoteAllocations?: any[];
    warehouse?: IWarehouse;
}

export class PurchaseCreditNote extends BaseEntity implements IPurchaseCreditNote {
    public id?: number;
    public purchaseCreditNoteNo?: string;
    public reference?: string;
    public status?: string;
    public deleted?: boolean;
    public subTotal?: number;
    public shippingCost?: number;
    public tax?: number;
    public total?: number;
    public notes?: string;
    public supplier?: ISupplier;
    public purchaseCreditNoteLines?: IPurchaseCreditNoteLine[];
    public adHocLines?: IAdHocLine[];
    public creationDate?: any;
    public updateDate?: any;
    public createdBy?: IEmployee;
    public exported?: boolean;
    public approved?: boolean;
    public allocatedAmount?: number;
    public purchaseCreditNoteDate?: any;
    public purchaseCreditNoteAllocations?: any[];
    public purchasePaymentAllocations?: any[];
    public warehouse?: IWarehouse;

    constructor(purchaseCreditNote?: any) {
        super();
        if (purchaseCreditNote) {
            this.id = purchaseCreditNote.id;
            this.purchaseCreditNoteNo = purchaseCreditNote.purchaseCreditNoteNo;
            this.reference = purchaseCreditNote.reference;
            this.status = purchaseCreditNote.status;
            this.deleted = purchaseCreditNote.deleted;
            this.subTotal = purchaseCreditNote.subTotal;
            this.shippingCost = purchaseCreditNote.shippingCost || 0;
            this.tax = purchaseCreditNote.tax;
            this.approved = purchaseCreditNote.approved;
            this.exported = purchaseCreditNote.exported;
            this.total = purchaseCreditNote.total;
            this.notes = purchaseCreditNote.notes;
            this.supplier = purchaseCreditNote.supplier;
            this.allocatedAmount = purchaseCreditNote.allocatedAmount;
            this.createdBy = purchaseCreditNote.createdBy;
            this.adHocLines = purchaseCreditNote.adHocLines || [];
            this.purchaseCreditNoteLines = purchaseCreditNote.purchaseCreditNoteLines || [];
            this.creationDate = MposDate.newInstance(purchaseCreditNote.creationDate) || MposDate.currentDate();
            this.updateDate = MposDate.newInstance(purchaseCreditNote.updateDate);
            this.purchaseCreditNoteDate = MposDate.newInstance(purchaseCreditNote.purchaseCreditNoteDate);
            this.purchasePaymentAllocations = purchaseCreditNote.purchasePaymentAllocations || [];
            this.purchaseCreditNoteAllocations = purchaseCreditNote.purchaseCreditNoteAllocations || [];
            this.warehouse = purchaseCreditNote.warehouse;
        } else {
            this.status = 'DRAFT';
            this.creationDate = MposDate.currentDate();
            this.purchaseCreditNoteLines = [];
            this.adHocLines = [];
            this.allocatedAmount = 0;
            this.purchasePaymentAllocations = [];
            this.purchaseCreditNoteAllocations = [];
        }
    }
    get discriminator(): any {
        return this.purchaseCreditNoteNo;
    }

    clone(): PurchaseCreditNote {
        return new PurchaseCreditNote(this);
    }
}
