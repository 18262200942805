import {ProductPackaging} from 'app/blocks/model/product-packaging.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IProductPackaging} from 'app/blocks/model/product-packaging.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ProductPackagingService extends EntityCrudService<IProductPackaging> {
    constructor(http: HttpClient) {
        super(http, Resource.PRODUCT_PACKAGINGS, (obj?) => new ProductPackaging(obj));
    }
}
