import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {InventoryProductEntryService} from 'app/blocks/service/api/inventory-product-entry.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IInventoryProductEntry} from 'app/blocks/model/inventory-product-entry.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {InventoryProductEntry} from 'app/blocks/model/inventory-product-entry.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class InventoryProductEntryResource extends AbstractEntityResource<IInventoryProductEntry> {
    constructor(inventoryProductEntryService: InventoryProductEntryService, router: Router) {
        super(
            Resource.INVENTORY_PRODUCT_ENTRIES,
            inventoryProductEntryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.INVENTORY_PRODUCT_ENTRIES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.INVENTORY_PRODUCT_ENTRIES),
            (entity?) => new InventoryProductEntry(entity),
            (entity) => entity.code
        );
    }
}
