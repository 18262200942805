import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBinType, BinType} from 'app/blocks/model/bin-type.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class BinTypeService extends EntityCrudService<IBinType> {
    constructor(http: HttpClient) {
        super(http, Resource.BIN_TYPES, (obj?) => new BinType(obj));
    }
}
