import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ServicePriorityCodeService} from 'app/blocks/service/api/service-priority-code.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IServicePriorityCode} from 'app/blocks/model/service-priority-code.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ServicePriorityCode} from 'app/blocks/model/service-priority-code.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ServicePriorityCodeResource extends AbstractEntityResource<IServicePriorityCode> {
    constructor(servicePriorityCodeService: ServicePriorityCodeService, router: Router) {
        super(
            Resource.SERVICE_PRIORITY_CODES,
            servicePriorityCodeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SERVICE_PRIORITY_CODES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SERVICE_PRIORITY_CODES),
            (entity?) => new ServicePriorityCode(entity),
            (entity) => entity.code,
            'priority_high'
        );
    }
}
