import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface ICGSTCategory extends IBaseEntity {
    id?: number;
    label?: string;
    isDefaultInGroup?: boolean;
    taxPercentage: number;
}

export class CGSTCategory extends BaseEntity implements ICGSTCategory {
    public id?: number;
    public label?: string;
    public isDefaultInGroup?: boolean;
    public taxPercentage: number;

    constructor(cgstCategory?) {
        super();
        if (cgstCategory) {
            this.id = cgstCategory.id;
            this.label = cgstCategory.label;
            this.isDefaultInGroup = cgstCategory.isDefaultInGroup;
            this.taxPercentage = cgstCategory.taxPercentage;
        }
    }

    get discriminator(): any {
        return this.label;
    }

    clone(): CGSTCategory {
        return new CGSTCategory(this);
    }
}
