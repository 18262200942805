import {Currency} from 'app/blocks/model/currency.model';
import {ICurrency} from 'app/blocks/model/currency.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {IFuelSurchargeGroup, FuelSurchargeGroup} from 'app/blocks/model/fuel-surcharge-group.model';

export interface IOrganisation extends IBaseEntity {
    id?: number;
    name?: string;
    orgType?: string;
    vatRegNo?: string;
    website?: string;
    registrationNo?: string;
    accountNo?: string;
    underReview?: boolean;
    deleted?: boolean;
    contactInfo?: IContactInfo;
    currency?: ICurrency;
    fuelSurchargeGroup?: IFuelSurchargeGroup;
}

export class Organisation extends BaseEntity implements IOrganisation {
    public id?: number;
    public name?: string;
    public orgType?: string;
    public vatRegNo?: string;
    public website?: string;
    public registrationNo?: string;
    public accountNo?: string;
    public underReview?: boolean;
    public deleted?: boolean;
    public contactInfo?: IContactInfo;
    public currency?: ICurrency;
    public fuelSurchargeGroup?: IFuelSurchargeGroup;
    constructor(organisation?) {
        super();
        if (organisation) {
            this.id = organisation.id;
            this.name = organisation.name;
            this.orgType = organisation.orgType;
            this.vatRegNo = organisation.vatRegNo;
            this.website = organisation.website;
            this.registrationNo = organisation.registrationNo;
            this.accountNo = organisation.accountNo;
            this.underReview = organisation.underReview;
            this.deleted = organisation.deleted;
            this.contactInfo = organisation.contactInfo;
            this.currency = organisation.currency;
            this.fuelSurchargeGroup = organisation.fuelSurchargeGroup;
        } else {
            this.contactInfo = new ContactInfo();
        }
    }
    get discriminator(): any {
        return this.name;
    }
    clone(): Organisation {
        return new Organisation(this);
    }
}
