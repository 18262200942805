import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TrailerService} from 'app/blocks/service/api/trailer.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ITrailer} from 'app/blocks/model/trailer.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Trailer} from 'app/blocks/model/trailer.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class TrailerResource extends AbstractEntityResource<ITrailer> {
    constructor(trailerService: TrailerService, router: Router) {
        super(
            Resource.TRAILERS,
            trailerService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.TRAILERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.TRAILERS),
            (entity?) => new Trailer(entity),
            (entity) => entity.trailerId,
            'local_shipping'
        );
    }
}
