import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {ICreditTerm} from 'app/blocks/model/credit-term.model';
import {IDunningMessageCode} from 'app/blocks/model/dunning-message-code.model';
import {IPaymentType} from 'app/blocks/model/payment-type.model';

export interface ICreditClass extends IBaseFranchiseEntity {
    id?: number;
    code?: string;
    description?: string;
    pastDueAtDays?: number;
    pastDueIgnoreIfLessThan?: number;
    holdServiceAtDays?: number;
    holdServiceIgnoreIfLessThan?: number;
    dunningMessageAtDays?: number;
    dunningMessageIgnoreIfLessThan?: number;
    allowCodAtPastDue?: boolean;
    allowCodAtHoldService?: boolean;
    dunningMessageCode?: IDunningMessageCode;
    pastDuePolicy?: any;
    holdServicePolicy?: any;
    dunningMessagePolicy?: any;
    creditTerm?: ICreditTerm;
    acceptedPayments?: IPaymentType[];
    stopSiteVisitSubmission?: boolean;
    notes?: string;
    noOfDaysPastCurrentMonth?: number;
    noOfMonthsPastCurrentMonth?: number;
    considerForwardOrderValueInEndingBalance?: boolean;
}

export class CreditClass extends BaseFranchiseEntity implements ICreditClass {
    public id?: number;
    public code?: string;
    public description?: string;
    public pastDueAtDays?: number;
    public pastDueIgnoreIfLessThan?: number;
    public holdServiceAtDays?: number;
    public holdServiceIgnoreIfLessThan?: number;
    public dunningMessageAtDays?: number;
    public dunningMessageIgnoreIfLessThan?: number;
    public allowCodAtPastDue?: boolean;
    public allowCodAtHoldService?: boolean;
    public dunningMessageCode?: IDunningMessageCode;
    public pastDuePolicy?: any;
    public holdServicePolicy?: any;
    public dunningMessagePolicy?: any;
    public creditTerm?: ICreditTerm;
    public acceptedPayments?: IPaymentType[];
    public stopSiteVisitSubmission?: boolean;
    public notes?: string;
    public noOfDaysPastCurrentMonth?: number;
    public noOfMonthsPastCurrentMonth?: number;
    public considerForwardOrderValueInEndingBalance?: boolean;

    constructor(creditClass?) {
        super();
        if (creditClass) {
            this.id = creditClass.id;
            this.code = creditClass.code;
            this.description = creditClass.description;
            this.pastDueAtDays = creditClass.pastDueAtDays;
            this.pastDueIgnoreIfLessThan = creditClass.pastDueIgnoreIfLessThan;
            this.holdServiceAtDays = creditClass.holdServiceAtDays;
            this.holdServiceIgnoreIfLessThan = creditClass.holdServiceIgnoreIfLessThan;
            this.dunningMessageAtDays = creditClass.dunningMessageAtDays;
            this.dunningMessageIgnoreIfLessThan = creditClass.dunningMessageIgnoreIfLessThan;
            this.allowCodAtPastDue = creditClass.allowCodAtPastDue;
            this.allowCodAtHoldService = creditClass.allowCodAtHoldService;
            this.dunningMessageCode = creditClass.dunningMessageCode;
            this.pastDuePolicy = creditClass.pastDuePolicy;
            this.holdServicePolicy = creditClass.holdServicePolicy;
            this.dunningMessagePolicy = creditClass.dunningMessagePolicy;
            this.creditTerm = creditClass.creditTerm;
            this.acceptedPayments = creditClass.acceptedPayments || [];
            this.stopSiteVisitSubmission = creditClass.stopSiteVisitSubmission;
            this.editable = creditClass.editable;
            this.notes = creditClass.notes;
            this.noOfDaysPastCurrentMonth = creditClass.noOfDaysPastCurrentMonth;
            this.noOfMonthsPastCurrentMonth = creditClass.noOfMonthsPastCurrentMonth;
            this.considerForwardOrderValueInEndingBalance = creditClass.considerForwardOrderValueInEndingBalance;
        } else {
            this.acceptedPayments = [];
        }
    }
    get discriminator(): any {
        return this.code;
    }
    clone(): CreditClass {
        return new CreditClass(this);
    }
}
