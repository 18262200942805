import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {AsyncRequest, IAsyncRequest} from 'app/blocks/model/async_request.model';
import {AsyncRequestService} from 'app/blocks/service/api/async_request.service';

@Injectable({
    providedIn: 'root'
})
export class AsyncRequestResource extends AbstractEntityResource<IAsyncRequest> {
    constructor(asyncRequestService: AsyncRequestService, router: Router) {
        super(
            Resource.ASYNC_REQUESTS,
            asyncRequestService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ASYNC_REQUESTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ASYNC_REQUESTS),
            (entity?) => new AsyncRequest(entity),
            (entity) => entity.id
        );
    }
}
