import {ClientSaleOrder} from 'app/blocks/model/client-sale-order.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IClientSaleOrder} from 'app/blocks/model/client-sale-order.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ClientSaleOrderService extends EntityCrudService<IClientSaleOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.CLIENT_SALE_ORDERS, (obj?) => new ClientSaleOrder(obj));
    }
}
