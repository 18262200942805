import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {BranchTransaction, IBranchTransaction} from 'app/blocks/model/branch-transaction.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class BranchTransactionService extends EntityCrudService<IBranchTransaction> {
    constructor(http: HttpClient) {
        super(http, Resource.BRANCH_TRANSACTIONS, (obj?) => new BranchTransaction(obj));
    }

    getOutstandingBalanceForBranch(branchId: number): Promise<HttpResponse<any>> {
        const REQUEST_URI = `${SERVER_API_URL}api/branch-transactions/${branchId}/outstanding-balance`;
        return this.http.get(REQUEST_URI, {params: null, observe: 'response'}).toPromise();
    }
}
