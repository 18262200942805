import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {IShortfallReport, ShortfallReport} from 'app/blocks/model/shortfall-report.model';

@Injectable({
    providedIn: 'root'
})
export class ShortfallReportService extends EntityCrudService<IShortfallReport> {
    constructor(http: HttpClient) {
        super(http, Resource.SHORTFALL_REPORT, (obj?) => new ShortfallReport(obj));
    }

    public getReport = (
        pageIndex: any,
        pageSize: any,
        dates: any,
        saleOrderNo: any,
        routeId: any,
        accountNoId: any,
        customerId: any,
        productCodeId: any,
        productDescId: any,
        uomId: any,
        promisedDeliveryDates: any,
        actualDeliveryDates: any,
        pickedById: any,
        salesRepresentativeId: any,
        dpSalesRepresentativeId: any,
        invoiceId: any,
        invoiceDates: any
    ): Promise<HttpResponse<any>> => {
        const requestParams = new HttpParams()
            .set('page', pageIndex)
            .set('size', pageSize)
            .set('dates', dates)
            .set('saleOrderNo', saleOrderNo)
            .set('routeId', routeId)
            .set('accountNoId', accountNoId)
            .set('customerId', customerId)
            .set('productCodeId', productCodeId)
            .set('productDescId', productDescId)
            .set('uomId', uomId)
            .set('promisedDeliveryDates', promisedDeliveryDates)
            .set('actualDeliveryDates', actualDeliveryDates)
            .set('pickedById', pickedById)
            .set('salesRepresentativeId', salesRepresentativeId)
            .set('dpSalesRepresentative', dpSalesRepresentativeId)
            .set('invoiceId', invoiceId)
            .set('invoiceDates', invoiceDates);
        const REQUEST_URI = `${SERVER_API_URL}api/shortfall-reports`;
        return this.http.get(REQUEST_URI, {params: requestParams, observe: 'response'}).toPromise();
    };

    public exportAll = (
        pageIndex: any,
        pageSize: any,
        dates: any,
        saleOrderNo: any,
        routeId: any,
        accountNoId: any,
        customerId: any,
        productCodeId: any,
        productDescId: any,
        uomId: any,
        promisedDeliveryDates: any,
        actualDeliveryDates: any,
        pickedById: any,
        salesRepresentativeId: any,
        dpSalesRepresentativeId: any
    ): Promise<any> => {
        const requestParams = new HttpParams()
            .set('page', pageIndex)
            .set('pageSize', pageSize)
            .set('dates', dates)
            .set('saleOrderNo', saleOrderNo)
            .set('routeId', routeId)
            .set('accountNoId', accountNoId)
            .set('customerId', customerId)
            .set('productCodeId', productCodeId)
            .set('productDescId', productDescId)
            .set('uomId', uomId)
            .set('promisedDeliveryDates', promisedDeliveryDates)
            .set('actualDeliveryDates', actualDeliveryDates)
            .set('pickedById', pickedById)
            .set('salesRepresentativeId', salesRepresentativeId)
            .set('dpSalesRepresentative', dpSalesRepresentativeId);

        const REQUEST_URI = `${SERVER_API_URL}api/reports/shortfall/export`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
}
