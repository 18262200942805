import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {DiTime} from 'app/blocks/util/di-time';

export interface IDeliveryOrder extends IBaseEntity {
    id?: number;
    deliveryOrderNo?: string;
    scheduledDate?: any;
    scheduledTime?: any;
    signedBy?: string;
    serviceDate?: any;
    serviceTime?: any;
    serviced?: boolean;
    invoiced?: boolean;
    orderWeight?: number;
    orderVolume?: number;
    deliveryOrderStatus?: string;
}

export class DeliveryOrder extends BaseEntity implements IDeliveryOrder {
    public id?: number;
    public deliveryOrderNo?: string;
    public scheduledDate?: any;
    public scheduledTime?: any;
    public signedBy?: string;
    public serviceDate?: any;
    public serviceTime?: any;
    public serviced?: boolean;
    public invoiced?: boolean;
    public orderWeight?: number;
    public orderVolume?: number;
    public deliveryOrderStatus?: string;

    constructor(deliveryOrder?) {
        super();
        if (deliveryOrder) {
            this.id = deliveryOrder.id;
            this.deliveryOrderNo = deliveryOrder.deliveryOrderNo;
            this.scheduledDate = MposDate.newInstance(deliveryOrder.scheduledDate);
            this.scheduledTime = DiTime.newInstance(deliveryOrder.scheduledTime);
            this.signedBy = deliveryOrder.signedBy;
            this.serviceDate = MposDate.newInstance(deliveryOrder.serviceDate);
            this.serviceTime = DiTime.newInstance(deliveryOrder.serviceTime);
            this.serviced = deliveryOrder.serviced;
            this.invoiced = deliveryOrder.invoiced;
            this.orderWeight = deliveryOrder.orderWeight;
            this.orderVolume = deliveryOrder.orderVolume;
            this.deliveryOrderStatus = deliveryOrder.deliveryOrderStatus;
        } else {
            this.deliveryOrderStatus = 'UNASSIGNED';
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DeliveryOrder {
        return new DeliveryOrder(this);
    }
}
