import {ProductList} from 'app/blocks/model/product-list.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IProductList} from 'app/blocks/model/product-list.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class ProductListService extends EntityCrudService<IProductList> {
    constructor(http: HttpClient) {
        // super(http, Resource.PRODUCT_LISTS, (obj?) => new ProductList(obj), true);
        super(http, Resource.PRODUCT_LISTS, (obj?) => new ProductList(obj));
    }

    applyProductListToCustomers = (id: any, customers: any, blackWhiteList: any): Promise<any> => {
        const params = new HttpParams().set('customers', customers).set('blackWhiteList', blackWhiteList);
        return this.http
            .post<any>(SERVER_API_URL + 'api/product-lists/apply/' + id, null, {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    applyProductListToDeliveryPoints = (id: any, deliveryPoints: any, blackWhiteList: any): Promise<any> => {
        const params = new HttpParams().set('deliveryPoints', deliveryPoints).set('blackWhiteList', blackWhiteList);
        return this.http
            .post<any>(SERVER_API_URL + 'api/product-lists/apply/' + id, null, {
                observe: 'response',
                params: params
            })
            .toPromise();
    };
}
