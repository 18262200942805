import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CatalogueService} from 'app/blocks/service/api/catalogue.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {CatalogueEntity, ICatalogueEntity} from 'app/blocks/model/catalogue.model';

@Injectable({
    providedIn: 'root'
})
export class CatalogueResource extends AbstractEntityResource<ICatalogueEntity> {
    constructor(catalogueService: CatalogueService, router: Router) {
        super(
            Resource.CATALOGUES,
            catalogueService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CATALOGUES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CATALOGUES),
            (entity?) => new CatalogueEntity(entity),
            (entity) => entity.id
        );
    }
}
