import {DeliveryPackageType} from 'app/blocks/model/delivery-package-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDeliveryPackageType} from 'app/blocks/model/delivery-package-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryPackageTypeService extends EntityCrudService<IDeliveryPackageType> {
    constructor(http: HttpClient) {
        super(http, Resource.DELIVERY_PACKAGE_TYPES, (obj?) => new DeliveryPackageType(obj));
    }
}
