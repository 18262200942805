import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IVehicleSkill extends IBaseEntity {
    id?: number;
    skill_name?: string;
}

export class VehicleSkill extends BaseEntity implements IVehicleSkill {
    public id?: number;
    public skill_name?: string;

    constructor(VehicleSkill?: any) {
        super();
        if (VehicleSkill) {
            this.id = VehicleSkill.id;
            this.skill_name = VehicleSkill.skill_name;
            // Add other attributes
            // this.name = VehicleSkill.name;
            // Whenever there is a time element, use DiTime
            // this.timeAttr = DiTime.newInstance(VehicleSkill.timeAttr)
            // Whenever there is a date element, use DiDate
            // this.dateAttr = DiDate.newInstance(VehicleSkill.dateAttr)
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): VehicleSkill {
        return new VehicleSkill(this);
    }
}
