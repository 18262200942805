import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface ISaleOrderType extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;
}

export class SaleOrderType extends BaseEntity implements ISaleOrderType {
    public id?: number;

    public code?: string;

    public description?: string;

    constructor(saleOrderType?) {
        super();
        if (saleOrderType) {
            this.id = saleOrderType.id;

            this.code = saleOrderType.code;

            this.description = saleOrderType.description;
        } else {
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): SaleOrderType {
        return new SaleOrderType(this);
    }
}
