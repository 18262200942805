import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IBatchInfo, BatchInfo} from 'app/blocks/model/batch-info.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IWarehouseBinLocation} from 'app/blocks/model/warehouse-bin-location.model';

export interface IInventoryProductEntry extends IBaseEntity {
    id?: number;
    productCondition?: any;
    quantity?: number;
    unitCost?: number;
    productBatch?: string;
    updateProductCost?: boolean;
    purchaseOrderItem?: any;
    caseQuantity?: number;
    eachQuantity?: number;
    batchInfo?: IBatchInfo;
    warehouseBinLocation?: IWarehouseBinLocation;
    discountPercentage?: number;
}

export class InventoryProductEntry extends BaseEntity implements IInventoryProductEntry {
    public id?: number;
    public productCondition?: any;
    public quantity?: number;
    public unitCost?: number;
    public productBatch?: string;
    public updateProductCost?: boolean;
    public purchaseOrderItem?: any;
    public caseQuantity?: number;
    public eachQuantity?: number;
    public batchInfo?: BatchInfo;
    public warehouseBinLocation?: IWarehouseBinLocation;
    public discountPercentage?: number;

    constructor(inventoryProductEntry?) {
        super();
        if (inventoryProductEntry) {
            this.id = inventoryProductEntry.id;
            this.productCondition = inventoryProductEntry.productCondition;
            this.quantity = inventoryProductEntry.quantity;
            this.unitCost = inventoryProductEntry.unitCost;
            this.productBatch = inventoryProductEntry.productBatch;
            this.updateProductCost = inventoryProductEntry.updateProductCost;
            this.purchaseOrderItem = inventoryProductEntry.purchaseOrderItem;
            this.caseQuantity = inventoryProductEntry.caseQuantity;
            this.eachQuantity = inventoryProductEntry.eachQuantity;
            this.batchInfo = inventoryProductEntry.batchInfo;
            this.warehouseBinLocation = inventoryProductEntry.warehouseBinLocation;
            this.discountPercentage = inventoryProductEntry.discountPercentage;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): InventoryProductEntry {
        return new InventoryProductEntry(this);
    }
}
