import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface ICustomerSmsText extends IBaseFranchiseEntity {
    id?: number;
    smsText?: string;
    sequence?: number;
}

export class CustomerSmsText extends BaseFranchiseEntity implements ICustomerSmsText {
    public id?: number;
    public smsText?: string;
    public sequence?: number;
    constructor(customerSmsText?) {
        super();
        if (customerSmsText) {
            this.id = customerSmsText.id;
            this.smsText = customerSmsText.smsText;
            this.sequence = customerSmsText.sequence;
            this.editable = customerSmsText.editable;
        } else {
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): CustomerSmsText {
        return new CustomerSmsText(this);
    }
}
