import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IPriceListType extends IBaseEntity {
    id?: number;
    code?: string;
    description?: string;
}

export class PriceListType extends BaseEntity implements IPriceListType {
    public id?: number;
    public code?: string;
    public description?: string;

    constructor(priceListType?) {
        super();
        if (priceListType) {
            this.id = priceListType.id;
            this.code = priceListType.code;
            this.description = priceListType.description;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): PriceListType {
        return new PriceListType(this);
    }
}
