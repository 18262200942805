import {PickOrder, IPickOrder} from 'app/blocks/model/pick-order.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import {INote} from 'app/blocks/model/note.model';

@Injectable({
    providedIn: 'root'
})
export class PickOrderService extends EntityCrudService<IPickOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.PICK_ORDERS, (obj?) => new PickOrder(obj));
    }

    validatePickOrder = (request, newSaleOrderForPartialPick = false): Promise<HttpResponse<any>> => {
        const requestParams = createRequestOption({
            newSaleOrderForPartialPick: newSaleOrderForPartialPick
        });
        return this.http.post<any>(SERVER_API_URL + 'api/pick-orders/' + request.id + '/validate', request, {params: requestParams}).toPromise();
    };

    startPickOrder = (id: number, request: any): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/pick-orders/' + id + '/start', request, {observe: 'response'}).toPromise();
    };

    bulkMarkAsPending = (
        request: {
            id: number;
            notes: INote;
            markAsPendingDate: string;
            markAsPendingTime: string;
            orderItems: any[];
        }[]
    ): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/pick-orders/bulk-mark-pending`;
        const requestBody = {
            markAsPendingRequests: request
        };
        return this.http.post<any>(REQUEST_URI, requestBody, {observe: 'response'}).toPromise();
    };

    bulkAssignPickOrder = (ids: number[], pickerEmployeeId: number | null): Promise<any> => {
        const request = {ids, pickerEmployeeId};
        return this.http.post<any>(SERVER_API_URL + 'api/pick-orders/bulk-assign', request, {observe: 'response'}).toPromise();
    };

    downloadPDF = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/pick-orders/${id}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'}).toPromise();
    };

    downloadMergedPickOrders = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/pick-orders/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    downloadConsolidatedPdf = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/pick-orders/download-consolidated`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    completePickOrders = (ids: number[], pickDate: string, pickTime: string): Promise<any> => {
        const request = {ids, pickDate, pickTime};
        return this.http.post<any>(SERVER_API_URL + 'api/pick-orders/bulk-validate', request, {observe: 'response'}).toPromise();
    };

    downloadShippingMark = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/pick-orders/download-shipping-mark`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
}
