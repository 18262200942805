import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CatalogueService} from 'app/blocks/service/api/catalogue.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {CataloguePresentation, ICataloguePresentation} from 'app/blocks/model/presentation.model';
import {PresentationService} from 'app/blocks/service/api/presentation.service';

@Injectable({
    providedIn: 'root'
})
export class PresentationResource extends AbstractEntityResource<ICataloguePresentation> {
    constructor(catalogueService: PresentationService, router: Router) {
        super(
            Resource.PRESENTATIONS,
            catalogueService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRESENTATIONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRESENTATIONS),
            (entity?) => new CataloguePresentation(entity),
            (entity) => entity.id
        );
    }
}
