import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IPriceListType} from 'app/blocks/model/price-list-type.model';
import {IProduct} from 'app/blocks/model/product.model';
import {DiscountTypes} from 'app/blocks/model/discount-types';
import {DiscountType} from 'app/blocks/model/discount-type.model';

export class ProductPrice {
    product?: IProduct;
    price?: number;
    discount?: number;
    beforeDiscountPrice?: number;
    minPrice?: number;
    maxPrice?: number;
    netMinPrice?: number;
    netMaxPrice?: number;
    minMaxMargin?: number;
    constructor(productPrice?) {
        if (productPrice) {
            this.price = productPrice.price;
            this.product = productPrice.product;
            this.discount = productPrice.discount;
            this.beforeDiscountPrice = productPrice.beforeDiscountPrice;
            this.minPrice = productPrice.minPrice;
            this.maxPrice = productPrice.maxPrice;
            this.netMinPrice = productPrice.netMinPrice;
            this.netMaxPrice = productPrice.netMaxPrice;
        } else {
            this.price = 0;
            this.discount = 0;
            this.beforeDiscountPrice = 0;
            this.minMaxMargin = 0;
        }
    }
}

export class Discount {
    type?: any;
    value?: number;
    constructor() {
        this.type = new DiscountType(DiscountTypes.ABSOLUTE);
        this.value = 0;
    }
}

export interface IPriceList extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    type?: IPriceListType;
    isDiscountList?: boolean;
    discount?: Discount;
    productPrices?: ProductPrice[];
    fromDate?: Date;
    toDate?: Date;
    isMarkupList?: boolean;
    isUseMarginForMinMax?: boolean;
    minMaxPriceMargin?: Discount;
}

export class PriceList extends BaseEntity implements IPriceList {
    public id?: number;
    public name?: string;
    public description?: string;
    public type?: IPriceListType;
    public isDiscountList?: boolean;
    public discount?: Discount;
    public productPrices?: ProductPrice[];
    public fromDate?: Date;
    public toDate?: Date;
    public isMarkupList?: boolean;
    public isUseMarginForMinMax?: boolean;
    public minMaxPriceMargin?: Discount;
    constructor(priceList?) {
        super();
        if (priceList) {
            this.id = priceList.id;
            this.name = priceList.name;
            this.description = priceList.description;
            this.type = priceList.type;
            this.isDiscountList = priceList.isDiscountList;
            this.discount = priceList.discount;
            this.productPrices = priceList.productPrices;
            this.fromDate = priceList.fromDate;
            this.toDate = priceList.toDate;
            this.isMarkupList = priceList.isMarkupList;
            this.isUseMarginForMinMax = priceList.isUseMarginForMinMax;
            this.minMaxPriceMargin = priceList.minMaxPriceMargin;
        } else {
            this.productPrices = [];
        }
    }
    get discriminator(): any {
        return this.name;
    }
    clone(): PriceList {
        return new PriceList(this);
    }
}
