import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ProductAttributeTypeService} from 'app/blocks/service/api/product-attribute-type.service';
import {IProductAttributeType, ProductAttributeType} from 'app/blocks/model/product-attribute-type.model';

@Injectable({
    providedIn: 'root'
})
export class ProductAttributeTypeResource extends AbstractEntityResource<IProductAttributeType> {
    constructor(productAttributeTypeService: ProductAttributeTypeService, router: Router) {
        super(
            Resource.PRODUCT_ATTRIBUTE_TYPES,
            productAttributeTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_ATTRIBUTE_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRODUCT_ATTRIBUTE_TYPES),
            (entity?) => new ProductAttributeType(entity),
            (entity) => entity.name,
            'priority_high'
        );
    }
}
