import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ISafetyProfile, SafetyProfile} from 'app/blocks/model/safety-profile.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class VehicleCheckListService extends EntityCrudService<ISafetyProfile> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLE_CHECKLISTS, (obj?) => new SafetyProfile(obj));
    }
}
