import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ISupplierPaymentTerm, SupplierPaymentTerm} from 'app/blocks/model/supplier-payment-term.model';
import {SupplierPaymentTermService} from 'app/blocks/service/api/supplier-payment-term.service';

@Injectable({
    providedIn: 'root'
})
export class SupplierPaymentTermResource extends AbstractEntityResource<ISupplierPaymentTerm> {
    constructor(supplierPaymentTermService: SupplierPaymentTermService, router: Router) {
        super(
            Resource.SUPPLIER_PAYMENT_TERMS,
            supplierPaymentTermService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SUPPLIER_PAYMENT_TERMS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SUPPLIER_PAYMENT_TERMS),
            (entity?) => new SupplierPaymentTerm(entity),
            (entity) => entity.termName,
            'money'
        );
    }
}
