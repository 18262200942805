import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeliveryPackageService} from 'app/blocks/service/api/delivery-package.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DeliveryPackageResource extends AbstractEntityResource<IDeliveryPackage> {
    constructor(deliveryPackageService: DeliveryPackageService, router: Router) {
        super(
            Resource.DELIVERY_PACKAGES,
            deliveryPackageService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DELIVERY_PACKAGES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DELIVERY_PACKAGES),
            (entity?) => new DeliveryPackage(entity),
            (entity) => entity.code
        );
    }
}
