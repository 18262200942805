import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RouteTypeService} from 'app/blocks/service/api/route-type.service';
import {Resource} from 'app/constants/resource';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IRouteType} from 'app/blocks/model/route-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {RouteType} from 'app/blocks/model/route-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class RouteTypeResource extends AbstractEntityResource<IRouteType> {
    constructor(routeTypeService: RouteTypeService, router: Router) {
        super(
            Resource.ROUTE_TYPES,
            routeTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ROUTE_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ROUTE_TYPES),
            (entity?) => new RouteType(entity),
            (entity) => entity.type,
            'style'
        );
    }
}
