import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CreditClassService} from 'app/blocks/service/api/credit-class.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICreditClass} from 'app/blocks/model/credit-class.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {CreditClass} from 'app/blocks/model/credit-class.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CreditClassResource extends AbstractEntityResource<ICreditClass> {
    constructor(creditClassService: CreditClassService, router: Router) {
        super(
            Resource.CREDIT_CLASSES,
            creditClassService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CREDIT_CLASSES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CREDIT_CLASSES),
            (entity?) => new CreditClass(entity),
            (entity) => entity.code,
            'credit_card'
        );
    }
}
