import {DurationPriceList} from 'app/blocks/model/duration-price-list.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDurationPriceList} from 'app/blocks/model/duration-price-list.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DurationPriceListService extends EntityCrudService<IDurationPriceList> {
    constructor(http: HttpClient) {
        super(http, Resource.DURATION_PRICE_LISTS, (obj?) => new DurationPriceList(obj));
    }
}
