import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITrailerType extends IBaseEntity {
    id?: number;
    description?: string;
    emptyWeight?: number;
    maxWeight?: number;
}
export class TrailerType extends BaseEntity implements ITrailerType {
    public id?: number;
    public description?: string;
    public emptyWeight?: number;
    public maxWeight?: number;

    constructor(trailerType?) {
        super();
        if (trailerType) {
            this.id = trailerType.id;
            this.description = trailerType.description;
            this.emptyWeight = trailerType.emptyWeight;
            this.maxWeight = trailerType.maxWeight;
        }
    }
    get discriminator(): any {
        return this.description;
    }
    clone(): TrailerType {
        return new TrailerType(this);
    }
}
