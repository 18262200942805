import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IServicePriorityCode extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;

    serviceWindowInDays?: number;

    followUpInDays?: number;

    priority?: number;

    color?: string;
}

export class ServicePriorityCode extends BaseFranchiseEntity implements IServicePriorityCode {
    public id?: number;

    public code?: string;

    public description?: string;

    public serviceWindowInDays?: number;

    public followUpInDays?: number;

    public priority?: number;

    public color?: string;

    constructor(servicePriorityCode?) {
        super();
        if (servicePriorityCode) {
            this.id = servicePriorityCode.id;

            this.code = servicePriorityCode.code;

            this.description = servicePriorityCode.description;

            this.serviceWindowInDays = servicePriorityCode.serviceWindowInDays;

            this.followUpInDays = servicePriorityCode.followUpInDays;

            this.priority = servicePriorityCode.priority;

            this.color = servicePriorityCode.color;

            this.editable = servicePriorityCode.editable;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): ServicePriorityCode {
        return new ServicePriorityCode(this);
    }
}
