import {SaleOrder} from 'app/blocks/model/sale-order.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {MposDate} from 'app/blocks/util/mpos-date';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class SaleOrderService extends EntityCrudService<ISaleOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.SALE_ORDERS, (obj?) => new SaleOrder(obj));
    }

    getPricing = (request: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/price', request).toPromise();
    };

    confirmSaleOrder = (id): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/sale-orders/' + id + '/confirm', null, {observe: 'response'}).toPromise();
    };

    checkStockOfSaleOrderProductsBeforeApproval = (saleOrderId: number, productIds: number[], routeId: number): Promise<HttpResponse<any>> => {
        const requestParams = createRequestOption({
            productIds: productIds,
            routeId: routeId
        });

        return this.http
            .post<any>(SERVER_API_URL + 'api/sale-orders/' + saleOrderId + '/check-stock-before-approval', requestParams, {observe: 'response'})
            .toPromise();
    };

    cancelSaleOrder = (id: number, date: MposDate, time: string): Promise<HttpResponse<any>> => {
        const request = {date, time};
        return this.http.post<any>(SERVER_API_URL + 'api/sale-orders/' + id + '/cancel', request, {observe: 'response'}).toPromise();
    };

    markAsDelivered = (request): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/sale-orders/mark-as-delivered', request, {observe: 'response'}).toPromise();
    };

    markAsReturned = (request): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/sale-orders/mark-as-returned', request, {observe: 'response'}).toPromise();
    };

    rescheduleSelected = (request): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/sale-orders/reschedule', request, {
                observe: 'response'
            })
            .toPromise();
    };

    createLoadOrderFromSaleOrders = (request): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/sale-orders/load-order', request, {
                observe: 'response'
            })
            .toPromise();
    };

    uploadFile = (label: string, file: File, saleOrderId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('label', label);
        return this.http.post<any>(`${SERVER_API_URL}api/sale-orders/${saleOrderId}/docs`, fd).toPromise();
    };

    downloadFile = (saleOrderId: number, mposFileId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/${saleOrderId}/docs/${mposFileId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    deleteFile = (saleOrderId: number, mposFileId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/${saleOrderId}/docs/${mposFileId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };
    public downloadPDF = (saleOrderId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/${saleOrderId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    markAsApproved = (ids: number[]): any => {
        const requestParams = new HttpParams().set('ids', ids.join(','));
        return this.http
            .post<any>(SERVER_API_URL + 'api/sale-orders/mark-as-approved', null, {observe: 'response', params: requestParams})
            .toPromise();
    };

    public downloadPod = (saleOrderId: number, preview = false): any => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = SERVER_API_URL + `api/sale-orders/${saleOrderId}/pod`;
        return this.http.get(REQUEST_URI, {responseType: type as 'json'});
    };

    downloadMergedSaleOrders = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    // DO - Delivery Order
    downloadDOSaleOrders = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/download-do`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    public sendQuoteEmail = (saleOrderIds: any): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', saleOrderIds);
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/email-quote`;
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS});
    };

    public bulkConvertToDraftQuote = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/sale-orders/quotes/convert-to-draft-quote';
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public bulkApproveQuote = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/sale-orders/quotes/approve';
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public bulkRejectQuote = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/sale-orders/quotes/reject';
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public bulkCancelQuote = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/sale-orders/quotes/cancel';
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public bulkConvertQuoteToOrder = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/sale-orders/quotes/convert';
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public getSaleOrderById = (id: string): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/sale-orders/${id}`;
        return this.http.get(REQUEST_URI).toPromise();
    };

    unCancelSaleOrder = (id: number, date: MposDate, time: string): Promise<HttpResponse<any>> => {
        const request = {date, time};
        return this.http.post<any>(SERVER_API_URL + 'api/sale-orders/' + id + '/uncancel', request, {observe: 'response'}).toPromise();
    };

    downloadPodPdfs(saleOrderIds: number[], deliveryStatus) {
        const requestParams = createRequestOption({saleOrderIds: saleOrderIds.toString(), deliveryStatus: deliveryStatus});
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/pod-download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    }

    getStorageOrdersForReceiveInventory = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/storage-orders`;
        return this.http.get(REQUEST_URI).toPromise();
    };

    downloadSoAnalysis = (ids: number[], isDownloadSo: boolean): Promise<any> => {
        const requestParams = createRequestOption({ids: ids, isDownloadSo: isDownloadSo});
        const REQUEST_URI = `${SERVER_API_URL}api/sale-orders/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
}
