import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IProductCostChange extends IBaseFranchiseEntity {
    id?: number;
    date?: string;
    product?: string;
    newValue?: number;
    oldValue?: number;
}

export class ProductCostChange extends BaseFranchiseEntity implements IProductCostChange {
    public id?: number;
    public date?: string;
    public product?: string;
    public newValue?: number;
    public oldValue?: number;

    constructor(productCostChange?) {
        super();
        if (productCostChange) {
            this.id = productCostChange.id;
            this.date = productCostChange.date;
            this.product = productCostChange.product;
            this.newValue = productCostChange.newValue;
            this.oldValue = productCostChange.oldValue;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): ProductCostChange {
        return new ProductCostChange(this);
    }
}
