import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PackOrderService} from 'app/blocks/service/api/pack-order.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IPackOrder, PackOrder} from 'app/blocks/model/pack-order.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class PackOrderResource extends AbstractEntityResource<IPackOrder> {
    constructor(packOrderService: PackOrderService, router: Router) {
        super(
            Resource.PACK_ORDERS,
            packOrderService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PACK_ORDERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PACK_ORDERS),
            (entity?) => new PackOrder(entity),
            (entity) => entity.id,
            'inventory_2'
        );
    }
}
