import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SUPER_ADMIN_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {Backup, IBackup} from 'app/blocks/model/backup.model';
import {BackupService} from 'app/blocks/service/api/backup.service';

@Injectable({
    providedIn: 'root'
})
export class BackupResource extends AbstractEntityResource<IBackup> {
    constructor(backupService: BackupService, router: Router) {
        super(
            Resource.BACKUPS,
            backupService,
            new EntityNavigator(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.BACKUPS, router),
            new EntityUrlProvider(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.BACKUPS),
            (entity?) => new Backup(entity),
            (entity) => entity.id,
            'share'
        );
    }
}
