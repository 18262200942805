import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IDeliveryMethod extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;
}

export class DeliveryMethod extends BaseEntity implements IDeliveryMethod {
    public id?: number;

    public code?: string;

    public description?: string;

    constructor(deliveryMethod?) {
        super();
        if (deliveryMethod) {
            this.id = deliveryMethod.id;

            this.code = deliveryMethod.code;

            this.description = deliveryMethod.description;
        } else {
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DeliveryMethod {
        return new DeliveryMethod(this);
    }
}
