import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CustomerStatusReasonService} from 'app/blocks/service/api/customer-status-reason.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICustomerStatusReason} from 'app/blocks/model/customer-status-reason.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {CustomerStatusReason} from 'app/blocks/model/customer-status-reason.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CustomerStatusReasonResource extends AbstractEntityResource<ICustomerStatusReason> {
    constructor(customerStatusReasonService: CustomerStatusReasonService, router: Router) {
        super(
            Resource.CUSTOMER_STATUS_REASONS,
            customerStatusReasonService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CUSTOMER_STATUS_REASONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CUSTOMER_STATUS_REASONS),
            (entity?) => new CustomerStatusReason(entity),
            (entity) => entity.code
        );
    }
}
