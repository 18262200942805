import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CustomerStatusReasonGroupService} from 'app/blocks/service/api/customer-status-reason-group.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICustomerStatusReasonGroup} from 'app/blocks/model/customer-status-reason-group.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {CustomerStatusReasonGroup} from 'app/blocks/model/customer-status-reason-group.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CustomerStatusReasonGroupResource extends AbstractEntityResource<ICustomerStatusReasonGroup> {
    constructor(customerStatusReasonGroupService: CustomerStatusReasonGroupService, router: Router) {
        super(
            Resource.CUSTOMER_STATUS_REASON_GROUPS,
            customerStatusReasonGroupService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CUSTOMER_STATUS_REASON_GROUPS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CUSTOMER_STATUS_REASON_GROUPS),
            (entity?) => new CustomerStatusReasonGroup(entity),
            (entity) => entity.code
        );
    }
}
