import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IOrderRejectionCode extends IBaseEntity {
    id?: number;
    code?: string;
    description?: string;
}

export class OrderRejectionCode extends BaseEntity implements IOrderRejectionCode {
    public id?: number;
    public code?: string;
    public description?: string;

    constructor(orderRejectionCode?) {
        super();
        if (orderRejectionCode) {
            this.id = orderRejectionCode.id;
            this.code = orderRejectionCode.code;
            this.description = orderRejectionCode.description;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): OrderRejectionCode {
        return new OrderRejectionCode(this);
    }
}
