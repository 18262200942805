import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IProduct} from 'app/blocks/model/product.model';
import {IProductAttribute} from 'app/blocks/model/product-attirbute.model';
import {IBatchInfo} from 'app/blocks/model/batch-info.model';
import {IWarehouseBinTransaction} from 'app/blocks/model/warehouse-bin-transaction.model';
import {IBatchInfoTransaction} from 'app/blocks/model/batch-info-transaction.model';

export interface IDeliveryItem extends IBaseEntity {
    id?: number;
    quantity?: number;
    catchWeightPickQty?: number;
    caseQuantity?: number;
    eachQuantity?: number;
    gratis?: boolean;
    product?: IProduct;
    amendmentReason?: string;
    sequenceNo?: number;
    productAttributes?: IProductAttribute[];
    batchInfos?: IBatchInfo[];
    warehouseBinTransactions?: IWarehouseBinTransaction[];
    batchInfoTransactions?: IBatchInfoTransaction[];
    initialVanStock?: number;
}

export class DeliveryItem extends BaseEntity implements IDeliveryItem {
    public id?: number;
    public quantity?: number;
    public catchWeightPickQty?: number;
    public caseQuantity?: number;
    public eachQuantity?: number;
    public gratis?: boolean;
    public product?: IProduct;
    public amendmentReason?: string;
    public sequenceNo?: number;
    public productAttributes?: IProductAttribute[];
    public batchInfos?: IBatchInfo[];
    public warehouseBinTransactions?: IWarehouseBinTransaction[];
    public batchInfoTransactions?: IBatchInfoTransaction[];
    public initialVanStock?: number;

    constructor(deliveryItem?) {
        super();
        if (deliveryItem) {
            this.id = deliveryItem.id;
            this.quantity = deliveryItem.quantity;
            this.catchWeightPickQty = deliveryItem.catchWeightPickQty;
            this.gratis = deliveryItem.gratis;
            this.product = deliveryItem.product;
            this.caseQuantity = deliveryItem.caseQuantity;
            this.eachQuantity = deliveryItem.eachQuantity;
            this.amendmentReason = deliveryItem.amendmentReason;
            this.sequenceNo = deliveryItem.sequenceNo;
            this.productAttributes = deliveryItem.productAttributes || [];
            this.batchInfos = deliveryItem.batchInfos || [];
            this.warehouseBinTransactions = deliveryItem.warehouseBinTransactions || [];
            this.batchInfoTransactions = deliveryItem.batchInfoTransactions || [];
            this.initialVanStock = deliveryItem.initialVanStock;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DeliveryItem {
        return new DeliveryItem(this);
    }
}
