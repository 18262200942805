import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {IBinType, BinType} from 'app/blocks/model/bin-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {BinTypeService} from 'app/blocks/service/api/bin-type.service';

@Injectable({
    providedIn: 'root'
})
export class BinTypeResource extends AbstractEntityResource<IBinType> {
    constructor(binTypeService: BinTypeService, router: Router) {
        super(
            Resource.BIN_TYPES,
            binTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.BIN_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.BIN_TYPES),
            (entity?) => new BinType(entity),
            (entity) => entity.id,
            'share'
        );
    }
}
