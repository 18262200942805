import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IntegrationMappingService} from 'app/blocks/service/api/integration-mapping.service';
import {IIntegrationMapping, IntegrationMapping} from 'app/blocks/model/integration-mapping.model';

@Injectable({
    providedIn: 'root'
})
export class IntegrationMappingResource extends AbstractEntityResource<IIntegrationMapping> {
    constructor(integrationMappingservice: IntegrationMappingService, router: Router) {
        super(
            Resource.INTEGRATION_MAPPINGS,
            integrationMappingservice,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.INTEGRATION_MAPPINGS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.INTEGRATION_MAPPINGS),
            (entity?) => new IntegrationMapping(entity),
            (entity) => entity.id
        );
    }
}
