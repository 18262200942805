import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IProduct} from 'app/blocks/model/product.model';

export interface IListProductPrice extends IBaseEntity {
    id?: number;
    price?: number;
    product?: IProduct;
}

export class ListProductPrice extends BaseEntity implements IListProductPrice {
    public id?: number;
    public price?: number;
    public product?: IProduct;

    constructor(listProductPrice?) {
        super();
        if (listProductPrice) {
            this.id = listProductPrice.id;
            this.price = listProductPrice.price;
            this.product = listProductPrice.product;
        }
    }

    get discriminator(): any {
        return this.price;
    }

    clone(): ListProductPrice {
        return new ListProductPrice(this);
    }
}
