import {DeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDeliveryPackage} from 'app/blocks/model/delivery-package.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryPackageService extends EntityCrudService<IDeliveryPackage> {
    constructor(http: HttpClient) {
        super(http, Resource.DELIVERY_PACKAGES, (obj?) => new DeliveryPackage(obj));
    }
}
