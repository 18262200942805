import {IAddress, Address} from 'app/blocks/model/address.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IBin} from 'app/blocks/model/bin.model';
import {IBranch} from 'app/blocks/model/branch.model';
import {IWarehouseType} from 'app/blocks/model/warehouse-type.model';
import {DiTime} from 'app/blocks/util/di-time';

export interface IWarehouse extends IBaseFranchiseEntity {
    id?: number;
    code?: string;
    warehouseName?: string;
    binLocationEnabled?: boolean;
    binLocationMask?: string;
    bins?: IBin[];
    address?: IAddress;
    type?: IWarehouseType;
    branch?: IBranch;
    latitude?: number;
    longitude?: number;
    serviceabilityType?: any;
    fulfilmentType?: any;
    logisticsServiceProvider?: any;
    storeName?: string;
    storeOpeningTime?: any;
    storeClosingTime?: any;
}

export class Warehouse extends BaseFranchiseEntity implements IWarehouse {
    public id?: number;
    public code?: string;
    public warehouseName?: string;
    public binLocationEnabled?: boolean;
    public binLocationMask?: string;
    public address?: IAddress;
    public type?: IWarehouseType;
    public branch?: IBranch;
    public latitude?: number;
    public longitude?: number;
    public bins?: IBin[];
    public serviceabilityType?: any;
    public fulfilmentType?: any;
    public logisticsServiceProvider?: any;
    public storeName?: string;
    public storeOpeningTime?: any;
    public storeClosingTime?: any;

    constructor(warehouse?) {
        super();
        if (warehouse) {
            this.id = warehouse.id;
            this.code = warehouse.code;
            this.warehouseName = warehouse.warehouseName;
            this.binLocationEnabled = warehouse.binLocationEnabled;
            this.binLocationMask = warehouse.binLocationMask;
            this.address = new Address(warehouse.address);
            this.type = warehouse.type;
            this.branch = warehouse.branch;
            this.latitude = warehouse.latitude;
            this.longitude = warehouse.longitude;
            this.bins = warehouse.bins;
            this.editable = warehouse.editable;
            this.serviceabilityType = warehouse.serviceabilityType;
            this.fulfilmentType = warehouse.fulfilmentType;
            this.logisticsServiceProvider = warehouse.logisticsServiceProvider;
            this.storeName = warehouse.storeName;
            this.storeOpeningTime = DiTime.newInstance(warehouse.storeOpeningTime);
            this.storeClosingTime = DiTime.newInstance(warehouse.storeClosingTime);
        } else {
            this.bins = [];
        }
    }
    get discriminator(): any {
        return this.code;
    }
    clone(): Warehouse {
        return new Warehouse(this);
    }
}
export enum ServiceabilityType {
    HYPER_LOCAL = 'HYPER_LOCAL',
    INTERCITY = 'INTERCITY',
    PAN_INDIA = 'PAN_INDIA'
}
export enum FulfilmentType {
    DELIVERY = 'DELIVERY',
    PICKUP = 'PICKUP'
}
export enum LogisticsServiceProvider {
    SHIPROCKET = 'SHIPROCKET'
}
