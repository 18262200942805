import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IProduct} from 'app/blocks/model/product.model';

export interface IProductList extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    products?: IProduct[];
}

export class ProductList extends BaseFranchiseEntity implements IProductList {
    public id?: number;
    public name?: string;
    public description: string;
    public products?: IProduct[];

    constructor(productList?) {
        super();
        if (productList) {
            this.id = productList.id;
            this.name = productList.name;
            this.description = productList.description;
            this.products = productList.products || [];
            this.editable = productList.editable;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ProductList {
        return new ProductList(this);
    }
}
