import {Invoice} from 'app/blocks/model/invoice.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams, HttpHeaders} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IInvoice} from 'app/blocks/model/invoice.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService extends EntityCrudService<IInvoice> {
    constructor(http: HttpClient) {
        super(http, Resource.INVOICES, (obj?) => new Invoice(obj));
    }

    markInvoiceAsReady = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/invoices/' + id + '/mark-as-ready', null, {observe: 'response'}).toPromise();
    };

    invoiceCustomer = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/invoices/' + id + '/invoice-customer', null, {observe: 'response'}).toPromise();
    };

    generatePdf = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/invoices/' + id + '/generate-pdf', null, {observe: 'response'}).toPromise();
    };

    bulkUpdateInvoiceStatus = (ids: string, status: string): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/invoices/mark-as/' + status, ids, {
                observe: 'response'
            })
            .toPromise();
    };

    toggleInvoiceExportedStatus = (ids: number[]): any => {
        const requestParams = new HttpParams().set('ids', ids.join(','));
        return this.http
            .post<any>(SERVER_API_URL + 'api/invoices/toggle-export-status', null, {observe: 'response', params: requestParams})
            .toPromise();
    };

    cancelInvoice = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/invoices/' + id + '/cancel', null, {observe: 'response'}).toPromise();
    };

    consolidateAndMarkAs = (ids: string, invoice: boolean): any => {
        const requestParams = new HttpParams().set('ids', ids).set('invoice', invoice ? 'true' : 'false');
        return this.http
            .post<any>(SERVER_API_URL + 'api/invoices/consolidate', null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    clearSmallInvoiceBalance = (ids): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/invoices/clear-balance', ids, {
                observe: 'response'
            })
            .toPromise();
    };

    getUnpaidInvoiceForCustomer = (customerId: number, sortParam: any): any => {
        const requestParams = createRequestOption(sortParam);
        return this.http
            .get(SERVER_API_URL + 'api/customers/' + customerId + '/unpaid-invoices', {params: requestParams, observe: 'response'})
            .toPromise();
    };

    getUnpaidInvoiceForCustomerGroup = (customerGroupId: number, sortParam: any): any => {
        const requestParams = createRequestOption(sortParam);
        return this.http
            .get(SERVER_API_URL + 'api/customers-groups/' + customerGroupId + '/unpaid-invoices', {params: requestParams, observe: 'response'})
            .toPromise();
    };

    bulkInvoice = (ids): any => {
        const requestParams = new HttpParams().set('ids', ids);
        return this.http
            .post<any>(SERVER_API_URL + 'api/invoices/bulk-invoice', null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    downloadCSVForXero = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('invoiceIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/invoices/csv/xero';
        return this.http.get(REQUEST_URI, {
            params: REQUEST_PARAMS,
            responseType: 'arraybuffer'
        });
    };

    public downloadPDF = (invoiceId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/invoices/${invoiceId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public downloadMergedInvoices = (invoiceIds: number[]): Observable<string> => {
        const requestParams = new HttpParams().set('ids', invoiceIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/invoices/download-v2`;
        return this.http.get<string>(REQUEST_URI, {params: requestParams});
    };

    public downloadPdfFromUrl(url: string): Observable<Blob> {
        return this.http.get(url, {responseType: 'blob'});
    }

    public getExportSettings = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/export-settings/invoices`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public saveExportSettings = (exportSettings: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/export-settings/invoices`;
        return this.http.put<any>(REQUEST_URI, exportSettings, {observe: 'response'}).toPromise();
    };

    public getInvoiceExcerpt = (id): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/invoices/${id}/excerpt`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    unCancelInvoice = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/invoices/' + id + '/uncancel', null, {observe: 'response'}).toPromise();
    };
    updateCreationDate = (request): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/invoices/update-creation-date', request, {
                observe: 'response'
            })
            .toPromise();
    };

    createDepositItemsInvoice = (depositItemInfoIds): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/invoices/deposit-items`;
        return this.http
            .post<any>(REQUEST_URI, depositItemInfoIds, {
                observe: 'response'
            })
            .toPromise();
    };

    // TODO: This should not be here, This call should be made via any other service
    discardDepositItems = (depositItemInfoIds): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/invoices/discard-deposit-items`;
        return this.http
            .post<any>(REQUEST_URI, depositItemInfoIds, {
                observe: 'response'
            })
            .toPromise();
    };

    applyCreditNotesToInvoice = (invoiceId: string, creditNotesId: any[]): any => {
        return this.http.post<any>(`${SERVER_API_URL}api/invoices/${invoiceId}/allocate`, creditNotesId, {observe: 'response'}).toPromise();
    };
}
