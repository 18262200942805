import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IPackaging, Packaging} from 'app/blocks/model/packaging.model';

export interface IProductPackaging extends IBaseEntity {
    id?: number;

    quantity?: number;
    packagings?: IPackaging[];
}

export class ProductPackaging extends BaseEntity implements IProductPackaging {
    public id?: number;

    public quantity?: number;
    public packagings?: IPackaging[];

    constructor(productPackaging?) {
        super();
        if (productPackaging) {
            this.id = productPackaging.id;

            this.quantity = productPackaging.quantity;
        } else {
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): ProductPackaging {
        return new ProductPackaging(this);
    }
}
