import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {ISiteVisit} from 'app/blocks/model/site-visit.model';

export interface ISurveyReport extends IBaseEntity {
    id: number;
    surveyDate: string;
    surveyTime: string;
    surveyName: string;
    imageAvailable: boolean;
    fileAvailable: boolean;
    reference: string;
    route: string;
    driver: string;
    deliveryPoint: string;
    customer: any;
    timeOfSurvey?: string;
}

export class SurveyReport extends BaseEntity implements ISurveyReport {
    public id: number;
    public surveyDate: string;
    public surveyTime: string;
    public surveyName: string;
    public imageAvailable: boolean;
    public fileAvailable: boolean;
    public reference: string;
    public route: string;
    public driver: string;
    public deliveryPoint: string;
    public customer: any;
    public timeOfSurvey?: string;

    constructor(surveyReport?) {
        super();
        if (surveyReport) {
            this.id = surveyReport.id;
            this.surveyDate = surveyReport.surveyDate;
            this.surveyTime = surveyReport.surveyTime;
            this.surveyName = surveyReport.surveyName;
            this.imageAvailable = surveyReport.imageAvailable;
            this.fileAvailable = surveyReport.fileAvailable;
            this.reference = surveyReport.reference;
            this.route = surveyReport.route;
            this.driver = surveyReport.driver;
            this.deliveryPoint = surveyReport.deliveryPoint;
            this.customer = surveyReport.customer;
            this.timeOfSurvey = surveyReport.timeOfSurvey;
        }
    }

    clone(): SurveyReport {
        return new SurveyReport(this);
    }
}
