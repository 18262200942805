import {StatementType} from 'app/blocks/model/statement-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IStatementType} from 'app/blocks/model/statement-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class StatementTypeService extends EntityCrudService<IStatementType> {
    constructor(http: HttpClient) {
        super(http, Resource.STATEMENT_TYPES, (obj?) => new StatementType(obj));
    }
}
