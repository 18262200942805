import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {HISTORY_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {VehicleUnloadService} from 'app/blocks/service/api/vehicle-unload.service';
import {IVehicleUnload, VehicleUnload} from 'app/blocks/model/vehicle-unload.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleUnloadResource extends AbstractEntityResource<IVehicleUnload> {
    constructor(vehicleUnloadService: VehicleUnloadService, router: Router) {
        super(
            Resource.VEHICLE_UNLOADS,
            vehicleUnloadService,
            new EntityNavigator(HISTORY_BASE_URL, Resource.VEHICLE_UNLOADS, router),
            new EntityUrlProvider(HISTORY_BASE_URL, Resource.VEHICLE_UNLOADS),
            (entity?) => new VehicleUnload(entity),
            (entity) => entity.id,
            'inventory'
        );
    }
}
