import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IStatementType extends IBaseEntity {
    id?: number;

    code?: string;

    description?: string;
}

export class StatementType extends BaseEntity implements IStatementType {
    public id?: number;

    public code?: string;

    public description?: string;

    constructor(statementType?) {
        super();
        if (statementType) {
            this.id = statementType.id;

            this.code = statementType.code;

            this.description = statementType.description;
        } else {
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): StatementType {
        return new StatementType(this);
    }
}
