import {InventoryReasonGroup} from 'app/blocks/model/inventory-reason-group.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IInventoryReasonGroup} from 'app/blocks/model/inventory-reason-group.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class InventoryReasonGroupService extends EntityCrudService<IInventoryReasonGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.INVENTORY_REASON_GROUPS, (obj?) => new InventoryReasonGroup(obj));
    }
}
