import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IDeliveryPointMessageType extends IBaseEntity {
    id?: number;
    type?: string;
}

export class DeliveryPointMessageType extends BaseEntity implements IDeliveryPointMessageType {
    public id?: number;
    public type?: string;

    constructor(deliveryPointMessageType?) {
        super();
        if (deliveryPointMessageType) {
            this.id = deliveryPointMessageType.id;
            this.type = deliveryPointMessageType.type;
        }
    }

    get discriminator(): any {
        return this.type;
    }

    clone(): DeliveryPointMessageType {
        return new DeliveryPointMessageType(this);
    }
}
