import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SUPER_ADMIN_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IRestore, Restore} from 'app/blocks/model/restore.model';
import {RestoreService} from 'app/blocks/service/api/restore.service';

@Injectable({
    providedIn: 'root'
})
export class RestoreResource extends AbstractEntityResource<IRestore> {
    constructor(restoreService: RestoreService, router: Router) {
        super(
            Resource.RESTORES,
            restoreService,
            new EntityNavigator(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.RESTORES, router),
            new EntityUrlProvider(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.RESTORES),
            (entity?) => new Restore(entity),
            (entity) => entity.id,
            'share'
        );
    }
}
