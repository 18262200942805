import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PriceListTypeService} from 'app/blocks/service/api/price-list-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IPriceListType} from 'app/blocks/model/price-list-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {PriceListType} from 'app/blocks/model/price-list-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class PriceListTypeResource extends AbstractEntityResource<IPriceListType> {
    constructor(priceListTypeService: PriceListTypeService, router: Router) {
        super(
            Resource.PRICE_LIST_TYPES,
            priceListTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRICE_LIST_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRICE_LIST_TYPES),
            (entity?) => new PriceListType(entity),
            (entity) => entity.code
        );
    }
}
