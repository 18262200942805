import {IStandingOrder} from 'app/blocks/model/standing-order.model';
import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {ICurrency} from 'app/blocks/model/currency.model';
import {ITimeWindow, TimeWindow} from 'app/blocks/model/time-window.model';
import {IBank} from 'app/blocks/model/bank.model';
import {IImage} from 'app/blocks/model/image.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {IRebateTier} from 'app/blocks/model/rebate-tier.model';
import {IPartnerCompany} from 'app/blocks/model/partner-company.model';
import {ICustomPdfTemplate} from 'app/blocks/model/custom-pdf-template.model';

export interface ICompany extends IBaseEntity {
    id?: number;
    name?: string;
    website?: string;
    vatRegNo?: string;
    registrationNo?: string;
    contactInfo?: IContactInfo;
    currency?: ICurrency;
    companyCode?: string;
    operationHours?: ITimeWindow[];
    writeSettings?: any;
    readSettings?: any[];
    bankAccounts?: IBank[];
    companyLogo?: IImage;
    invoiceTemplate?: any;
    podTemplate?: any;
    bulkPickOrderTemplate?: any;
    primaryEmail?: string;
    salesEmail?: string;
    deliveryEmail?: string;
    fleetEmail?: string;
    inventoryEmail?: string;
    analyticsEmail?: string;
    geminiEmail?: string;
    franchises?: IFranchise[];
    rebateTiers?: IRebateTier[];
    stripePublishableKey?: string;
    stripeSecretKeyAvailable?: boolean;
    partnerCompanies?: IPartnerCompany[];
    pan?: string;
    fssai?: string;
    customInvoiceTemplate?: ICustomPdfTemplate;
}

export class Company extends BaseEntity implements ICompany {
    public id?: number;
    public name?: string;
    public website?: string;
    public vatRegNo?: string;
    public registrationNo?: string;
    public contactInfo?: IContactInfo;
    public currency?: ICurrency;
    public companyCode?: string;
    public operationHours?: ITimeWindow[];
    public writeSettings: any;
    public readSettings: any[];
    public bankAccounts?: IBank[];
    public companyLogo?: IImage;
    public invoiceTemplate?: any;
    public podTemplate?: any;
    public bulkPickOrderTemplate?: any;
    public primaryEmail?: string;
    public salesEmail?: string;
    public deliveryEmail?: string;
    public fleetEmail?: string;
    public inventoryEmail?: string;
    public analyticsEmail?: string;
    public franchises?: IFranchise[];
    public rebateTiers?: IRebateTier[];
    public stripePublishableKey?: string;
    public stripeSecretKeyAvailable?: boolean;
    public partnerCompanies?: IPartnerCompany[];
    public pan?: string;
    public fssai?: string;
    public geminiEmail?: string;
    public customInvoiceTemplate?: ICustomPdfTemplate;

    constructor(company?) {
        super();
        if (company) {
            this.id = company.id;
            this.name = company.name;
            this.website = company.website;
            this.vatRegNo = company.vatRegNo;
            this.registrationNo = company.registrationNo;
            this.contactInfo = new ContactInfo(company.contactInfo);
            this.currency = company.currency;
            this.companyCode = company.companyCode;
            this.operationHours = company.operationHours ? TimeWindow.processArray(company.operationHours) : [];
            this.writeSettings = company.writeSettings;
            this.readSettings = company.readSettings || [];
            this.bankAccounts = company.bankAccounts || [];
            this.companyLogo = company.companyLogo;
            this.invoiceTemplate = company.invoiceTemplate;
            this.podTemplate = company.podTemplate;
            this.bulkPickOrderTemplate = company.bulkPickOrderTemplate;
            this.primaryEmail = company.primaryEmail;
            this.salesEmail = company.salesEmail;
            this.deliveryEmail = company.deliveryEmail;
            this.fleetEmail = company.fleetEmail;
            this.inventoryEmail = company.inventoryEmail;
            this.analyticsEmail = company.analyticsEmail;
            this.franchises = company.franchises || [];
            this.rebateTiers = company.rebateTiers || [];
            this.stripePublishableKey = company.stripePublishableKey;
            this.stripeSecretKeyAvailable = company.stripeSecretKeyAvailable;
            this.partnerCompanies = company.partnerCompanies || [];
            this.pan = company.pan;
            this.fssai = company.fssai;
            this.geminiEmail = company.geminiEmail;
            this.customInvoiceTemplate = company.customInvoiceTemplate;
        } else {
            this.contactInfo = new ContactInfo();
            this.operationHours = [];
            this.readSettings = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Company {
        return new Company(this);
    }
}
