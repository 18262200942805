import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Backup, IBackup} from 'app/blocks/model/backup.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class BackupService extends EntityCrudService<IBackup> {
    constructor(http: HttpClient) {
        super(http, Resource.BACKUPS, (obj?) => new Backup(obj));
    }
    public backup = (data: any): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/super-admin/backups`, data, {
                observe: 'response'
            })
            .toPromise();
    };

    public clearOldBackups = (): Promise<HttpResponse<any>> => {
        return this.http
            .get<any>(SERVER_API_URL + `api/super-admin/clear-backups`, {
                observe: 'response'
            })
            .toPromise();
    };
}
