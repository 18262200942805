import {MajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IMajorAccountCode} from 'app/blocks/model/major-account-code.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class MajorAccountCodeService extends EntityCrudService<IMajorAccountCode> {
    constructor(http: HttpClient) {
        super(http, Resource.MAJOR_ACCOUNT_CODES, (obj?) => new MajorAccountCode(obj));
    }
}
