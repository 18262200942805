import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PurchaseCreditNoteService} from 'app/blocks/service/api/purchase-credit-note.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IPurchaseCreditNote, PurchaseCreditNote} from 'app/blocks/model/purchase-credit-note.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class PurchaseCreditNoteResource extends AbstractEntityResource<IPurchaseCreditNote> {
    constructor(purchaseCreditNoteService: PurchaseCreditNoteService, router: Router) {
        super(
            Resource.PURCHASE_CREDIT_NOTES,
            purchaseCreditNoteService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PURCHASE_CREDIT_NOTES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PURCHASE_CREDIT_NOTES),
            (entity?) => new PurchaseCreditNote(entity),
            (entity) => entity.purchaseCreditNoteNo,
            'description'
        );
    }
}
