import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IVehicleSkill, VehicleSkill} from 'app/blocks/model/vehicle-skill.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleSkillService extends EntityCrudService<IVehicleSkill> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLE_SKILL, (obj?) => new VehicleSkill(obj));
    }
}
