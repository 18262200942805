import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IOrderItemReason} from 'app/blocks/model/order-item-reason.model';
import {IProduct} from 'app/blocks/model/product.model';
import {IProductAttribute} from 'app/blocks/model/product-attirbute.model';
import {IWarehouseBinTransaction} from 'app/blocks/model/warehouse-bin-transaction.model';
import {BatchInfoTransaction} from 'app/blocks/model/batch-info-transaction.model';

export interface IOrderItem extends IBaseEntity {
    id?: number;
    orderedQuantity?: number;
    deliveredQuantity?: number;
    invoicedQuantity?: number;
    unitPrice?: number;
    tax?: number;
    total?: number;
    gratis?: boolean;
    promotion?: boolean;
    discount?: number;
    discountPercentage?: number;
    subTotal?: number;
    product?: IProduct;
    pickMismatchReason?: IOrderItemReason;
    pickedQuantity?: number;
    inventoryQuantity?: number;
    trackingStatus?: string;
    productClassCommissionPercentage?: number;
    productCommission?: number;
    caseQuantity?: number;
    eachQuantity?: number;
    pickConfirmed?: boolean;
    sequence?: number;
    purchaseOrderItemNote?: string;
    orderItemAttributes?: IProductAttribute[];
    taxPercentage?: number;
    purchaseOrderItem?: any;
    warehouseBinTransactions?: IWarehouseBinTransaction[];
    maximumPrice?: number;
    hsnCode?: string;
    marginPercentage?: number;
    batchInfoTransactions?: BatchInfoTransaction[];
}
export class OrderItem extends BaseEntity implements IOrderItem {
    public id?: number;
    public orderedQuantity?: number;
    public deliveredQuantity?: number;
    public invoicedQuantity?: number;
    public unitPrice?: number;
    public tax?: number;
    public total?: number;
    public gratis?: boolean;
    public promotion?: boolean;
    public promotionInSameProduct?: boolean;
    public discount?: number;
    public discountPercentage?: number;
    public subTotal?: number;
    public product?: IProduct;
    public pickMismatchReason?: IOrderItemReason;
    public pickedQuantity?: number;
    public inventoryQuantity?: number;
    public trackingStatus?: string;
    public productClassCommissionPercentage?: number;
    public productCommission?: number;
    public promotionQuantity?: number;
    public caseQuantity?: number;
    public eachQuantity?: number;
    public pickConfirmed?: boolean;
    public sequence?: number;
    public purchaseOrderItemNote?: string;
    public orderItemAttributes?: IProductAttribute[];
    public taxPercentage?: number;
    public purchaseOrderItem?: any;
    public warehouseBinTransactions?: IWarehouseBinTransaction[];
    public maximumPrice?: number;
    public hsnCode?: string;
    public marginPercentage?: number;
    public batchInfoTransactions?: BatchInfoTransaction[];
    constructor(orderItem?) {
        super();
        if (orderItem) {
            this.id = orderItem.id;
            this.orderedQuantity = orderItem.orderedQuantity;
            this.deliveredQuantity = orderItem.deliveredQuantity;
            this.invoicedQuantity = orderItem.invoicedQuantity;
            this.unitPrice = orderItem.unitPrice;
            this.tax = orderItem.tax;
            this.total = orderItem.total;
            this.gratis = orderItem.gratis;
            this.promotion = orderItem.promotion;
            this.promotionInSameProduct = orderItem.promotionInSameProduct;
            this.discount = orderItem.discount;
            this.discountPercentage = orderItem.discountPercentage;
            this.subTotal = orderItem.subTotal;
            this.product = orderItem.product;
            this.pickMismatchReason = orderItem.pickMismatchReason;
            this.pickedQuantity = orderItem.pickedQuantity;
            this.trackingStatus = orderItem.trackingStatus;
            this.inventoryQuantity = orderItem.inventoryQuantity;
            this.productClassCommissionPercentage = orderItem.productClassCommissionPercentage;
            this.productCommission = orderItem.productCommission;
            this.promotionQuantity = orderItem.promotionQuantity;
            this.caseQuantity = orderItem.caseQuantity;
            this.eachQuantity = orderItem.eachQuantity;
            this.pickConfirmed = orderItem.pickConfirmed;
            this.sequence = orderItem.sequence;
            this.purchaseOrderItemNote = orderItem.purchaseOrderItemNote;
            this.orderItemAttributes = orderItem.orderItemAttributes;
            this.taxPercentage = orderItem.taxPercentage;
            this.purchaseOrderItem = orderItem.purchaseOrderItem;
            this.warehouseBinTransactions = orderItem.warehouseBinTransactions;
            this.maximumPrice = orderItem.maximumPrice;
            this.hsnCode = orderItem.hsnCode;
            this.marginPercentage = orderItem.marginPercentage;
            this.batchInfoTransactions = orderItem.batchInfoTransactions || [];
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): OrderItem {
        return new OrderItem(this);
    }
}
