import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {CustomPdfTemplate, ICustomPdfTemplate} from 'app/blocks/model/custom-pdf-template.model';
import {CustomPdfTemplateService} from 'app/blocks/service/api/custom-pdf-template.service';

@Injectable({
    providedIn: 'root'
})
export class CustomPdfTemplateResource extends AbstractEntityResource<ICustomPdfTemplate> {
    constructor(customPdfTemplateService: CustomPdfTemplateService, router: Router) {
        super(
            Resource.CUSTOM_PDF_TEMPLATES,
            customPdfTemplateService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CUSTOM_PDF_TEMPLATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CUSTOM_PDF_TEMPLATES),
            (entity?) => new CustomPdfTemplate(entity),
            (entity) => entity.id
        );
    }
}
