import {PaymentType} from 'app/blocks/model/payment-type.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPaymentType} from 'app/blocks/model/payment-type.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PaymentTypeService extends EntityCrudService<IPaymentType> {
    constructor(http: HttpClient) {
        super(http, Resource.PAYMENT_TYPES, (obj?) => new PaymentType(obj));
    }
}
