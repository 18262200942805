import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ITenant, Tenant} from 'app/blocks/model/tenant.model';
import {BranchService} from 'app/blocks/service/api/branch.service';
import {TenantService} from 'app/blocks/service/api/tenant.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL, ENTITIES_SUPER_ADMIN_SETUP_BASE_URL} from 'app/app.constants';
import {IBranch} from 'app/blocks/model/branch.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Branch} from 'app/blocks/model/branch.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class TenantResource extends AbstractEntityResource<ITenant> {
    constructor(tenantService: TenantService, router: Router) {
        super(
            Resource.TENANTS,
            tenantService,
            new EntityNavigator(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.TENANTS, router),
            new EntityUrlProvider(ENTITIES_SUPER_ADMIN_SETUP_BASE_URL, Resource.TENANTS),
            (entity?) => new Tenant(entity),
            (entity) => entity.id,
            'group'
        );
    }
}
