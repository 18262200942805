import {ICustomerStatusReasonGroup, CustomerStatusReasonGroup} from 'app/blocks/model/customer-status-reason-group.model';

import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface ICustomerStatusReason extends IBaseEntity {
    id?: number;

    code?: string;

    reason?: string;

    sequence?: number;

    isDefaultInGroup?: boolean;

    active?: boolean;

    group?: ICustomerStatusReasonGroup;
}

export class CustomerStatusReason extends BaseEntity implements ICustomerStatusReason {
    public id?: number;

    public code?: string;

    public reason?: string;

    public sequence?: number;

    public isDefaultInGroup?: boolean;

    public active?: boolean;

    public group?: ICustomerStatusReasonGroup;

    constructor(customerStatusReason?) {
        super();
        if (customerStatusReason) {
            this.id = customerStatusReason.id;

            this.code = customerStatusReason.code;

            this.reason = customerStatusReason.reason;

            this.sequence = customerStatusReason.sequence;

            this.isDefaultInGroup = customerStatusReason.isDefaultInGroup;

            this.active = customerStatusReason.active;

            this.group = new CustomerStatusReasonGroup(customerStatusReason.group);
        } else {
            this.group = new CustomerStatusReasonGroup();
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): CustomerStatusReason {
        return new CustomerStatusReason(this);
    }
}
